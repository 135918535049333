import { API_URL } from '../../../request'

export const listLeadsUrl = () => {
    return API_URL + `/leads/list`
}

export const listSharedUrl = () => {
    return API_URL + `/leads/shared`
}

export const listLeadsExportUrl = () => {
    return API_URL + `/leads/export`
}

export const getLeadStatsUrl = () => {
    return API_URL + `/leads/stat`
}

export const searchLeadsUrl = () => {
    return API_URL + `/leads/search`
}

export const fetchAllFacebookDataUrl = () => {
    return API_URL + `/fetch/all/facebook/data`
}

export const addLeadUrl = () => {
    return API_URL + `/addLead`
}
export const listProposalUrl = () => {
    return API_URL + `/proposal`
}
export const leadsActivitiesUrl = () => {
    return API_URL + `/leads/activityType`
}

export const FetchProposalUrl = () => {
    return API_URL + `/proposal/fetch`
}
export const addLeadActivity = () => {
    return API_URL + `/add/leadActivity`
}
export const saveAndSendEmail = () => {
    return API_URL + `/add/email`
}

export const updateLeadUrl = (id) => {
    return API_URL + `/lead/${id}`
}
export const loadLeadsUrl = () => {
    return API_URL + '/getAllLeadsByPost'
}


export const assignLeadUrl = () => {
    return API_URL + '/lead/assign'
}
export const assignCampaignUrl = () => {
    return API_URL + '/campaign/assign'
}
export const emailsListUrl = () => {
    return API_URL + '/communicationEmail'
}
export const viewProposalUrl = () => {
    return API_URL + '/proposal/view'
}
export const addViewingLeadUrl = () => {
    return API_URL + '/viewingLead'
}


export const saveComment = () => {
    return API_URL + `/add/comment`
}
export const assignLogsUrl = () => {
    return API_URL + `/autoAssignLeadsList`
}
