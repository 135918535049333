import { API_URL } from '../../../request'

export const addFollowUpUrl = () => {
  return API_URL + `/followup`
}
export const listFollowUpUrl = () => {
  return API_URL + `/followup`
}
export const setFollowupsToFalseUrl = () => {
  return API_URL + `/falseFollowups`
}
export const addSmartFollowUpUrl = () => {
  return API_URL + `/smartFollowup`
}
