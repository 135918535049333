import { addFollowUpUrl, listFollowUpUrl ,setFollowupsToFalseUrl,addSmartFollowUpUrl} from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'


export const addFollowUpAction = async params => {
  try {
    let { data } = await axios.post(
      addFollowUpUrl(),
      { ...params },
      getToken()
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const listFollowUpAction = async params => {
  try {
    let { data } = await axios.get(
      listFollowUpUrl(), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const setFollowupsToFalse = async params => {
  try {
    let { data } = await axios.put(
      setFollowupsToFalseUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const addSmartFollowUp = async params => {
  try {
    let { data } = await axios.post(
      addSmartFollowUpUrl(),
      { ...params },
      getToken()
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
