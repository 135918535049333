import {push} from 'connected-react-router'
import _ from 'lodash'
import {
    DashboardOutlined,
    FacebookOutlined,
    UserOutlined,
    StockOutlined,
    BookOutlined,
    PlusSquareOutlined,
    CalendarOutlined,
    FilePptOutlined,
    MailOutlined,
    ReadOutlined,
    CarOutlined,
    LayoutOutlined,
    BlockOutlined,
    TableOutlined,
    UsergroupAddOutlined,
    FormOutlined,
    SolutionOutlined,
    ClockCircleOutlined,
    PhoneOutlined,
} from '@ant-design/icons'

import Dashboard from './containers/dashboard'
import {Path} from 'path-parser'
import {RiFileList2Line} from 'react-icons/ri'

// import FbSuccess from './containers/dashboard/fbsuccess'

// import AllAccounts from './containers/accounts/views/all'
// import AllCampaigns from './containers/campaigns/views/all'
// import AllAddSets from './containers/addSets/views/all'
// import AllAds from './containers/addSets/views/allAd'
// import SharedLeads from './containers/leads/views/sharedLeads'
// import AllLeads from './containers/leads/views/allLeads'
// import LeadStats from './containers/leads/views/leadStats'
// import SaleDirectorLeads from './containers/leads/views/saleDirectorLeads'
// import SaleDirectorUnAssignedLeads from './containers/leads/views/saleDirectorUnAssignedLeads'
// import SaleDirectorAssignedLeads from './containers/leads/views/saleDirectorAssignedLeads'

// import WonLeads from './containers/leads/views/wonList'
// import UnqualfiedLeads from './containers/leads/views/unqualifiedList'
// import AssignedList from './containers/leads/views/assignedList'
// import AddRequirement from './containers/Requirement/views/add'
// import requirementList from './containers/Requirement/views/all'
// import UserTargetList from './containers/targets/views/list'
// import AddUserTarget from './containers/targets/views/index'
//
// import AddUsers from './containers/users/views/add'
// import EditUser from './containers/users/views/edit'
// import AddProject from './containers/project/views/add'
// import AllProject from './containers/project/views/all'
// import MapProject from './containers/project/views/mapView'
// import CreateProposal from './containers/project/views/createProposal'
//
// import AllUsers from './containers/users/views/all'
// import CheckInsList from './containers/users/views/checkins'
// import ChangePassword from './containers/users/views/editPassword'

// import AddLead from './containers/leads/views/add'
// import ProposalList from './containers/leads/views/proposalList'
// import ViewLead from './containers/leads/views/viewLead'
// import ViewUserLead from './containers/leads/views/viewUserLead'
// import AllFollowups from './containers/followup/views/list'

// import AllAgentsCrawler from './containers/agentsCrawler/views/list'
// import AllQuestionnaire from './containers/hiringQuestionnaire/views/list'
// import HiringInvite from './containers/hiringQuestionnaire/views/invite'
// import AllCommunicationMails from './containers/communication/views/index'
// import TodayFollowups from './containers/followup/views/todayFollowup'
// import AddQuestion from './containers/questions/view/index'
// import ViewQuestion from './containers/questions/view/viewQuestion'
// import AllQuestion from './containers/questions/view/list'
// import AddDriver from './containers/drivers/views/index'
// import DriverList from './containers/drivers/views/list'
// import Journey from './containers/drivers/views/journey'
// import AddCar from './containers/car/views/index'
// import CarList from './containers/car/views/list'

// import ViewingLead from './containers/leads/views/viewingLead'
// import ViewingLeadList from './containers/leads/views/viewingLeadList'

// import AddViewLead from './containers/leads/views/viewingLead'
// import AddPlot from './containers/plot/views/index'
// import PlotList from './containers/plot/views/list'
// import AddBuyerPlot from './containers/buyerPlots/views/index'
// import BuyerPlotList from './containers/buyerPlots/views/list'
// import AddPlotImage from './containers/buyerPlots/views/soldPlotPhoto'
// import CalendarView from './containers/hiringQuestionnaire/views/calendarView'
// import AddExpense from './containers/expense/views/index'
// import ExpenseList from './containers/expense/views/list'
// import AddOfferLetter from './containers/offerLetter/views/index'
// import OfferLetterList from './containers/offerLetter/views/list'
// import FinanceDashboard from './containers/dashboard/financeDashboard'
// import AddAttendance from './containers/attendance/views/index'
// import AttendanceList from './containers/attendance/views/attendanceList'
// import AddClosing from './containers/closing/views/index'
// import ManagerClosingList from './containers/closing/views/managerClosing'
// import AgentsClosingList from './containers/closing/views/agentsClosing'
// import DirectorClosingList from './containers/closing/views/diretorClosing'
// import AdminClosingList from './containers/closing/views/adminClosing'
// // import ManagerDashboard from './containers/dashboard/managerDashboard'
// import AddMeeting from './containers/meeting/views'
// import MeetingList from './containers/meeting/views/list'
// import InviteeList from './containers/meeting/views/inviteeList'
// import AddListing from './containers/lisiting/views'
// import ListingList from './containers/lisiting/views/list'
// import DirectoryList from './containers/users/views/directory'
// import InhouseList from './containers/inhouseData/views/list'
// import AssignedInhouseList from './containers/inhouseData/views/assignedInhouseLead'
// import JouneysList from './containers/drivers/views/journeyList'

import {agentUser} from './request'

import React from 'react'

const FbSuccess = React.lazy(() => import( './containers/dashboard/fbsuccess'))
const AllAppointments = React.lazy(() => import( './containers/appointment/views/list'))
const FinanceDashboard = React.lazy(() => import( './containers/dashboard/financeDashboard'))
const ChangePassword = React.lazy(() => import( './containers/users/views/editPassword'))
const LeadStats = React.lazy(() => import( './containers/leads/views/leadStats'))
const InhouseList = React.lazy(() => import( './containers/inhouseData/views/list'))
const AssignedInhouseList = React.lazy(() => import( './containers/inhouseData/views/assignedInhouseLead'))
const AddRequirement = React.lazy(() => import( './containers/Requirement/views/add'))
const requirementList = React.lazy(() => import( './containers/Requirement/views/all'))
const AllAccounts = React.lazy(() => import( './containers/accounts/views/all'))
const ManagerDashboard = React.lazy(() => import( './containers/dashboard/managerDashboard'))
const UnassignedLeads = React.lazy(() => import( './containers/leads/views/unassignedLeads'))
const AllCalls = React.lazy(() => import( './containers/call/views/list'))
const ProposalList = React.lazy(() => import( './containers/leads/views/proposalList'))
const AllFollowups = React.lazy(() => import( './containers/followup/views/list'))
const AddLead = React.lazy(() => import( './containers/leads/views/add'))
const AllLeads = React.lazy(() => import( './containers/leads/views/allLeads'))
const SaleDirectorLeads = React.lazy(() => import( './containers/leads/views/saleDirectorLeads'))
const MyLeads = React.lazy(() => import( './containers/leads/views/myleads'))
const WonLeads = React.lazy(() => import( './containers/leads/views/wonList'))
const UnqualfiedLeads = React.lazy(() => import( './containers/leads/views/unqualifiedList'))
const AssignedList = React.lazy(() => import( './containers/leads/views/assignedList'))
const ViewLead = React.lazy(() => import( './containers/leads/views/viewLead'))
const AllTickets = React.lazy(() => import('./containers/tickets/views/list'))

import ViewUserLead from './containers/leads/views/viewUserLead'

/*const ViewUserLead = React.lazy(() =>
  import(''),
)*/

const TodayFollowups = React.lazy(() =>
    import('./containers/followup/views/todayFollowup'),
)
const AddProject = React.lazy(() => import('./containers/project/views/add'))
const AllProject = React.lazy(() => import('./containers/project/views/all'))
const MapProject = React.lazy(() =>
    import('./containers/project/views/mapView'),
)
const AddCampaign = React.lazy(() =>
    import('./containers/campaigns/views/add'),
)
const AllCampaigns = React.lazy(() =>
    import('./containers/campaigns/views/all'),
)

const AddRequests = React.lazy(() =>
    import('./containers/marketingRequest/views/add'),
)
const AllAddSets = React.lazy(() => import('./containers/addSets/views/all'))
const AllAds = React.lazy(() => import('./containers/addSets/views/allAd'))
const AllAdsForStaff = React.lazy(() => import('./containers/addSets/views/allAdForStaff'))

const AddUsers = React.lazy(() => import('./containers/users/views/add'))
const EditUser = React.lazy(() => import('./containers/users/views/edit'))
const AllUsers = React.lazy(() => import('./containers/users/views/all'))
const DirectoryList = React.lazy(() =>
    import('./containers/users/views/directory'),
)

const CheckInsList = React.lazy(() =>
    import('./containers/users/views/checkins'),
)
const AllCommunicationMails = React.lazy(() =>
    import('./containers/communication/views/index'),
)
const AddQuestion = React.lazy(() =>
    import('./containers/questions/view/index'),
)
const ViewQuestion = React.lazy(() =>
    import('./containers/questions/view/viewQuestion'),
)
const AllQuestion = React.lazy(() => import('./containers/questions/view/list'))
const AddDriver = React.lazy(() => import('./containers/drivers/views/index'))
const DriverList = React.lazy(() => import('./containers/drivers/views/list'))
const JouneysList = React.lazy(() =>
    import('./containers/drivers/views/journeyList'),
)
const Journey = React.lazy(() => import('./containers/drivers/views/journey'))
const ViewingLeadList = React.lazy(() =>
    import('./containers/leads/views/viewingLeadList'),
)
const ViewingLead = React.lazy(() =>
    import('./containers/leads/views/viewingLead'),
)
const AddCar = React.lazy(() => import('./containers/car/views/index'))
const CarList = React.lazy(() => import('./containers/car/views/list'))
const AddPlot = React.lazy(() => import('./containers/plot/views/index'))
const PlotList = React.lazy(() => import('./containers/plot/views/list'))
const AddBuyerPlot = React.lazy(() =>
    import('./containers/buyerPlots/views/index'),
)
const BuyerPlotList = React.lazy(() =>
    import('./containers/buyerPlots/views/list'),
)
const AddExpense = React.lazy(() => import('./containers/expense/views/index'))
const ExpenseList = React.lazy(() => import('./containers/expense/views/list'))
const AddAttendance = React.lazy(() =>
    import('./containers/attendance/views/index'),
)
const AttendanceList = React.lazy(() =>
    import('./containers/attendance/views/attendanceList'),
)
const AddUserTarget = React.lazy(() =>
    import('./containers/targets/views/index'),
)
const UserTargetList = React.lazy(() =>
    import('./containers/targets/views/list'),
)
const AddClosing = React.lazy(() => import('./containers/closing/views/index'))
const ManagerClosingList = React.lazy(() =>
    import('./containers/closing/views/managerClosing'),
)
const AgentsClosingList = React.lazy(() =>
    import('./containers/closing/views/agentsClosing'),
)
const DirectorClosingList = React.lazy(() =>
    import('./containers/closing/views/diretorClosing'),
)
const AdminClosingList = React.lazy(() =>
    import('./containers/closing/views/adminClosing'),
)
const AddMeeting = React.lazy(() => import('./containers/meeting/views'))
const MeetingList = React.lazy(() => import('./containers/meeting/views/list'))
const InviteeList = React.lazy(() =>
    import('./containers/meeting/views/inviteeList'),
)
const AddListing = React.lazy(() => import('./containers/lisiting/views'))
const ListingList = React.lazy(() => import('./containers/lisiting/views/list'))
const HiringInvite = React.lazy(() =>
    import('./containers/hiringQuestionnaire/views/invite'),
)
const CalendarView = React.lazy(() =>
    import('./containers/hiringQuestionnaire/views/calendarView'),
)
const AllAgentsCrawler = React.lazy(() =>
    import('./containers/agentsCrawler/views/list'),
)
const AllQuestionnaire = React.lazy(() =>
    import('./containers/hiringQuestionnaire/views/list'),
)
const AddOfferLetter = React.lazy(() => import('./containers/offerLetter/views/index'))
const OfferLetterList = React.lazy(() => import('./containers/offerLetter/views/list'))
const DirectorTeam = React.lazy(() => import('./containers/users/views/managerTeam'))
const ManagerTeam = React.lazy(() => import('./containers/users/views/managerTeam'))
const ViewProfile = React.lazy(() => import('./containers/users/views/viewProfile'))
const AddTicket = React.lazy(() => import( './containers/tickets/views/add'))
const AddComment = React.lazy(() => import( './containers/tickets/views/addComment'))
const AssignLogs = React.lazy(() => import( './containers/leads/views/assignLogs'))
const ExcludeCampaign = React.lazy(() => import( './containers/campaigns/views/excludeCampaign'))

let menu = [
    {

        path: '/dashboard',
        name: 'Dashboard',
        icon: <DashboardOutlined/>,
        key: 'dashboard',
        homepage: true,
        component: Dashboard,
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'officeAdministrator'],
    },
    {
        isLazy: true,
        path: '/dashboard/manager',
        name: 'Dashboard',
        icon: <DashboardOutlined/>,
        key: 'mangerDashboard',
        homepage: true,
        component: ManagerDashboard,
        authority: ['salesManager'],
    },
    {
        isLazy: true,
        path: '/dashboard/finance',
        name: 'Dashboard',
        icon: <DashboardOutlined/>,
        key: 'dashboard',
        //homepage: true,
        component: FinanceDashboard,
        authority: ['finance'],
    },
    {
        isLazy: true,
        path: '/changePassword',
        name: 'Change Password',
        dontShowOnMenu: true,
        key: 'changePassword',
        title: 'Change Password',
        component: ChangePassword,
        authority: ['salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
    },
    {

        isLazy: true,
        path: '/leads',
        icon: <StockOutlined/>,
        name: 'Leads',
        key: 'leads',
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
        children: [

            {
                isLazy: true,
                path: '/leads/stats',
                name: 'Lead Stats',
                title: 'Lead Stats',
                component: LeadStats,
                authority: ['admin', 'salesDirector'],
            },
            {
                isLazy: true,
                path: '/leads/add',
                name: 'Add Lead',
                title: 'Add Lead',
                component: AddLead,
            },

            {
                isLazy: true,
                path: '/leads/all',
                name: 'All Leads',
                title: 'All Leads',
                component: AllLeads,
                authority: ['admin', 'realEstateAdvisor', 'teleCaller'],
            },
            {
                isLazy: true,
                path: '/leads/sales-m-lead',
                name: 'Team Leads',
                title: 'Team Leads',
                component: SaleDirectorLeads,
                authority: ['salesDirector', 'salesManager'],
            },
            {
                isLazy: true,
                path: '/leads/myleads',
                name: 'My Leads',
                title: 'My Leads',
                component: MyLeads,
                authority: ['salesDirector', 'salesManager'],
            },
            // {
            //   path: '/leads/salesDirectorUnAssigned',
            //   name: 'Unassigned Leads',
            //   title: 'Unassigned Leads',
            //   component: SaleDirectorUnAssignedLeads,
            //   authority: ['salesDirector', 'salesManager']
            // },
            // {

            //   path: '/leads/salesDirectorAssigned',
            //   name: 'Assigned Leads',
            //   title: 'Assigned Leads',
            //   component: SaleDirectorAssignedLeads,
            //   authority: ['salesDirector', 'salesManager']
            // },

            {

                isLazy: true,
                path: '/leads/Unassigned/all',
                name: 'Unassigned Leads',
                title: 'Unassigned Leads',
                component: UnassignedLeads,
                authority: ['admin'],
            },
            {
                isLazy: true,
                path: '/leads/won',
                name: 'Won Leads',
                title: 'Won Leads',
                component: WonLeads,
                authority: ['admin'],
            },
            {
                isLazy: true,
                path: '/leads/unqualified',
                name: 'Unqualified Leads',
                title: 'Unqualified Leads',
                component: UnqualfiedLeads,
                authority: ['admin'],
            },
            // {

            //   path: '/leads/shared',
            //   name: 'Shared Leads',
            //   title: 'Shared Leads',
            //   component: SharedLeads,
            // },
            {
                isLazy: true,
                path: '/leads/assigned/list',
                name: 'Assigned Leads',
                title: 'Assigned Leads',
                component: AssignedList,
                authority: ['admin'],
            },

            {
                isLazy: true,
                'path': '/leads/detail',
                'name': 'View',
                'title': 'View',
                dontShowOnMenu: true,
                'component': ViewLead,
            },
            {
                isLazy: false,
                'path': '/leads/viewUserPage',
                'name': 'View',
                'title': 'View',
                dontShowOnMenu: true,
                'component': ViewUserLead,
            },
            {
                isLazy: true,
                path: '/leads/edit/',
                name: 'Edit Lead',
                title: 'Edit Lead',
                key: 'edit',
                dontShowOnMenu: true,
                component: AddLead,

            },
            {
                isLazy: true,
                path: '/leads/assignLogs/',
                name: 'Assign Logs',
                title: 'Assign Logs',
                key: 'assignLogs',
                component: AssignLogs,
                authority: ['admin'],

            },

        ],
    },
    {
        isLazy: true,
        path: '/inhouseList',
        name: 'Inhouse',
        key: 'inhouseList',
        title: '',
        icon: <FormOutlined/>,
        authority: ['admin'],
        children: [
            {
                path: '/inhouseList/list',
                isLazy: true,
                name: 'Unassigned Inhouse List',
                title: 'Unassigned Inhouse List',
                key: 'inhouseList',
                component: InhouseList,
                icon: <FormOutlined/>,
                authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
                children: [],

            }, {
                isLazy: true,
                path: '/inhouseList/assignedList',
                name: 'Assigned Inhouse List',
                key: 'inhouseList',
                title: 'Assigned Inhouse List',
                component: AssignedInhouseList,
                icon: <FormOutlined/>,
                authority: ['admin'],
                children: [],

            }],

    },
    {
        isLazy: true,
        path: '/inhouseListt',
        name: 'Inhouse',
        key: 'inhouseList',
        component: AssignedInhouseList,
        title: 'Inhouse List',
        icon: <FormOutlined/>,
        authority: ['salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],

    },
    {
        isLazy: true,
        path: '/followups',
        icon: <PlusSquareOutlined/>,
        name: 'Follow Ups',
        key: 'followups',
        component: AllFollowups,
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
        children: [
            {
                isLazy: true,
                path: '/followups/list',
                name: 'Follow Ups List',
                title: 'Follow Ups List',
                key: 'followups',
                component: AllFollowups,
            },
            {
                isLazy: true,
                path: '/followups/view',
                name: 'Today Follow Ups',
                title: 'Today Follow Ups',
                component: TodayFollowups,
            },

        ],

    },
    {
        isLazy: true,
        path: '/appointments',
        icon: <CalendarOutlined/>,
        name: 'Appointments List',
        title: 'Appointments List',
        key: 'appointments',

        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
        component: AllAppointments,
    },
    {
        isLazy: true,
        path: '/fb_success',
        name: 'Login Success',
        key: 'loginsuccess',
        dontShowOnMenu: true,
        component: FbSuccess,
        authority: ['admin', 'realEstateAdvisor'],
    },
    {
        isLazy: true,
        path: '/accounts',
        icon: 'chrome',
        name: 'Accounts',
        key: 'accounts',
        authority: ['superadmin'],
        children: [
            {
                isLazy: true,
                path: '/accounts/all',
                name: 'All Accounts',
                title: 'All Accounts',
                component: AllAccounts,
            },
        ],
    },
    {
        isLazy: true,
        path: '/allAds',
        icon: <FacebookOutlined/>,
        name: 'All Ads',
        key: 'allAds',
        component: AllAdsForStaff,
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager']
    },
    {
        isLazy: true,
        path: '/marketing',
        icon: <FacebookOutlined/>,
        name: 'Marketing',
        key: 'marketing',
        authority: ['admin'],
        children: [
            {
                path: '/marketing/addCampaigns',
                name: 'Add Campaign',
                title: 'Add Campaign',
                component: AddCampaign,
                isLazy: true,
            },
            {
                path: '/marketing/campaigns',
                name: 'Campaigns',
                title: 'Campaigns',
                component: AllCampaigns,
                isLazy: true,
            },
            {
                path: '/marketing/adsets',
                name: 'AdSets',
                title: 'AdSets',
                component: AllAddSets,
                isLazy: true,
            },
            {
                path: '/marketing/ads',
                name: 'Ads',
                title: 'Ads',
                component: AllAds,
                isLazy: true,
            },
            {
                path: '/marketing/excludeCampaign',
                name: 'Exclude Campaign',
                title: 'Exclude Campaign',
                component: ExcludeCampaign,
                isLazy: true,
            },
        ],
    },
   /* {
        isLazy: true,
        path: '/campaigns',
        icon: <FacebookOutlined/>,
        name: 'Campaigns',
        key: 'campaigns',
        authority: ['salesManager', 'salesDirector'],
        component: AllCampaigns,

    },*/
   /* {
        isLazy: true,
        path: '/marketingRequests',
        icon: <FacebookOutlined/>,
        name: 'Marketing Requests',
        key: 'Marketing Requests',
        authority: ['admin'],

        children: [
            {
                path: '/marketingRequests/add',
                name: 'Add Mk. Requests',
                title: 'Add Requests',
                component: AddRequests,
                isLazy: true,
            },

        ],

    },*/
    {
        isLazy: true,
        path: '/users',
        icon: <UserOutlined/>,
        name: 'Users',
        key: 'users',
        authority: ['admin', 'officeAdministrator'],
        children: [
            {
                path: '/users/add',
                name: 'Add User',
                title: 'Add User',
                key: 'add',
                component: AddUsers,
                isLazy: true,
            },
            {
                path: '/users/edit/:id',
                name: 'Edit User',
                title: 'Edit User',
                dontShowOnMenu: true,
                key: 'edit',
                component: AddUsers,
                isLazy: true,
            },
            {
                path: '/users/selfEdit',
                name: 'Edit User',
                title: 'Edit User',
                dontShowOnMenu: true,
                key: 'editUser',
                component: EditUser,
                isLazy: true,
            },
            // {
            //     path: '/users/viewProfile',
            //     name: 'View Profile',
            //     title: 'View Profile',
            //     dontShowOnMenu: true,
            //     key: 'viewProfile',
            //     component: ViewProfile,
            //     isLazy: true,
            // },
            {
                path: '/users/all',
                name: 'All User',
                title: 'All User',
                component: AllUsers,
                isLazy: true,
            },
            {
                path: '/users/changePassword',
                name: 'Change Password',
                key: 'changePass',
                dontShowOnMenu: true,
                title: 'Change Password',
                component: ChangePassword,
                isLazy: true,
            },
            {
                path: '/users/directory',
                name: 'Directory',
                title: 'Directory',
                component: DirectoryList,
                isLazy: true,
            },
            {
                path: '/users/checkins',
                name: 'CheckIns',
                title: 'CheckIns',
                component: CheckInsList,
                authority: ['admin', 'officeAdministrator'],
                isLazy: true,
            },
        ],
    },
    {
        isLazy: true,
        path: '/managerTeam',
        icon: <UserOutlined/>,
        name: 'Manager Team',
        title: 'Manager Team',
        key: 'managerTeam',
        component: ManagerTeam,
        authority: ['salesManager']
    },
    {
        isLazy: true,
        path: '/directorTeam',
        icon: <UserOutlined/>,
        name: 'Director Team',
        title: 'Director Team',
        key: 'directorTeam',
        component: DirectorTeam,
        authority: ['salesDirector']
    },
    {
        isLazy: true,
        path: '/communication',
        name: 'Communication',
        icon: <MailOutlined/>,
        key: 'communication',
        authority: ['admin'],
        children: [
            {
                isLazy: true,
                path: '/communication/communication',
                name: 'All Mails',
                icon: 'mail',
                key: 'allMails',
                title: 'Mails',
                component: AllCommunicationMails,
                authority: ['admin'],
            },
        ],
    },
    {
        isLazy: true,
        path: '/stages/:userId/:lte/:gte/:value',
        authority: ['admin'],
        title: 'Stages',
        key: 'stagesUrl',
        dontShowOnMenu: true,
        component: AllLeads,
    },
    {
        isLazy: true,
        path: '/followups/:userId/:lte/:gte',
        authority: ['admin'],
        title: 'Followups',
        key: 'followupsUrl',
        dontShowOnMenu: true,
        component: AllFollowups,
    },
    {
        isLazy: true,
        path: '/driver',
        name: 'Driver',
        icon: <CarOutlined/>,
        key: 'driver',
        authority: ['admin', 'adminManager', 'officeAdministrator'],
        children: [
            {
                isLazy: true,
                path: '/driver/add',
                name: 'Add Driver',
                icon: 'mail',
                title: 'Add Driver',
                component: AddDriver,
            },
            {
                isLazy: true,
                path: '/driver/edit',
                name: 'Add Driver',
                icon: 'mail',
                key: 'editDriver',
                title: 'Add Driver',
                component: AddDriver,
                dontShowOnMenu: true,
            },
            {
                isLazy: true,
                path: '/driver/list',
                name: 'Driver List',
                title: 'Driver List',
                component: DriverList,
            },
            {
                isLazy: true,
                path: '/journey/list',
                name: 'Driver Journeys',
                title: 'Driver Journeys',
                key: 'JouneysList',
                component: JouneysList,
            },
            {
                isLazy: true,
                path: '/driver/journey',
                name: 'Driver Journey',
                title: 'Driver Journey',
                key: 'journeyDetails',
                dontShowOnMenu: true,
                component: Journey,
            },
            {
                isLazy: true,
                path: '/driver/viewingLead/list',
                name: 'Viewing Lead List',
                title: 'Viewing Lead List',
                component: ViewingLeadList,
            },
            {
                isLazy: true,
                path: '/driver/viewingLead/add',
                name: 'Viewing Lead List',
                title: 'Viewing Lead List',
                dontShowOnMenu: true,
                key: 'viewingLead',
                component: ViewingLead,
            },

        ],
    },
    {
        isLazy: true,
        path: '/car',
        name: 'Car',
        icon: <CarOutlined/>,
        key: 'driver',
        authority: ['admin', 'adminManager', 'officeAdministrator'],
        children: [
            {
                isLazy: true,
                path: '/car/add',
                name: 'Add Car',
                title: 'Add Car',
                component: AddCar,
            },
            {
                isLazy: true,
                path: '/car/edit',
                name: 'Add Car',
                title: 'Add Car',
                key: 'editCar',
                component: AddCar,
                dontShowOnMenu: true,
            },
            {
                isLazy: true,
                path: '/car/list',
                name: 'Car List',
                title: 'Car List',
                component: CarList,
            },
        ],
    },
    {
        isLazy: true,
        path: '/call',
        name: 'Call',
        icon: <PhoneOutlined/>,
        key: 'call',
        // isLazy: true,
        authority: ['admin', 'adminManager', 'officeAdministrator'],
        children: [
            {
                isLazy: true,
                path: '/call/list',
                name: 'Call Logs',
                title: 'Call Logs',
                component: AllCalls,
            },
        ],
    },
    {
        isLazy: true,
        path: '/plots',
        name: 'Plots',
        icon: <LayoutOutlined/>,
        key: 'plots',
        authority: ['admin', 'Investor'],
        children: [
            {
                isLazy: true,
                path: '/plot/add',
                name: 'Add Plot',
                icon: 'mail',
                key: 'addPlot',
                title: 'Add Plot',
                component: AddPlot,
            },
            {
                isLazy: true,
                path: '/plot/list',
                name: 'Plot List',
                icon: 'mail',
                key: 'plotList',
                title: 'Plot List',
                component: PlotList,
            },
        ],
    },
    {
        isLazy: true,
        path: '/buyerPlots',
        name: 'Buyer Plots',
        icon: <BlockOutlined/>,
        key: 'buyerPlots',
        authority: ['admin', 'Investor'],
        children: [
            {
                isLazy: true,
                path: '/buyerPlots/add',
                name: 'Add Buyer Plots',
                icon: 'mail',
                key: 'addBuyerPlots',
                title: 'Add Buyer Plots',
                component: AddBuyerPlot,
            },
            {
                isLazy: true,
                path: '/buyerPlots/list',
                name: 'Buyer Plot List',
                icon: 'mail',
                key: 'buyerPlotList',
                title: 'Buyer Plot List',
                component: BuyerPlotList,
            },
            // {
            //   path: '/buyerPlots/add/plotImage',
            //   name: 'Add Plot Image',
            //   icon: 'mail',
            //   key: 'addPlotImage',
            //   title: 'Add Plot Image',
            //   component: AddPlotImage,
            //   authority: ['admin'],
            //
            // }
        ],
    },
    {
        isLazy: true,
        path: '/expense',
        name: 'Expense',
        icon: <TableOutlined/>,
        key: 'expense',
        authority: ['admin', 'Investor', 'finance', 'adminManager', 'officeAdministrator'],
        children: [
            {
                isLazy: true,
                path: '/expense/add',
                name: 'Add Expense',
                icon: 'mail',
                key: 'addExpense',
                title: 'Add Expense',
                component: AddExpense,
            },
            {
                isLazy: true,
                path: '/expense/edit',
                name: 'Add Expense',
                title: 'Add Expense',
                key: 'editExpense',
                component: AddExpense,
                dontShowOnMenu: true,
            },
            {
                isLazy: true,
                path: '/expense/list',
                name: 'Expense List',
                icon: 'mail',
                key: 'expenseList',
                title: 'Expense List',
                component: ExpenseList,
            },

        ],
    },
    {
        isLazy: true,
        path: '/attendance',
        name: 'Attendance',
        icon: <SolutionOutlined/>,
        key: 'attendance',
        authority: ['admin', 'adminManager', 'officeAdministrator'],
        children: [
            {
                isLazy: true,
                path: '/attendance/add',
                name: 'Add Attendance',
                title: 'Add Attendance',
                component: AddAttendance,
            },
            {
                isLazy: true,
                path: '/attendance/list',
                name: 'Attendance List',
                title: 'Attendance List',
                component: AttendanceList,
            },

        ],
    },
    {
        isLazy: true,
        path: '/target',
        name: 'Target',
        icon: <SolutionOutlined/>,
        key: 'target',
        authority: ['admin', 'salesManager', 'salesDirector'],
        children: [
            {
                isLazy: true,
                path: '/target/add',
                name: 'Add Target',
                title: 'Add Target',
                component: AddUserTarget,
                authority: ['admin'],
            },
            {
                isLazy: true,
                path: '/target/edit/',
                name: 'Edit Target',
                title: 'Edit Target',
                key: 'editTarget',
                dontShowOnMenu: true,
                component: AddUserTarget,
                authority: ['admin'],
            },
            {
                isLazy: true,
                path: '/target/list',
                name: 'Target List',
                title: 'Target List',
                component: UserTargetList,
                authority: ['admin', 'salesManager', 'salesDirector'],
            },

        ],
    },
    {
        isLazy: true,
        path: '/closing',
        name: 'Closing',
        icon: <SolutionOutlined/>,
        key: 'closing',
        authority: ['salesDirector', 'realEstateAdvisor', 'teleCaller', 'officeAdministrator', 'salesManager', 'admin'],
        children: [
            {
                isLazy: true,
                path: '/closing/add',
                name: 'Add Closing',
                title: 'Add Closing',
                component: AddClosing,
                authority: ['realEstateAdvisor', 'teleCaller', 'salesManager'],
            },
            {
                isLazy: true,
                path: '/closing/manager/list',
                name: 'Closing List',
                title: 'Closing List',
                component: ManagerClosingList,
                authority: ['salesManager'],
            },
            {
                isLazy: true,
                path: '/closing/agents/list',
                name: 'Closing List',
                title: 'Closing List',
                component: AgentsClosingList,
                authority: ['realEstateAdvisor', 'teleCaller'],
            },
            {
                isLazy: true,
                path: '/closing/director/list',
                name: 'Closing List',
                title: 'Closing List',
                component: DirectorClosingList,
                authority: ['salesDirector'],
            },
            {
                isLazy: true,
                path: '/closing/admin/list',
                name: 'Closing List',
                title: 'Closing List',
                component: AdminClosingList,
                authority: ['admin', 'officeAdministrator'],
            },

        ],
    },
    {
        isLazy: true,
        path: '/meeting',
        name: 'Meeting',
        icon: <ClockCircleOutlined/>,
        key: 'meeting',
        authority: ['admin', 'adminManager', 'officeAdministrator'],
        children: [
            {
                isLazy: true,
                path: '/meeting/add',
                name: 'Add Meeting',
                title: 'Add Meeting',
                component: AddMeeting,
                authority: ['admin', 'adminManager', 'officeAdministrator'],
            },
            {
                isLazy: true,
                path: '/meeting/edit',
                name: 'Add Meeting',
                title: 'Add Meeting',
                key: 'editMeeting',
                dontShowOnMenu: true,
                component: AddMeeting,
            },
            {
                isLazy: true,
                path: '/meeting/list',
                name: 'Meeting List',
                title: 'Meeting List',
                component: MeetingList,
            },
            {
                isLazy: true,
                path: '/meeting/inviteeList',
                name: 'Meeting List',
                title: 'Meeting List',
                component: InviteeList,
                dontShowOnMenu: true,
            },
        ],
    },
    {
        isLazy: true,
        path: '/listing',
        name: 'Listing',
        icon: <ClockCircleOutlined/>,
        key: 'listing',
        authority: ['admin', 'adminManager', 'officeAdministrator', 'salesManager'],
        children: [
            {
                isLazy: true,
                path: '/listing/add',
                name: 'Add Listing',
                title: 'Add Listing',
                component: AddListing,
            },
            {
                isLazy: true,
                path: '/listing/edit',
                name: 'Add Meeting',
                title: 'Add Meeting',
                key: 'editListing',
                dontShowOnMenu: true,
                component: AddListing,
            },
            {
                isLazy: true,
                path: '/listing/list',
                name: 'Listing List',
                title: 'Listing List',
                component: ListingList,
            },
        ],
    },
    {
        isLazy: true,
        path: '/ticket',
        name: 'Ticket',
        icon: <SolutionOutlined/>,
        key: 'ticket',
        children: [
            {
                isLazy: true,
                path: '/ticket/add',
                name: 'Add Ticket',
                title: 'Add Ticket',
                component: AddTicket,
            },
            {
                isLazy: true,
                path: '/ticket/edit',
                name: 'Edit Ticket',
                title: 'Edit Ticket',
                key: 'edit',
                dontShowOnMenu: true,
                component: AddTicket,
            },
            {
                isLazy: true,
                path: '/ticket/comment',
                name: 'Add Ticket Comment',
                title: 'Add Ticket Comment',
                key: 'comment',
                dontShowOnMenu: true,
                component: AddComment,
            },
            {
                isLazy: true,
                path: '/ticket/list',
                name: 'Ticket List',
                title: 'Ticket List',
                component: AllTickets,
            },

        ],
    },

    /*

      {
        isLazy: true,
        path: '/requirement',
        name: 'Requirement',
        key: 'requirement',
        icon: <FormOutlined/>,
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
        children: [
          {
            isLazy: true,
            path: '/requirement/add',
            name: 'Add Requirement',
            title: 'Add Requirement',
            component: AddRequirement,

          },
          {
            isLazy: true,
            path: '/requirement/all',
            name: 'All Requirement',
            title: 'All Requirement',
            component: requirementList,
          },
          {
            isLazy: true,
            path: '/requirement/edit',
            name: 'Edit Requirement',
            title: 'Edit Requirment',
            key: 'edit',
            dontShowOnMenu: true,
            component: AddRequirement,
          },
        ],
      },
      {
        isLazy: true,
        path: '/proposal',
        name: 'Proposals',
        key: 'proposal',
        icon: <BookOutlined/>,
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
        children: [
          {
            path: '/proposal/create',
            name: 'Create Proposal',
            title: 'Create Proposal',
            key: 'proposals',
            justShowMenu: true,
          },
          {
            isLazy: true,
            path: '/proposal/list',
            name: 'All Proposals',
            title: 'All Proposals',
            component: ProposalList,
          },

        ],
      },
      {
        isLazy: true,
        path: '/question',
        name: 'KnowledgeBase',
        icon: <ReadOutlined/>,
        key: 'communication',
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
        children: [
          {
            isLazy: true,
            path: '/question/add',
            name: 'Add Question',
            icon: 'mail',
            key: 'questions',
            title: 'Add Question',
            component: AddQuestion,
            authority: ['admin'],
          },
          {
            isLazy: true,
            'path': '/question/viewQuestion',
            'name': 'View',
            'title': 'View',
            dontShowOnMenu: true,
            'component': ViewQuestion,
          },
          {
            isLazy: true,
            path: '/question/list',
            name: 'Question List',
            icon: 'mail',
            key: 'questions',
            title: 'Question List',
            component: AllQuestion,
          },
        ],
      },
      {
        isLazy: true,
        path: '/project',
        icon: <FilePptOutlined/>,
        name: 'Project',
        key: 'project',
        authority: ['admin', 'salesDirector', 'realEstateAdvisor', 'teleCaller', 'salesManager'],
        children: [
          {
            isLazy: true,
            path: '/project/add',
            name: 'Add Project',
            title: 'Add Project',
            key: 'add',
            component: AddProject,
            authority: ['admin'],
          },
          {
            isLazy: true,
            path: '/project/edit/',
            name: 'Edit Project',
            title: 'Edit Project',
            key: 'edit',
            dontShowOnMenu: true,
            component: AddProject,

          },
          {
            isLazy: true,
            path: '/project/all',
            name: 'All Project',
            title: 'All Project',
            key: 'All',
            component: AllProject,
          }, {
            isLazy: true,
            path: '/project/map',
            name: 'Map Project',
            title: 'Map Project',
            key: 'map',
            component: MapProject,
          },

        ],
      },
    */

]

export const getUrlPushWrapper = (keyString, query) => {
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {
    if (!params) params = {}

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    let queryString = Object.keys(params)
        .map(key => key + '=' + params[key])
        .join('&')

    return `${val.path}?${queryString}`
}

export const getUrlParams = (keyString, route) => {
    let obj = getUrlObject(keyString)

    if (obj) {
        console.log('objjjjj is', obj, route)
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = keyString => {
    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    return val
}

let returnMenu = () => {
    let userInfo = localStorage.getItem('user')
    if (userInfo) {
        userInfo = JSON.parse(userInfo)
        if (_.includes(
            [agentUser.aish, agentUser.admin, agentUser.vidushi, agentUser.teena, agentUser.mary], userInfo._id)) {
            menu.push({
                path: '/hiring',
                name: 'HR ',
                icon: <UsergroupAddOutlined/>,
                key: 'hiring',

                authority: ['admin', 'salesDirector', 'teleCaller', 'realEstateAdvisor', 'officeAdministrator'],
                children: [
                    {
                        path: '/hiring/inviteInterview',
                        name: 'Send Invite IW',
                        title: 'Invite Interview',
                        key: 'inviteInterview',
                        authority: ['admin', 'salesDirector', 'teleCaller', 'realEstateAdvisor', 'salesManager'],
                        component: HiringInvite,
                    },
                    {
                        path: '/hiring/scheduledInterview',
                        name: 'Scheduled Interview',
                        title: 'Schedule',
                        key: 'scheduledInterview',
                        authority: ['admin', 'salesDirector', 'teleCaller', 'realEstateAdvisor', 'salesManager'],
                        component: CalendarView,
                    },
                    {
                        path: '/agentsCrawler',
                        icon: 'chrome',
                        name: 'Crawled Agents List',
                        title: 'Crawled Agents',
                        key: 'agentsCrawler',
                        authority: ['admin', 'salesDirector', 'teleCaller', 'realEstateAdvisor', 'salesManager'],
                        component: AllAgentsCrawler,
                    },
                    {
                        path: '/questionnaire',
                        icon: 'chrome',
                        name: 'IW Invited List',
                        title: 'Interview Invites',
                        key: 'questionnaire',
                        authority: ['admin', 'salesDirector', 'teleCaller', 'realEstateAdvisor', 'salesManager'],
                        component: AllQuestionnaire,
                    },
                ],
            })
            menu.push({
                path: '/offerLetter',
                name: 'Offer Letter',
                icon: <BookOutlined/>,
                key: 'offerLetter',
                authority: ['admin', 'salesDirector', 'teleCaller', 'realEstateAdvisor', 'officeAdministrator'],
                children: [
                    {
                        path: '/offerLetter/add',
                        name: 'Add Offer Letter',
                        icon: 'mail',
                        key: 'addOfferLetter',
                        title: 'Add Offer Letter',
                        component: AddOfferLetter,
                    },
                    {
                        path: '/offerLetter/list',
                        name: 'Offer Letter List',
                        icon: 'mail',
                        key: 'offerLetterList',
                        title: 'Offer Letter List',
                        component: OfferLetterList,
                    },
                    {
                        path: '/offerLetter/edit/',
                        name: 'Edit Letter',
                        title: 'Edit Letter',
                        key: 'editLetter',
                        dontShowOnMenu: true,
                        component: AddOfferLetter,

                    },

                ],
            })
        }
    }
    return menu
}

export default returnMenu()
