import {HomeOutlined} from '@ant-design/icons'
import styles from './style.less'
import {Button, Row, Col, Tag, Divider} from 'antd'
import {GrMap} from 'react-icons/gr'
import React, {useState} from 'react'
import moment from 'moment'
import {AiOutlineCloudDownload} from 'react-icons/ai'
import {downloadProjectVariationsFiles} from '../../proposalDetails/actions'
import {FaBed} from 'react-icons/fa'
import {MdOutlineBathtub} from 'react-icons/md'
import CF from 'react-currency-format'
import {currencyFormat} from '../../../request'

const VariationItem = (item) => {
    const {arr} = item
    const [loading, setLoad] = useState(false)
    const downloadMediaFiles = async ({type, projectId, projectName}) => {

        setLoad(true)
        let {file, fileExt} = await downloadProjectVariationsFiles({
            type,
            projectId,
            projectName
        })
        if (file) {
            var link = document.createElement('a')
            document.body.appendChild(link)
            link.setAttribute('type', 'hidden')
            link.href = file
            link.download = `${projectName}-${type}.${fileExt}`
            link.click()
            document.body.removeChild(link)
        }
        setLoad(false)


    }


    return <div>
        <Divider/>
        <Row>
            <Col style={{flex: 1}}>
                <div className={styles.variationHead}><b>Property has {arr && arr.length} Variations</b></div>
            </Col>
            <Col>
                {loading ? <b>Downloading....</b> : null}
            </Col>
        </Row>
        {
            arr.map((v, k) => {
                const {priceType, max, min} = v && v.price

                return (
                    <div key={k} className={styles.variationItem}>
                        <div style={{flex: 1}}>
                            <div className={styles.variationName}>{_.startCase(v && v.name)}</div>
                            <div className={styles.contactCtr}>
                                <div className={styles.iconCtrl}><FaBed
                                    className={styles.icon}/> Bedroom {v && v.bedRoom}
                                </div>
                                <div><MdOutlineBathtub className={styles.icon}/> Bathroom {v && v.bathRoom}</div>
                            </div>
                            <div className={styles.priceCtr}>
                                <div className={styles.iconCtrl}>Price :</div>
                                <div className={styles.priceVal}>
                                    <Row>
                                        {priceType && priceType == 'Fixed' ? <Col>
                                            <CF
                                                value={max}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={` ${currencyFormat}`}
                                                renderText={value => (
                                                    <div className={styles.priceField}>Fixed : {value}</div>
                                                )}
                                            />
                                        </Col> : <>

                                            <Col>
                                                <CF
                                                    value={min}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={` ${currencyFormat}`}
                                                    renderText={value => (
                                                        <div className={styles.minPriceField}>Min: {value}</div>
                                                    )}
                                                />
                                            </Col>
                                            <Col>
                                                <CF
                                                    value={max}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={` ${currencyFormat}`}
                                                    renderText={value => (
                                                        <div className={styles.priceField}>Max: {value}</div>
                                                    )}
                                                />
                                            </Col>

                                        </>}


                                    </Row>


                                </div>
                            </div>
                            <div className={styles.priceCtr}>
                                <div className={styles.iconCtrl}>Area :</div>
                                <div className={styles.areaCtrl}>
                                    <Tag color={'purple'}>Min : {v && v.area && v.area.from}</Tag> &nbsp;

                                    <Tag color={'purple'}> Max : {v && v.area && v.area.to}</Tag>
                                </div>

                            </div>
                        </div>
                        <div>
                            <Row>

                                {
                                    v.photos && v.photos.length ?
                                        <Col><Button disabled={loading} type={'primary'} size={'small'} onClick={() => {
                                            downloadMediaFiles({
                                                type: 'photos',
                                                projectId: v._id,
                                                projectName: v.name
                                            })
                                        }
                                        }>Photos <AiOutlineCloudDownload
                                            className={styles.downIcon}/></Button></Col> : null
                                }

                                {
                                    v.plan && v.plan.length ? <Col className={styles.colBox}><Button
                                        disabled={loading}
                                        type={'primary'} size={'small'} onClick={() => {
                                        downloadMediaFiles({
                                            type: 'plan',
                                            projectId: v._id,
                                            projectName: v.name
                                        })
                                    }
                                    }>Floor Plan <AiOutlineCloudDownload
                                        className={styles.downIcon}/></Button></Col> : null
                                }

                            </Row>
                        </div>


                    </div>
                )
            })
        }

    </div>
}

export default VariationItem
