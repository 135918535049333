import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Select, Checkbox, Input } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styles from './styles.less'
import { AddLeadActivity, SaveAndSendEmail, UpdateLead } from '../leads/actions'
import { getUserAction } from '../users/actions'
import { Divider } from 'antd'

const striptags = require('striptags')

const AddEmail = props => {
  const [email, setEmail] = useState()
  const [textNote, setTextNote] = useState('')
  const [emailChecked, setEmailChecked] = useState(false)
  const [whatsAppChecked, setWhatsAppChecked] = useState(false)
  const [smsChecked, setSmsChecked] = useState(false)
  const [subject, setSubject] = useState()
  const [message, setMessage] = useState(' ')
  // const [signature, setSignature] = useState()
  const [emailLoader, setEmailLoader] = useState(false)
  const [smsLoader, setSmsLoader] = useState(false)
  let { hideTitle, handleAddEmail, leadId, leadData } = props
  let user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    getCurrentuserSignature()
  }, [])


  const mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ['@'],
    source: function(searchTerm, renderList, mentionChar) {
      let values
      if (mentionChar === '@') {
        values = atValues
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm)

      } else {
        const matches = []
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i])
        renderList(matches, searchTerm)
      }
    },
    mentionContainerClass: styles.mentionContainer,
    onSelect: (item, insertItem) => {
      insertItem({ ...item, value: `{%=${item.value}%}` })
    },
    showDenotationChar: false
  }
  const saveAndShareEmails = async () => {

    if (!email) {
      return notification.error({ message: 'Enter emailId!' })
    }
    if (!subject) {
      return notification.error({ message: 'Enter subject!' })
    }
    if (!message) {
      return notification.error({ message: 'Enter message!' })
    }
    var element = document.getElementsByClassName('ql-editor')
    element[0].innerHTML = ''
    setEmailLoader(true)
    let x = await SaveAndSendEmail({ message, leadId, subject, email, leadData, from: user && user.email })
    if (!x.error) {
      notification.success({
        message: x.message
      })
      setMessage('')
      setSubject('')
      setEmail('')
      setEmailLoader(false)
      setEmailChecked(!emailChecked)

      if (handleAddEmail) {
        handleAddEmail()
      }
    } else {
      setEmailLoader(false)
    }

  }
  const getCurrentuserSignature = async () => {
    let { success, data } = await getUserAction({ userId: user._id })
    if (success && data) {
      setMessage(data.signature)
    }
  }


  let form = <div>
    {hideTitle && <React.Fragment>
      <div style={{ marginBottom: '15px' }}>
        <Checkbox checked={emailChecked} onChange={(val) => {
          setEmailChecked(!emailChecked)
          if (val.target.checked) setEmail(leadData && leadData.email)
        }}>Email</Checkbox>
        <Checkbox checked={whatsAppChecked} onChange={() => {
          setWhatsAppChecked(!whatsAppChecked)
        }}>WhatsApp</Checkbox>
        <Checkbox checked={smsChecked} onChange={() => {
          setSmsChecked(!smsChecked)
        }}>SMS</Checkbox>
      </div>

      <Input style={{ marginBottom: '10px' }} disabled={!emailChecked} value={email}
             placeholder={'Enter Email'} onChange={(v) => {
        setEmail(v.target.value)
      }} />

      <Input style={{ marginBottom: '10px' }} value={subject} disabled={!emailChecked}
             placeholder={'Enter subject'} onChange={(v) => {
        setSubject(v.target.value)
      }} />
      <ReactQuill value={message || ''} placeholder={'Type message...'} onChange={(text) => {
        setMessage(text)
      }} />


      <div className={styles.quoteAdd}><Button type="primary"
                                               loading={emailLoader || smsLoader}
                                               disabled={!(emailChecked || whatsAppChecked || smsChecked)}
                                               style={{ marginTop: '5px' }} onClick={async () => {
        if (!message) {
          return notification.error({ message: 'Enter message!' })
        }
        let whatsappMessage = striptags(message)

        if (emailChecked) {
          console.log('email block')
          saveAndShareEmails()
        }
        if (smsChecked) {
          console.log('smsm  block')
        }
        if (whatsAppChecked) {
          window.open(`http://api.whatsapp.com/send?phone=${props && props.leadMobile}&text=${whatsappMessage}&source=&data=`)
          await AddLeadActivity({
            message: `Whatsapp sent to "${props && props.leadMobile}"`,
            whatsApp: whatsappMessage,
            leadId: leadId,
            user: user && user.name,
            userId: user && user._id,
            type: 'whatsApp',
            from: user && user.email,
            email: leadData && leadData.phone
          })
          setWhatsAppChecked(!whatsAppChecked)

          var element = document.getElementsByClassName('ql-editor')
          element[0].innerHTML = ''
          if (handleAddEmail) {
            handleAddEmail()
          }

        }


      }}>Send</Button>
      </div>

    </React.Fragment>}
  </div>

  return (
    <React.Fragment>{!props.hideTitle ? <PageHeaderWrapper>{form}</PageHeaderWrapper> : form}</React.Fragment>
  )
}

export default AddEmail
