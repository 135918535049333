import {HomeOutlined} from '@ant-design/icons'
import styles from './style.less'
import {Button, Row, Col, Tag} from 'antd'
import {GrMap} from 'react-icons/gr'
import {GoMail} from 'react-icons/go'
import React, {useState} from 'react'
import moment from 'moment'
import {AiOutlineCloudDownload} from "react-icons/ai";
import {downloadProjectFiles} from "../../proposalDetails/actions";

const ProjectItem = (item) => {
    const {name, _id, locality, showSales, createdOn, projectName, salesScript, photos, brochure, plan} = item
    const [loading, setLoad] = useState(false)

    const downloadMediaFiles = async ({type, projectId, projectName}) => {

        setLoad(true);
        let {file, fileExt} = await downloadProjectFiles({
            type,
            projectId,
            projectName
        })
        if (file) {
            var link = document.createElement("a");
            document.body.appendChild(link);
            link.setAttribute("type", "hidden");
            link.href = file;
            link.download = `${projectName}-${type}.${fileExt}`;
            link.click();
            document.body.removeChild(link);
        }
        setLoad(false);


    }


    return <div>
        <h2><HomeOutlined className={styles.icon}/> {_.startCase(name)}</h2>
        <div className={styles.contactCtr}>
            <div><GrMap className={styles.icon}/> {locality}</div>
            <div className={styles.timeAgo}>{moment(createdOn).fromNow()}</div>
        </div>
        <div className={styles.projectInfoCtr}>
            <div className={styles.proName}>{projectName}</div>

        </div>

        <Row>

            {
                showSales && photos && photos.length ?
                    <Col><Button disabled={loading} type={'primary'} size={'small'} onClick={() => {
                        downloadMediaFiles({
                            type: 'photos',
                            projectId: _id,
                            projectName: name
                        })
                    }
                    }>Photos <AiOutlineCloudDownload className={styles.downIcon}/></Button></Col> : null
            }
            {
                showSales && brochure && brochure.length ? <Col className={styles.colBox}><Button
                    disabled={loading}
                    type={'primary'} size={'small'} onClick={() => {
                    downloadMediaFiles({
                        type: 'brochure',
                        projectId: _id,
                        projectName: name
                    })
                }
                }>Brochure <AiOutlineCloudDownload className={styles.downIcon}/></Button></Col> : null
            }
            {
                showSales && plan && plan.length ? <Col className={styles.colBox}><Button
                    disabled={loading}
                    type={'primary'} size={'small'} onClick={() => {
                    downloadMediaFiles({
                        type: 'plan',
                        projectId: _id,
                        projectName: name
                    })
                }
                }>Floor Plan <AiOutlineCloudDownload className={styles.downIcon}/></Button></Col> : null
            }
            {
                showSales ? <Col>
                    <div style={{
                        marginLeft: 10,
                        fontSize: 12
                    }}>{loading ? 'Downloading, may take few secs...' : null}</div>
                </Col> : null
            }
        </Row>


        {(salesScript && showSales) && <div style={{marginTop: '10px'}}>
            <h2>Sales Script</h2>
            <div dangerouslySetInnerHTML={{__html: salesScript}}></div>

        </div>
        }

    </div>
}

export default ProjectItem
