import { API_URL } from "../../../request";

export const addProjectUrl = () => {
    return API_URL + `/addProject`;
};
export const addProjectVariationUrl = () => {
    return API_URL + `/addProjectVariation`;
};

export const listProjectUrl = () => {
    return API_URL + `/listProjects`;
};
export const updateProjectUrl = () => {
    return API_URL + `/updateProject`;
};

export const listPropertyTypeandAmenitiesUrl = () => {
    return API_URL + `/list/project/amenities`;
};
export const searchProjectsUrl = () => {
    return API_URL + `/project/search`
}
export const variantsListUrl = () => {
    return API_URL + `/projectVariationList`
}
export const updateProjectVariationActionUrl = () => {
    return API_URL + `/updateProjectVariation`
}
