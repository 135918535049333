import React, { PureComponent, useEffect, useState } from 'react'
import {
  Steps,
  Card,
  Input,
  Select,
  DatePicker,
  Modal,
  Icon,
  Spin,
  Typography,
  InputNumber,
  Row,
  Col,
  Tabs,
  Collapse,
  Button,
  Form,
  Tag,
  Alert,
  Badge,
  Popconfirm,
  Popover,
  Avatar,
  Tooltip, Radio, Timeline,
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import moment from 'moment'
import CF from 'react-currency-format'

import {
  DislikeOutlined,
  LikeOutlined,
  PlusOutlined,
  PhoneOutlined,
  BookOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons'
import styles from './styles.less'
import { notification } from 'antd/lib/index'
import AddNote from '../../note/index'
import AddFollowup from '../../followup/views'

import AddEmail from '../../email/index'
import { RenderComments } from '../../activities/index'
import Activities from '../../activities/index'
import Appointment from '../../appointment/views/index'
import AddComment from './comment'
import { WonModal, LostModal } from './helpers'
import { getColorClass } from '../../../colors'
import UnqualifyLead from './unqualifyModal'
import { phoneCodeList } from '../../../country'

import { MdSnooze } from 'react-icons/md'
import {
  listLeads,
  leadsActivities,
  UpdateLead,
} from '../actions'
import {
  listFollowUpAction,
  addFollowUpAction,
  setFollowupsToFalse,
  addSmartFollowUp,
} from '../../followup/actions'
import { getStatusColor, leadStages, shareRatio } from '../../../request'
import SearchManager from './searchManager'

const {Step} = Steps
const {Panel} = Collapse
const {TabPane} = Tabs
const {Paragraph} = Typography
const {Option} = Select
const managerEmail = 'krishnam@bnwdubai.com'

const ViewLead = props => {

  const [form] = Form.useForm()

  const [loader, setLoader] = useState(false)
  const [leadData, setLeadData] = useState('')
  const [id, setId] = useState(props && props.leadId)
  const [activities, setActivities] = useState([])
  const [activeKey, setActiveKey] = useState('3')
  const [renderFollowup, setRenderFollowup] = useState()
  const [followupData, setFollowupData] = useState([])
  const [snoozeLoader, setSnoozeLoader] = useState(false)
  const [wonModal, setWonModal] = useState(false)
  const [lostModal, setLostModal] = useState(false)
  const [callLoader, setCallLoader] = useState(false)
  const [popOverVisible, setPopOverVisible] = useState(false)
  const [budgetTemp, setBudgetNumber] = useState(0)
  const [popOverVisible1, setPopOverVisible1] = useState(false)
  const [popOverVisible2, setPopOverVisible2] = useState(false)
  const [phoneCode, setPhoneCode] = useState()
  const [leadPhone, setPhone] = useState()
  const [additionalNo, setAdditionalNo] = useState([])
  const [snoozeModal, setSnoozeModal] = useState(false)
  const [snoozeValue, setSnoozeValue] = useState('30 minutes')
  const [snoozeDate, setSnoozeDate] = useState(moment().add(30, 'minutes'))
  const [snoozeKey, setSnoozeKey] = useState()
  const [commentData, setCommentData] = useState()
  const [shareManagerObj, setShareManager] = useState({})
  const [shareRatioVal, setShareRatio] = useState(null)

  let user = JSON.parse(localStorage.getItem('user'))

  const fetchFollowUp = async () => {
    let {data} = await listFollowUpAction({active: true, leadId: props.leadId || id})
    setFollowupData(data)
  }
  const setNewData = async leadId => {
    setLoader(true)
    let {data} = await listLeads({_id: leadId})

    if (data && data.length) {
      let agent = data[0]
      setLeadData(agent)
      if (agent.comments && agent.comments.length) {
        setCommentData(agent.comments[agent.comments.length - 1])

      }

      if (wonModal || lostModal) {
        getLeadByTypes('all')
      }
      fetchFollowUp()
      if (props && props.getTodoLeads && id != props.leadId) {
        props.getTodoLeads(true, 1)
      }
    }
    setLoader(false)
  }

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    setId(id)
    setLoader(false)
  }, [])

  useEffect(
    async () => {
      if (id) {
        setNewData(id)
      }
    },
    [id],
  )
  useEffect(
    async () => {
      if (id) {
        let {data} = await leadsActivities({leadId: id, type: 'all'})
        setActivities(data)
      }
    },
    [id],
  )
  const truncate = (str, n) => {
    if (str && str.length) {
      return str.length > n ? str.substr(0, n - 1) + '...' : str
    }
    return ''
  }

  const updateTheLead = async values => {
    values._id = leadData && leadData._id
    setLoader(true)
    let x = await UpdateLead(values)
    setLoader(false)
    if (!x.error) {
      notification.success({
        message: x.message,
      })
      setLeadData(x && x.data)

      //   setNewData(leadData && leadData._id)

    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  let rightSide

  if (
    (leadData && leadData.status == 'Open') ||
    (leadData && !leadData.status)
  ) {
    rightSide = (
      <div>
        <Button
          type="primary"
          size="large"
          style={{margin: '4px', background: 'white', color: 'green'}}
          onClick={() => {
            setWonModal(true)
          }}>
          <LikeOutlined className={'mr-2'}/> Won
        </Button>
        <Button
          type="danger"
          size="large"
          style={{margin: '4px', background: 'white', color: 'red'}}
          onClick={() => {
            setLostModal(true)
          }}>
          <DislikeOutlined className={'mr-2'}/> Lost
        </Button>
      </div>
    )
  } else {
    rightSide = <div className={styles.headerRight}/>
  }

  const onCancel = () => {
    setWonModal(false)
    setLostModal(false)
  }

  const getLeadByTypes = async (type) => {

    try {
      let {data} = await leadsActivities({
        leadId: id || (props && props.leadId),
        type: type,
      })
      setActivities(data)
    } catch (err) {
      console.log('error', err)
    }
  }

  const markQualified = async () => {
    let obj = {
      _id: leadData && leadData._id,
      qualified: true,
      qualifiedTime: new Date(),
    }

    setLoader(true)
    let x = await UpdateLead(obj)
    setLoader(false)
    if (!x.error) {
      notification.success({
        message: x.message,
      })
      setLeadData(x && x.data)

      // setNewData(leadData._id)
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  const handleCallUnattended = async () => {
    try {
      let body = {
        leadId: leadData && leadData._id,
        userId: user && user._id,
        user: user && user.name,
      }

      setCallLoader(true)
      //set next followup
      let x = await addSmartFollowUp(body)
      setCallLoader(false)
      if (!x.error) {
        notification.success({message: 'Followup Added'})
      } else {
        notification.error({message: 'Something went wrong'})
      }
      await getLeadByTypes('all')
      setNewData(leadData && leadData._id)
    } catch (err) {
      notification.error('Something went wrong')
    }
  }
  const snoozeChange = async (val) => {
    setSnoozeValue(val.target.value)
    let value

    switch (val.target.value) {
      case '2 minutes':
        value = moment().add(2, 'minutes')
        break

      case '5 minutes':
        value = moment().add(5, 'minutes')
        break
      case '15 minutes':
        value = moment().add(15, 'minutes')
        break

      case '30 minutes':
        value = moment().add(30, 'minutes')
        break

      case '2 hours':
        value = moment().add(2, 'hours')
        break
      case '4 hours':
        value = moment().add(4, 'hours')
        break
      case '1 days':
        value = moment().add(2, 'days')
        break
      case '2 days':
        value = moment().add(2, 'days')
        break
    }
    setSnoozeDate(value)
  }
  const handleOk = async () => {
    setSnoozeModal(false)
    setSnoozeLoader(true)
    await updateTheLead({
      snoozeTime: snoozeDate,
      snoozeDuration: snoozeValue,
    })
    setNewData(leadData && leadData._id)
    setSnoozeLoader(false)

  }

  const {assignDate, workingHourElapsed, assignExpireTime, assignExpired} = leadData

  return (
    <Spin size="large" spinning={loader}>
      <React.Fragment>
        <div className="abc" style={{margin: '10px'}}>
          <div className={styles.headerButtonWrapper}>
            <div>
              <span
                style={{
                  margin: '0 20px',
                  fontWeight: 800,
                  fontSize: '24px',
                }}>
                {leadData.customer || ''}
              </span>
              <span style={{fontSize: '20px', fontStyle: 'italic'}}>
                {leadData && leadData.city}
              </span>
              <span style={{
                marginLeft: '10px',
                fontSize: '20px',
              }}> {leadData && leadData.status &&
              <Tag color={getStatusColor(leadData.status)}>{leadData.status}</Tag>}</span>
              <span style={{
                marginLeft: '10px',
                fontSize: '24px',
                fontWeight: 800,
                color: 'cadetblue',
              }}>  {leadData && leadData.campaign_name ? `(${leadData.campaign_name})` : ''}
              </span>
            </div>
            {rightSide}
          </div>
          <div>
            <table style={{margin: '20px 4px', width: '100%', textAlign: 'left'}}>
              <thead>
              <tr>
                <th style={{width: '10%'}}>Budget</th>
                <th style={{width: '18%'}}>Rating</th>
                {/*<th style={{ width: '25%' }}>Estimated Close Date</th>*/}
                <th style={{width: '25%'}}>
                  {' '}
                  Next Followup{' '}
                  <Button
                    size="small"
                    style={{marginLeft: '5px'}}
                    type="primary"
                    onClick={() => {
                      setActiveKey('1')
                    }}>
                    {<PlusOutlined/>}
                  </Button>
                </th>
                <th style={{width: '25%'}}>
                  {' '}
                  Comments{' '}
                  <Button
                    size="small"
                    style={{marginLeft: '5px'}}
                    type="primary"
                    onClick={() => {
                      setActiveKey('3')
                    }}>
                    {<PlusOutlined/>}
                  </Button>
                </th>

                {/*{leadData &&*/}
                {/*!leadData.qualified &&*/}
                {/*leadData.status == 'Open' ? (*/}
                {/*    <th style={{width: '25%'}}>*/}
                {/*        <Button*/}
                {/*            size="small"*/}
                {/*            className={styles.purpleBtn}*/}
                {/*            style={{marginLeft: '5px'}}*/}
                {/*            type="primary"*/}
                {/*            onClick={() => markQualified()}>*/}
                {/*            Interested <LikeOutlined/>*/}
                {/*        </Button>*/}
                {/*    </th>*/}
                {/*) : null}*/}

                {leadData && leadData.tags && leadData.tags.length
                 ? _.map(leadData.tags, (x, key) => {
                    return (
                      <th
                        style={{
                          width: '25%',
                          backgroundColor: 'white',
                          border: '1px #d0d0d0 solid',
                          padding: '0 10px',
                        }}>
                        <Tag color="red">{x}</Tag>
                      </th>
                    )
                  })
                 : ''}
                <th style={{width: '25%'}}>
                  {' '}
                  Stage{' '}
                  <Select
                    showSearch
                    value={leadData.stage}
                    style={{width: 200}}
                    placeholder="Select Stage"
                    onChange={async (val) => {
                      updateTheLead({stage: val})
                      setNewData(leadData && leadData._id)
                      await getLeadByTypes('all')

                    }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={leadStages}
                  /></th>

                {/*{*/}
                {/*    leadData && leadData.stage == 'Untouched' ? <th style={{width: '25%'}}>*/}
                {/*            {' '}*/}
                {/*            <Button*/}
                {/*                size="small"*/}
                {/*                className={styles.contactedBtn}*/}
                {/*                style={{marginLeft: '5px'}}*/}
                {/*                type="primary"*/}
                {/*                onClick={async () => {*/}
                {/*                    updateTheLead({stage: 'Contacted'})*/}
                {/*                    setNewData(leadData && leadData._id)*/}
                {/*                    await getLeadByTypes('all')*/}

                {/*                }}>*/}
                {/*                Contacted <BookOutlined/>*/}
                {/*            </Button>*/}
                {/*        </th>*/}
                {/*        : null*/}
                {/*}*/}
                {/*{*/}
                {/*    leadData && leadData.stage == 'Contacted' ? <th style={{width: '25%'}}>*/}
                {/*            {' '}*/}
                {/*            <Button*/}
                {/*                size="small"*/}
                {/*                className={styles.contactedBtn}*/}
                {/*                style={{marginLeft: '5px'}}*/}
                {/*                type="primary"*/}
                {/*                onClick={async () => {*/}
                {/*                    updateTheLead({stage: 'Not Answered'})*/}
                {/*                    setNewData(leadData && leadData._id)*/}
                {/*                    await getLeadByTypes('all')*/}

                {/*                }}>*/}
                {/*                Not Answered <BookOutlined/>*/}
                {/*            </Button>*/}
                {/*        </th>*/}
                {/*        : null*/}
                {/*}*/}
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <div className={styles.editBudget}>
                    <Popover
                      content={
                        <div className={styles.popOverBudget}>
                          <InputNumber
                            defaultValue={leadData.budget}
                            className={styles.inputNUmber}
                            size={'small'}
                            onChange={val => {
                              setBudgetNumber(val)
                            }}
                            formatter={value =>
                              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                          />

                          <Button
                            className={styles.btnIcons}
                            size={'small'}
                            type="primary"
                            icon={<CheckOutlined/>}
                            onClick={async () => {
                              if (!isNaN(parseInt(budgetTemp))) {
                                await updateTheLead({
                                  budget: budgetTemp,
                                  changeList: true,
                                })
                                setPopOverVisible(false)
                              }
                            }}
                          />

                          <Button
                            className={styles.btnIcons}
                            size={'small'}
                            type="danger"
                            icon={<CloseOutlined/>}
                            onClick={() => setPopOverVisible(false)}
                          />
                        </div>
                      }
                      title="Edit Budget"
                      trigger="click"
                      visible={popOverVisible}
                      onVisibleChange={x => {
                        setPopOverVisible(x)
                      }}>
                      <Button type="link" style={{padding: 0}}>
                        {!leadData.budget && <span>+ Budget</span>}

                        {leadData.budget && <CF
                          value={leadData.budget}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'د.إ'}
                          renderText={value => (
                            <p className={styles.budgetNe}>{value}</p>
                          )}
                        />}


                      </Button>
                    </Popover>
                  </div>
                </td>
                <td>
                  <Select disabled={leadData && leadData.stage == 'Untouched'}
                          value={leadData && leadData.leadRating}
                          style={{width: 150}} onChange={async (val) => {
                    await updateTheLead({leadRating: val, changeList: true})
                  }}>
                    <Option value="Hot">Hot</Option>
                    <Option value="Cold">Cold</Option>
                    <Option value="Warm">Warm</Option>
                  </Select>
                  {/*<DatePicker*/}
                  {/*  value={*/}
                  {/*    leadData && leadData.estimateCloseDate*/}
                  {/*      ? moment(leadData.estimateCloseDate)*/}
                  {/*      : moment()*/}
                  {/*  }*/}
                  {/*  disabledDate={d =>*/}
                  {/*    !d || d.isBefore(moment().add(-1, 'day'))*/}
                  {/*  }*/}
                  {/*  onChange={async date => {*/}
                  {/*    if (date) {*/}
                  {/*      await updateTheLead({ estimateCloseDate: date, changeList: true })*/}
                  {/*    } else {*/}
                  {/*    }*/}
                  {/*  }}*/}
                  {/*/>*/}
                </td>
                <td>
                  <a
                    onClick={() => {
                      setActiveKey('1')
                    }}>
                      <span>
                        {followupData
                         ? followupData.length
                           ? truncate(followupData[0].description, 24)
                           : ''
                         : ''}
                      </span>
                    <br/>
                    <span>
                        <small>
                          {followupData && followupData.length
                           ? moment(followupData[0].followUpDate).format('ll')
                           : ''}
                        </small>
                      </span>
                    <br/>
                    <span style={{color: 'red'}}>
                        {followupData &&
                         followupData[0] &&
                         followupData[0].overdueCount ? (
                           <Badge
                             count={`OD (${followupData[0].overdueCount})`}
                             style={{backgroundColor: 'red'}}
                           />
                         ) : (
                           ''
                         )}
                      </span>
                  </a>
                </td>
                <td>
                  <a
                    onClick={() => {
                      setActiveKey('3')
                    }}>
                    <Popover id={'commentClass'} content={commentData && commentData.msg}
                             title={false}>
                      <span>
                              {commentData && commentData.msg ? truncate(commentData.msg, 24) : ''}
                      </span>
                      <br/>
                      <span>
                        <small>
                          {commentData && commentData.time
                           ? moment(commentData.time).format('ll')
                           : ''}
                        </small>
                      </span>
                    </Popover>
                  </a>
                </td>

              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div>
          <Row gutter={14} style={{marginRight: '0px'}}>
            <Col span={8} className="gutter-row">
              <Collapse
                style={{backgroundColor: 'white'}}
                bordered={false}
                defaultActiveKey={['2', '1', '4', '5']}>
                <Panel header="Lead Requirements" key="1">
                  <div className={styles.description}>
                    <b>
                      {' '}
                      <Paragraph
                        editable={{
                          onChange: async val => {
                            await updateTheLead({description: val, changeList: true})
                          },
                        }}>
                        {leadData.description
                         ? leadData.description
                         : 'Click to add description'}
                      </Paragraph>
                    </b>
                  </div>
                </Panel>


                {/*<Panel header="Share Lead" key="5">*/}

                {/*  {leadData && leadData.sharedManagerId ? <div style={{*/}
                {/*    paddingLeft: '20px', display: 'flex',*/}
                {/*    justifyContent: 'space-between'*/}
                {/*  }}>*/}
                {/*    <div>*/}
                {/*      <h4>Manager : {leadData && leadData.sharedManagerName}</h4>*/}
                {/*      <h4>Commission : {leadData && leadData.shareRatio}</h4>*/}
                {/*    </div>*/}

                {/*    {leadData && leadData.removeRequested ?*/}
                {/*      <small style={{ color: 'orange' }}> Request Under Removal</small> : <Popconfirm*/}
                {/*        title="Are you sure to remove this manager?"*/}
                {/*        onConfirm={async () => {*/}
                {/*          await updateTheLead({*/}
                {/*            changeList: true,*/}
                {/*            removeRequested: true*/}
                {/*          })*/}

                {/*          await getLeadByTypes('all')*/}
                {/*        }}*/}
                {/*        onCancel={() => {*/}

                {/*        }}*/}
                {/*        okText="Yes"*/}
                {/*        cancelText="No">*/}
                {/*        <Tooltip title={'Remove Sharing'}><CloseSquareOutlined /></Tooltip>*/}
                {/*      </Popconfirm>*/}
                {/*    }*/}
                {/*  </div> : <div style={{ paddingLeft: '20px' }}>*/}

                {/*    <Input.Group>*/}
                {/*      <Row gutter={8}>*/}
                {/*        <Col>*/}
                {/*          <label>Manager : </label>*/}
                {/*        </Col>*/}
                {/*        <Col>*/}
                {/*          <SearchManager allowLeadSharing={true} onSelect={async (e) => {*/}
                {/*            setShareManager(e)*/}


                {/*          }} />*/}

                {/*        </Col>*/}
                {/*      </Row>*/}
                {/*    </Input.Group>*/}

                {/*    <Input.Group size={'small'} style={{ marginTop: '15px' }}>*/}
                {/*      <Row gutter={8}>*/}
                {/*        <Col>*/}
                {/*          <label>Commission Sharing % : </label>*/}
                {/*        </Col>*/}
                {/*        <Col>*/}
                {/*          <InputNumber*/}
                {/*            className={styles.inputNUmber}*/}
                {/*            size={'small'}*/}
                {/*            max={100}*/}
                {/*            maxLength={3}*/}
                {/*            onChange={val => {*/}
                {/*              setShareRatio(val)*/}
                {/*            }}*/}
                {/*          /><br />*/}
                {/*          {shareRatioVal ? `Your share is ${100 - shareRatioVal} %` : ''}*/}

                {/*        </Col>*/}
                {/*      </Row>*/}
                {/*    </Input.Group>*/}


                {/*    <div>*/}
                {/*      <Button size={'small'} style={{ marginTop: '10px' }} type="primary" onClick={async () => {*/}
                {/*        if (shareManagerObj._id && shareRatioVal) {*/}

                {/*          await updateTheLead({*/}
                {/*            sharedManagerId: shareManagerObj && shareManagerObj._id,*/}
                {/*            sharedManagerName: shareManagerObj && shareManagerObj.name,*/}
                {/*            changeList: true,*/}
                {/*            shareRatio: `${shareRatioVal}% : ${100 - shareRatioVal}%`*/}

                {/*          })*/}
                {/*          await getLeadByTypes('all')*/}


                {/*        } else {*/}
                {/*          return notification.error({ message: 'Choose Manager & Ratio' })*/}
                {/*        }*/}
                {/*      }}>Save</Button>*/}
                {/*    </div>*/}

                {/*  </div>*/}
                {/*  }*/}


                {/*</Panel>*/}

                <Panel header="Contact" key="2">
                  {leadData && leadData.customer ? (
                    <div>
                      <Tooltip title={leadData.customer}>
                        <Avatar
                          style={{
                            backgroundColor: getColorClass(
                              leadData.customer[0].charAt(0) ||
                              leadData.customer,
                            ),
                          }}>
                          {leadData && leadData.customer
                           ? leadData.customer[0].charAt(0).toUpperCase() ||
                             leadData.customer.toUpperCase()
                           : ''}
                        </Avatar>
                      </Tooltip>{' '}
                      <span style={{paddingLeft: '10px'}}>
                                            {' '}
                        {leadData.customer}
                                             </span>
                      <>
                        <div style={{paddingLeft: '45px'}}>
                          {' '}
                          <Popover
                            content={
                              <div className={styles.popOverBudget}>
                                <Input.Group compact style={{marginBottom: '3px'}}>
                                  <Input
                                    value={leadPhone || (leadData && leadData.phone)}
                                    required={true}
                                    style={{width: '80%'}}
                                    onChange={(val) => setPhone(val.target.value)}
                                    placeholder="Mobile"/>

                                </Input.Group>


                                <Button
                                  className={styles.btnIcons}
                                  size={'small'}
                                  type="primary"
                                  icon={<CheckOutlined/>}
                                  onClick={async () => {
                                    if ((leadPhone || (leadData && leadData.phone))) {
                                      //  let phoneNumber = `${phoneCode || leadData.phoneCode}${leadPhone}`
                                      await updateTheLead({
                                        phone: (leadPhone || leadData && leadData.phone),
                                        // phoneCode: phoneCode || leadData.phoneCode
                                      })
                                      await getLeadByTypes('all')

                                      setPhone('')
                                    }
                                    setPopOverVisible1(false)
                                    setPhone('')

                                  }}
                                />

                                <Button
                                  className={styles.btnIcons}
                                  size={'small'}
                                  type="danger"
                                  icon={<CloseOutlined/>}
                                  onClick={() => setPopOverVisible1(false)}
                                />
                              </div>
                            }
                            title="Edit Mobile"
                            trigger="click"
                            visible={popOverVisible1}
                            onVisibleChange={x => {
                              setPopOverVisible1(x)
                            }}>
                            {leadData && leadData.phone} <EditOutlined/>
                          </Popover>
                        </div>
                        <div style={{paddingLeft: '45px'}}>
                          {' '}
                          {leadData && leadData.email ? (
                            leadData.email
                          ) : (
                             <span style={{color: 'red'}}>NO EMAIL</span>
                           )}
                        </div>

                      </>
                      <div style={{paddingLeft: '45px'}}>
                        {' '}
                        {leadData && leadData.city}
                      </div>
                      <div style={{paddingLeft: '45px'}}>
                        {' '}
                        {leadData && leadData.country}
                      </div>
                      <div style={{paddingLeft: '45px'}}>
                        {' '}
                        {leadData && leadData.additionalPhoneNumber.length
                         ? leadData.additionalPhoneNumber.join(',')
                         : ''}
                      </div>
                      {user && user.userType != 'salesManager' &&
                      <div style={{paddingLeft: '45px'}}>
                        {' '}
                        <Popover
                          content={
                            <div className={styles.popOverBudget}>
                              <Select
                                value={additionalNo && additionalNo.length ? additionalNo : (leadData &&
                                  leadData.additionalPhoneNumber)}
                                style={{width: '100%', marginBottom: '3px'}}
                                mode={'tags'}
                                onChange={(value) => {
                                  setAdditionalNo(value)
                                }}
                              >
                              </Select>
                              <Button
                                className={styles.btnIcons}
                                size={'small'}
                                type="primary"
                                icon={<CheckOutlined/>}
                                onClick={async () => {
                                  if (additionalNo && additionalNo.length) {
                                    await updateTheLead({
                                      additionalPhoneNumber: additionalNo,
                                    })
                                    await getLeadByTypes('all')

                                  }

                                  setPopOverVisible2(false)
                                  setAdditionalNo([])

                                }}
                              />

                              <Button
                                className={styles.btnIcons}
                                size={'small'}
                                type="danger"
                                icon={<CloseOutlined/>}
                                onClick={() => setPopOverVisible2(false)}
                              />
                            </div>
                          }
                          title="Add Phone Number"
                          trigger="click"
                          visible={popOverVisible2}
                          onVisibleChange={x => {
                            setPopOverVisible2(x)
                          }}>
                          <Button type="link" style={{padding: 0}}>
                            <span>+ Additional No</span>
                          </Button>
                        </Popover>
                      </div>}
                    </div>
                  ) : null}
                </Panel>

                {((leadData && leadData.status == 'Open') ||
                  (leadData && !leadData.status)) && (
                  <Panel header="Unqualify Lead?" key="3">
                    <UnqualifyLead
                      updateTheLead={updateTheLead}
                      user={user}
                      leadData={leadData}
                      handleUnqualify={async () => {
                        await getLeadByTypes('all')
                        setActiveKey('3')
                        setNewData(leadData && leadData._id)
                      }}
                    />
                  </Panel>
                )}

                {leadData && leadData.assigneeName ?
                 <Panel header="Lead Assign To" key="4">
                   <div style={{paddingLeft: '20px'}}>
                     <h3> {leadData.assigneeName}</h3>
                   </div>
                 </Panel> : ''
                }

                <Panel header="Lead Query" key="5">
                  <div style={{paddingLeft: '20px'}}>
                    {_.map(leadData && leadData.queAns, (item) => {
                      return <div>
                        <h5 style={{color: 'orange'}}>Ques: {item.name}</h5>
                        <h5 style={{color: 'green'}}>Ans: {item && item.values && item.values[0]}</h5>
                      </div>
                    })}
                  </div>
                </Panel>

              </Collapse>

              {
                leadData && leadData.assignTo ?
                <div className={styles.assignInfo}>
                  {assignExpired ?
                   <div className={styles.expired}>Assign time expired</div> : null}
                  <div className={styles.assignDate}>Assigned
                    on {moment(assignDate).format('lll')} </div>
                  <div className={styles.hourElapsed}>Work Hour Completed
                    : {workingHourElapsed} </div>
                </div> : null
              }


            </Col>
            <Col span={16} className="gutter-row">
              <Row>
                <Col span={24}>
                  <Card
                    bordered="false"
                    style={{
                      backgroundColor: '#e8fae6',
                      border: '1px solid #e8e8e8',
                    }}>

                    <div>
                      <Button
                        loading={callLoader}
                        onClick={handleCallUnattended}
                        type="primary"
                        size={'small'}
                        style={{
                          backgroundColor: '#ff5722',
                          borderColor: '#ff5722',
                          marginLeft: '10px',
                        }}>
                        <PhoneOutlined className={'mr-2'}/>
                        Call Not Picked
                      </Button>
                      <Button
                        loading={snoozeLoader}
                        type="primary"
                        size={'small'}
                        onClick={async () => {
                          setSnoozeValue('30 minutes')
                          setSnoozeModal(true)
                          setSnoozeDate(moment().add(30, 'minutes'))
                        }}
                        style={{
                          marginLeft: '10px',
                        }}>
                        Snooze
                        <MdSnooze
                          style={{marginLeft: '4px', verticalAlign: 'middle'}}
                        />
                      </Button>
                    </div>
                    <Tabs
                      style={{overflow: 'unset'}}
                      type="line"
                      activeKey={activeKey}
                      defaultActiveKey="3"
                      onTabClick={key => {
                        setActiveKey(key)
                        if (key == '1') {
                          setRenderFollowup(Date.now())
                        }
                      }}>
                      <TabPane tab={'Comments'} key={3}>

                        <AddComment
                          hideTitle={true}
                          leadId={leadData && leadData._id}
                          handleComment={async () => {
                            setActiveKey('3')
                            setNewData(leadData && leadData._id)
                            await getLeadByTypes('all')
                          }}
                        />

                        {commentData && <Card>
                          <div style={{
                            height: '110px',
                            'overflow-y': 'scroll', paddingTop: '4px',
                          }}>
                            <RenderComments data={leadData && leadData.comments}
                                            showInActivities={false}/>
                          </div>
                        </Card>}

                      </TabPane>

                      <TabPane tab={'Message'} key={0}>
                        <AddEmail
                          hideTitle={true}
                          leadId={leadData && leadData._id}
                          leadMobile={leadData && leadData.phone}
                          leadData={{...leadData, emails: null}}
                          handleAddEmail={async () => {
                            await getLeadByTypes('all')
                            setActiveKey('3')
                            setNewData(leadData && leadData._id)
                          }}
                        />
                      </TabPane>
                      <TabPane tab={'FollowUp'} key={1}>
                        <AddFollowup
                          hideTitle={true}
                          key={renderFollowup}
                          leadId={leadData && leadData._id}
                          handleAddFollowup={async () => {
                            setActiveKey('3')
                            setNewData(leadData && leadData._id)
                            await getLeadByTypes('all')
                          }}
                        />
                      </TabPane>

                      <TabPane tab={'Appointment'} key={2}>
                        <Appointment
                          hideTitle={true}
                          leadId={leadData && leadData._id}
                          handleAddAppointment={async () => {
                            setActiveKey('3')
                            // setNewData(leadData && leadData._id)
                            await getLeadByTypes('all')
                          }}
                        />
                      </TabPane>

                    </Tabs>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Activities

                    leadId={leadData && leadData._id} activities={activities}
                    assignLogs={leadData && leadData.assignLogs}
                    handleActivity={async (key) => {
                      console.log('key', key)
                      if (key == '0') await getLeadByTypes('all')

                      if (key == '1') {
                        await getLeadByTypes(['email', 'message', 'whatsApp'])
                      }
                      if (key == '2') {
                        await getLeadByTypes(['followUp'])
                      }
                      if (key == '3') {
                        await getLeadByTypes(['callLog', 'recording'])
                      }
                      if (key == '5') {
                        await getLeadByTypes([
                          'proposalCreated',
                          'proposalView',
                          'photoDownload',
                          'brochureDownload',
                          'planDownload'])
                      }
                      if (key == '6') {
                        await getLeadByTypes(['comment'])
                      }
                    }}
                  />
                </Col>
              </Row>

              {wonModal &&
              WonModal({
                wonModal,
                leadId: leadData && leadData._id,
                onCancel,
                updateTheLead,
                estimateCloseDate: leadData && leadData.estimateCloseDate,
                form,
              })}
              {lostModal &&
              LostModal({
                lostModal,
                leadId: leadData && leadData._id,
                onCancel,
                updateTheLead,
                estimateCloseDate: leadData && leadData.estimateCloseDate,
                form,
              })}
            </Col>
          </Row>
        </div>
        <Modal
          visible={snoozeModal}
          title={'Set Snooze Time'}
          onOk={handleOk}
          onCancel={() => setSnoozeModal(false)}
        >
          <Radio.Group value={snoozeValue} defaultValue={snoozeValue} onChange={snoozeChange}>
            <Radio className={styles.radioStyle} value={'2 minutes'}>2 Minutes</Radio>
            <Radio className={styles.radioStyle} value={'5 minutes'}>5 Minutes</Radio>
            <Radio className={styles.radioStyle} value={'15 minutes'}>15 Minutes</Radio>
            <Radio className={styles.radioStyle} value={'30 minutes'}>30 Minutes</Radio>
            <Radio className={styles.radioStyle} value={'2 hours'}>2 Hours</Radio>
            <Radio className={styles.radioStyle} value={'4 hours'}>4 Hours</Radio>
            <Radio className={styles.radioStyle} value={'1 days'}>1 Day</Radio>
            <Radio className={styles.radioStyle} value={'2 days'}>2 Days</Radio>
          </Radio.Group>
        </Modal>
      </React.Fragment>
    </Spin>
  )
}

export default ViewLead
