import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Input, Select, Spin } from 'antd'
import GetAllFormFields from '../../../components/_utils/formUtils'
import _ from 'lodash'
import { goBack } from 'connected-react-router'
import { listProjectAction } from '../../project/actions'
import { addLeadAction, listLeads, UpdateLead } from '../actions'
import { country, phoneCodeList } from '../../../country'
import { API_URL, platforms } from '../../../request'
import { useDispatch } from 'react-redux'

const { Option } = Select

const AddLead = props => {
  const [loader, setLoader] = useState(false)
  const [id, setId] = useState(null)
  const [projects, setProjects] = useState([])
  const [viewLead, setViewLead] = useState(false)
  const [phoneCode, setPhoneCode] = useState()
  const [spinLoader, setSpinLoader] = useState(false)
  const [showProperty, setShowProperty] = useState(false)
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue, getFieldValue } = form

  const onFinish = async values => {
    let x = null

    values.campaign_id = values.campaign_name

    values.inbound = {
      campaign_id: values.campaign_id,
      campaign_name: values.campaign_name
    }

    let projectFound = projects.find(item => item._id == values.projectId)
    if (projectFound) {
      values.projectName = projectFound.name
      values.projectMailer = projectFound.mailer
    }
    console.log(values.phoneCode)
    values.phone = `${values.phoneCode ? values.phoneCode : ''}${values.phone}`
    console.log(values, 'kkkkkkkkkkkkk')

    setLoader(true)
    if (id) {
      values._id = id
      x = await UpdateLead(values)
    } else {
      x = await addLeadAction(values)
    }

    setLoader(false)
    if (!x.error) {
      if (!id) {
        resetFields()
      } else {
        dispatch(goBack())
      }
      if (props.closeDrawer) {
        props.closeDrawer()
      }

      if (viewLead) {
        window.open(`${window.location.origin}/leads/detail?id=${x && x.data && x.data._id}`, '_blank')
      }
      notification.success({
        message: x.message
      })
    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }
  }

  const onFinishFailed = errorInfo => {
    console.log(errorInfo)
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }

  const fetchProjects = async () => {
    let { data } = await listProjectAction({ results: 10000 })
    setProjects(data)
  }


  useEffect(() => {
    fetchProjects()
  }, [])


  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    let id = searchParams.get('id')
    
    setId(id)

    setLoader(false)

  }, [])

  useEffect(async () => {
    if (id) {
      setSpinLoader(true)
      let { data } = await listLeads({ _id: id })

      if (data && data.length) {
        let lead = data[0]

        setFieldsValue({
          customer: lead && lead.customer,
          email: lead && lead.email,
          city: lead && lead.city,
          country: lead && lead.country,
          platform: lead && lead.platform,
          projectId: lead && lead.projectId,
          campaign_name: lead && lead.campaign_name,
          phone: lead && lead.phone.replace(lead && lead.phoneCode, ''),
          phoneCode: lead && lead.phoneCode,
          additionalPhoneNumber: lead && lead.additionalPhoneNumber,
          budget: lead && lead.budget,
          propertyType: lead && lead.propertyType,
          leadType: lead && lead.leadType

        })
        setShowProperty(lead && lead.leadType && lead.leadType.includes('Buy') ? true : false)
        setSpinLoader(false)
      } else {
        setSpinLoader(false)
      }

    } else {
      setSpinLoader(false)

    }
  }, [id])


  const inputTypes = {
    fields: [
      {
        label: 'Name',
        key: 'customer',
        required: true
      },
      {
        label: 'Email',
        key: 'email',
        required: true
      },
      {
        label: 'Phone',
        key: 'phone',
        required: true,
        hidden: id ? false : true
      },
      {
        label: 'Budget',
        key: 'budget',
        type: 'number'
      }
    ]
  }
  const inputTypes1 = {
    fields: [

      {
        label: 'City',
        key: 'city'
        //required: true
      },
      {
        label: 'Country',
        key: 'country',
        type: 'select',
        keyAccessor: x => x.countryName,
        valueAccessor: x => x.countryName,
        options: _.orderBy(country, ['countryName'], ['asc']),
        //required: true,
        showSearch: true
      },
      {
        label: 'Platform',
        key: 'platform',
        type: 'select',
        keyAccessor: (x) => x.id,
        valueAccessor: (x) => `${x.value}`,
        options: platforms,
        onChange: (xx) => {
          setFieldsValue({ platform: xx })
        },
        required: true
      },
      {
        label: 'Projects',
        key: 'projectId',
        type: 'select',
        keyAccessor: x => x._id,
        valueAccessor: x => x.name,
        options: projects,
        // required: true,
        showSearch: true
      },
      {
        label: 'Campaign',
        key: 'campaign_name'
        //required: true;
      }
    ]
  }

  return (
    <PageHeaderWrapper title={id ? 'Edit Lead' : 'Add Lead'}>
      <Card>
        <Spin spinning={spinLoader}>
          <div>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              initialValues={{
                price: {
                  number: 333,
                  currency: 'rmb'
                }
              }}>
              <GetAllFormFields inputSchema={inputTypes} />
              <Form.Item label="Phone" required={id ? false : true} hidden={id ? true : false}>
                <Input.Group compact>
                  <Form.Item
                    name={'phoneCode'}
                    rules={[{ required: id ? false : true }]}

                    noStyle
                  >
                    <Select
                      showSearch={true}
                      style={{ width: '20%' }}
                      dropdownMatchSelectWidth={false}
                      value={phoneCode}
                      optionLabelProp="value"
                      optionFilterProp="children"
                    >

                      {_.map(_.uniqBy(phoneCodeList, 'phoneCode'), (x) => (
                        <Option key={x.phoneCode} value={x.phoneCode}>
                          {x.name} ({x.phoneCode})
                        </Option>))}

                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={'phone'}
                    noStyle
                    rules={[{ required: true }]}
                  >
                    <Input style={{ width: '50%' }} placeholder="Mobile" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              <GetAllFormFields inputSchema={inputTypes1} />
              <Form.Item label={'Lead Type'} name={'leadType'}>
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  mode="multiple"
                  onChange={(val) => {
                    setFieldsValue({ leadType: val })
                    if (val.includes('Buy')) {
                      setShowProperty(true)
                    } else {
                      setShowProperty(false)
                    }
                  }}
                >
                  <Option value="Buy">Buy</Option>
                  <Option value="Sale">Sale</Option>
                  <Option value="Rent">Rent</Option>

                </Select>
              </Form.Item>
              <Form.Item rules={[{ required: showProperty }]}
                         hidden={!showProperty} label={'Property Type'}
                         name={'propertyType'}>
                <Select
                  showSearch
                  placeholder="Search to Select"
                  optionFilterProp="children"
                >
                  <Option value="Primary">Primary</Option>
                  <Option value="Secondary">Secondary</Option>

                </Select>
              </Form.Item>
              <Form.Item label={'Additional Phone No.'} name={'additionalPhoneNumber'}>
                <Select
                  mode={'tags'}
                >
                </Select>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button onClick={() => setViewLead(false)} type="primary" htmlType="submit" loading={loader}>
                  {id ? 'Edit' : 'Add'}
                </Button>
                <Button style={{ marginLeft: '15px' }} onClick={() => setViewLead(true)} type="primary"
                        htmlType="submit"
                        loading={loader}>
                  {id ? 'Edit & View' : 'Add & View'}
                </Button>
              </Form.Item>
            </Form>
          </div>

        </Spin>

      </Card>
    </PageHeaderWrapper>
  )
}

export default AddLead
