import { API_URL } from '../../../request'

export const addTargetUrl = () => {
  return API_URL + `/target`
}

export const userTargetCountUrl = () => {
  return API_URL + `/user/target`
}


