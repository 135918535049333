import axios from 'axios'
import CF from 'react-currency-format'
import styles from './containers/project/views/style.less'
import React from 'react'
import moment from 'moment'
import async from 'async'

//export let API_URL = 'http://localhost:8065'
 export let API_URL = 'https://crm-r.bnwdubai.com'

if (process.env.NODE_ENV == 'production') {
    API_URL = 'https://crm-r.bnwdubai.com'
}



let authAxios = axios.create({
    baseURL: API_URL,
})

export function downloadData (url, filename) {
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
                let a = document.createElement('a')
                a.href = URL.createObjectURL(b)
                a.setAttribute('download', filename)
                a.click()
            },
        )
    })
}

export const getToken = () => {
    return {
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')},
    }
}
export const timeStampFormat = 'DD/MM/YYYY HH:mm A'
export const agentUser = {
    vidushi: '61b1d13d3aa51319a898da36',
    kamy: '61d579c31b060d18a5692bcb',
    teena: '61b1d4e93aa51319a898da48',
    admin: '5f83f5c8156ba92fa8a454a0',
    aish: '62333268d392dd7cebde84e4',
    mary: '61c1817ecc6c516ac3dd4081',

}
export const bedRooms = ['1', '2', '3', '4', '5', '6', '7']
export const bathRooms = ['1', '2', '3', '4', '5', '6', '7', '8']
export const completionStatus = ['Off-Plan', 'Ready']
export const mapKey = 'AIzaSyAa1JQzP2OzrXtK6qCVjRja4dHam14pl00'

export const currencyFormat = 'د.إ'
export const builders = [
    {name: 'Sobha', id: '1', icon: 'https://crm-r.bnwdubai.com/builder/sobha.png'},
    {name: 'Emaar', id: '2', icon: 'https://crm-r.bnwdubai.com/builder/emaar.png'},
    {name: 'Azizi', id: '3', icon: 'https://crm-r.bnwdubai.com/builder/azizi.png'},
]
export const followUpTime = [
    '08:00 AM',
    '08:15 AM',
    '08:30 AM',
    '08:45 AM',
    '09:00 AM',
    '09:15 AM',
    '09:30 AM',
    '09:45 AM',
    '10:00 AM',
    '10:15 AM',
    '10:30 AM',
    '10:45 AM',
    '11:00 AM',
    '11:15 AM',
    '11:30 AM',
    '11:45 AM',
    '12:00 PM',
    '12:15 PM',
    '12:30 PM',
    '12:45 PM',
    '01:00 PM',
    '01:15 PM',
    '01:30 PM',
    '01:45 PM',
    '02:00 PM',
    '02:15 PM',
    '02:30 PM',
    '02:45 PM',
    '03:00 PM',
    '03:15 PM',
    '03:30 PM',
    '03:45 PM',
    '04:00 PM',
    '04:15 PM',
    '04:30 PM',
    '04:45 PM',
    '05:00 PM',
    '05:15 PM',
    '05:30 PM',
    '05:45 PM',
    '06:00 PM',
    '06:15 PM',
    '06:30 PM',
    '06:45 PM',
    '07:00 PM',
    '07:15 PM',
    '07:30 PM',
    '07:45 PM',
    '08:00 PM',
    '08:15 PM',
    '08:30 PM',
    '08:45 PM',
    '09:00 PM',
    '09:15 PM',
    '09:30 PM',
    '09:45 PM',
    '10:00 PM',
]
export const userTypeArr = [
    'salesDirector',
    'realEstateAdvisor',
    'teleCaller',
    'salesManager',
    'investor',
    'finance',
    'officeAdministrator',
    'marketing',
    'hr',
    'salesManager',
    'adminManager',
]
export const platforms = [
    {id: 'facebook', value: 'Facebook'},
    {id: 'gmail', value: 'Gmail'},
    {id: 'inbound', value: 'Inbound'},
    {id: 'landing', value: 'Landing'},
    {id: 'linkedin', value: 'Linkedin'},
    {id: 'tiktok', value: 'Tiktok'},
    {id: 'googleads', value: 'Googleads'},
    {id: 'personal', value: 'Personal'},
]

export const userType = [
    {id: 'salesDirector', value: 'Sales Director'},
    {id: 'realEstateAdvisor', value: 'Real Estate Advisor'},
    {id: 'teleCaller', value: 'Tele Caller'},
    {id: 'admin', value: 'Admin'},
    {id: 'investor', value: 'Investor'},
    {id: 'finance', value: 'Finance'},
    {id: 'officeAdministrator', value: 'Office Administrator'},
    {id: 'marketing', value: 'Marketing'},
    {id: 'hr', value: 'HR'},
    {id: 'salesManager', value: 'Sales Manager'},
    {id: 'adminManager', value: 'Admin Manager'},

]
export const leadSource = ['In House', 'Personal', 'Bayut']
export const chequeOption = [
    '1 cheque (Yearly)',
    '2 cheques (Bi-Yearly)',
    '3 cheques (Triannual)',
    '4 cheques (Quaterly)',
    '6 cheques (Bi-monthly)',
    '12 cheques (monthly)']
export const developerOption = [
    'Azizi Developments',
    'Credo Investments',
    'Damac Properties',
    'Mustafa bin Abdul Latif Group',
    'Nakheel',
    'Tameer Holding Investment LLC',
    'The Developer Properties',
    'Trident International Holdings',
    'Zabeel Properties',
    ' Aber Properties',
    ' Bando Engineering & Construction Co. Ltd',
    ' Business Center',
    ' Five Holdings',
    ' Gulf General Investments (GGICO)',
    ' H.H SHEIKH SUROOR BIN MOHAMMED AL NAHYAN ',
    ' INNOVATION SEZ DEVELOPER LTD',
    ' Lime Light ',
    ' Nakheel',
    ' UTMOST Properties',
    ' ZAYA LIVING REAL ESTATE DEVELOPMENT L.L.C',
    '32 Group',
    '32group',
    '4 Directions ',
    'A N K Developers',
    'A S A Developers Ltd',
    'A.S.A Developers Limited',
    'a=Amazon business centre ',
    'Aaa Facilities Management Services (L.L.C)',
    'Aaber Facilites Management (L.L.C)',
    'Aakar Developers Limited',
    'AB Properties (Bhatia Group)',
    'Ab Properties Limited',
    'Abbraj',
    'Aber',
    'abidos',
    'Abraaj',
    'Abraj',
    'Abwab Real Estate',
    'Abyaar',
    'ABYAAR DEVELOPMENT',
    'Abyaar Real Estate Development (K.S.C) (Dubai Br)',
    'ABYAR REAL Estat ',
    'Aces Holdings',
    'ACW Developer',
    'ACW Holdings',
    'Address Marina',
    'AJFAN L L C',
    'AJMAL ',
    'Ajmal Real Estate',
    'Ajman Villa',
    'AKOYA BY DAMAC',
    'Akshara Global',
    'Al abbas group',
    'Al Ain',
    'Al Ain Developer',
    'AL Ain Develpoments ',
    'al ain holdings',
    'Al Ain properties',
    'al ain property',
    'Al Alfahra',
    'AL ARYAM PHASE TWO L.L.C',
    'Al attar  ',
    'Al Barari',
    'Al bodoor real estate ',
    'Al Bodor',
    'Al Bodor Real Estate ',
    'Al Bodor Real Estate Development ',
    'Al Bodor Real Estate Development Limited',
    'Al Burooj',
    'Al Dar',
    'Al Dar Properties',
    'Al Dar Real Estate',
    'Al Derea Group',
    'Al Duaa Holdings (Fzc)',
    'Al Faara',
    'Al Fahad',
    'Al Fahad Holding',
    'Al Fahim',
    'al fajer',
    'AL Fajer Properies ',
    'Al fajer propertes',
    'Al Fajer Properties',
    'Al Fajer Properties (L.L.C)',
    'Al falak',
    'Al Fara\'a Properties',
    'al faraa ',
    'Al Faraa Homes 2',
    'al faraa properties',
    'Al Faraa Properties LLC',
    'Al Farah',
    'Al Fattan',
    'AL Fattan Properites L.L.C',
    'Al Fattan Properties',
    'Al Fattan Properties (L.L.C)',
    'Al Fattan Properties LLC',
    'Al Fattan Properties, LLC',
    'Al Forsan Real Estate',
    'Al Furjan',
    'Al Furjan LLC',
    'Al Futtaim',
    'Al Futtaim Group Real Estate',
    'Al Ghanem Real Estate Llc',
    'Al Ghurair',
    'Al Ghurair Group',
    'al ghurair properties',
    'Al Habtoor',
    'Al Habtoor City',
    'Al Habtoor group',
    'Al Habtoor Group LLC',
    'Al Habtour',
    'Al Hamra',
    'Al Helal',
    'Al Jawi Investment L.L.C',
    'Al Jaziri',
    'Al Jaziri Brothers',
    'AL Jiziri Brothers',
    'Al Khail Heights',
    'Al Khail Heights LLC',
    'Al Kharraz Meadows',
    'Al Madar',
    'Al Madar Holdings',
    'Al Madar Investment (L.L.C)',
    'al Manal',
    'Al Manal Developer',
    'Al Manal Development',
    'Al Manal Development Fzco',
    'Al Manara',
    'Al Masarat Real Estate',
    'Al Masarat Real Estate Development(Llc)',
    'Al Mashroom LLC',
    'Al Maskan',
    'Al Massaleh',
    'al mazaya ',
    'Al Mazaya Holding',
    'Al Murjan Real Estate ( Fzc )',
    'Al Nahda Real Estate',
    'Al Nakheel',
    'Al Rashid Investments (L.L.C)',
    'Al Rostamani Real Estate',
    'Al Safar General Contracting Co (LLC)',
    'Al Safeer',
    'Al Salam',
    'Al sandos',
    'Al Sayyah',
    'Al Sayyah & Sons Investment (L.L.C.)',
    'Al Sayyah & Sons Investment Co.',
    'Al Sayyah & Sons Investment Co. LLC',
    'Al Sayyah & Sons Investment LLC',
    'Al Sayyah Developments',
    'Al Sayyah Investment',
    'Al Seeb',
    'Al Seeb Developers',
    'Al Seeb Development',
    'Al Seeb Developments',
    'AL seeb properties',
    'Al Seeb Propeties',
    'Al Seeb Real Estate',
    'Al Seeb Real Estate Development',
    'AL SEEB REL ESTATE DEVELOPMENT',
    'Al Seef Investment',
    'Al Seef Investments',
    'Al Shafar',
    'Al Shafar - ASGC',
    'Al Shafar General Contracting',
    'Al Shaffer Development',
    'Al Shamsi Group (Llc)',
    'Al Shirawi',
    'Al Sondos',
    'Al Sondos Real Estate LLC',
    'Al Sooma',
    'AL SOOMA DEVELOPER',
    'Al Tajir',
    'Al Tajir Real Estate',
    'Al Tajir Real Estate (L.L.C)',
    'Al Tamimi Investments Limited',
    'Al Tayer',
    'Al Tayer Real Estate',
    'Al Thanyah Fifth JBC 4',
    'AL THANYAH FOURTH',
    'AL THURAYA REAL ESTATE',
    'Al Thuraya Real Estate Development Ltd',
    'al waleed ',
    'Al Waleed RE',
    'Al Waleed Real Estate',
    'Al walled real estat',
    'Al wasal',
    'Al Wasl',
    'Al Wasl Properties',
    'Al yousuf ',
    'Al yousuf group ',
    'Al Yousufi Tower ',
    'Al Zahia',
    'Al Zarooni',
    'Al Zarooni group',
    'Al Zarooni Group of Companies',
    'AL Zarouni',
    'Al-Futtaim Group Real Estate',
    'Albwardy Investment',
    'ALDAR',
    'Aldar Properties',
    'Alduaa Residence Limited',
    'Alef',
    'alef residences ',
    'Alfara',
    'Alfara\'A Properties (Llc)',
    'Alfaraa',
    'Alfurjan ( L.L.C )',
    'ALFURJAN LLC',
    'Ali Muhammad Saeed Bujsaim',
    'Allana Feroz Abdul Razak & Allana Shiraz Abdul Razak',
    'Almadar Group',
    'Alosaimi Real Estate Investments Co L.L.C',
    'alrostomaney',
    'ALSAYYAH',
    'Alseeb Real Estate Development',
    'Alshafar Development',
    'Alternative Capital Invest',
    'Alternative Capital Invest Gmbh (Branch)',
    'Althuraya Real Estate Development Limited',
    'Althuraya Real Estate Development LtdAlthuray',
    'ALZAHIA ',
    'amazon',
    'Amesco Tower Jlt',
    'Amwaj 4',
    'Anil Adinath Bastawade',
    'ANIS',
    'Anis Holdings Limited',
    'Anis Property Investments Limited',
    'ANK Developer',
    'ANK Developers',
    'Antonia Resources Limited',
    'apartment ',
    'Aqua',
    'Aqua properties',
    'AQUA PROPERTIES LTD',
    'Aqua Real Estate Development',
    'Arabia Group ',
    'Arabia Group Development Limited',
    'Arabia Group Investment Limited',
    'Arabian Gulf Properties',
    'Arabian Investments Limited',
    'Arabian Ranches 3 ',
    'Arabtec',
    'Arabtec & Al Rostamani',
    'Arada',
    'ARADA  aljada',
    'Arada Development LLC',
    'Arady Developments LLC',
    'ARANCO',
    'Aras Group',
    'Arch',
    'Arenco ',
    'arennco',
    'Arloid Development',
    'Armada',
    'Armada Group',
    'Armada Holding',
    'ART',
    'ARTAR',
    'Arthur & Hardman Real Estate Development LLC',
    'Arthur and Hardman Real Estate Development LLC',
    'Arthur Mcenzey',
    'ARTISTIC LEGEND REAL ESTATE DEVELOPMENT',
    'Artistic Properties',
    'ARY',
    'ARY Marina View',
    'Aryene Plus Property Developers Limited',
    'ASAM',
    'Asas ',
    'ascon al aweer',
    'Ascott',
    'ASGC',
    'Assas Investment Limited ',
    'ASTECO',
    'Aswan Developers Inc',
    'Aswan Investments Limited',
    'Atlantis The Royal Developer',
    'aurora ',
    'Avanti Holding Limited',
    'Avenue residence',
    'Avetona Global Limited',
    'AW Rostamani',
    'AWC',
    'Axis 1',
    'AXIS RESDECE 2',
    'Axon Development (Fzc)',
    'AYS',
    'AYS Developers',
    'az',
    'Aziz Developments',
    'AZIZI',
    'azizi  development',
    'AZIZI DEVELOPEMENT',
    'Azizi Developer',
    'azizi developers',
    'Azizi Development',
    'Azizi Developments',
    'AZIZI DEVELOPMENTS L.L.C',
    'Azizi Developments L.L.C.',
    'AZIZI DEVELOPMENTS LLC',
    'Azizi Devloper',
    'Azizi devlopments llc',
    'AZIZI investment LLC ',
    'Azizi Investments',
    'Azizi Investments (L.L.C)',
    'Azizi Real Estate Development',
    'Azure',
    'Azure Developers',
    'Azure Nakheel',
    'azure residence ',
    'Azzizi development',
    'B & M Fzco',
    'B&M FZCO',
    'B&M Property Developer',
    'Bafarawa General Trading (L.L.C)',
    'Bahwan',
    'Balqis Residence Fze',
    'Bando Engineering & Construction',
    'Bando Engineering & Construction Co Ltd - Dubai Branch',
    'Bando Engineering & Construction Co. Ltd.',
    'bando housing corp',
    'Bangash Builders & Developers Limited',
    'Bangash Properties',
    'Banyan Tree',
    'Banyar',
    'Baraq Holdings Limited',
    'Barton',
    'Bavaria Gulf',
    'Bavaria Gulf Sandoval Limited',
    'Bay Central Developments Limited',
    'Bay View Investments Limited',
    'bayan',
    'Beliza Resources Limited',
    'Bella Rose Real Estate Development  LLC',
    'Beney Investments Limited',
    'bh',
    'Bhatia Developer',
    'bhatia group ',
    'Bhatia Properties',
    'Biinghatti',
    'Bin Hamoodah',
    'Bin Hamoodah Group',
    'Bin Hamoodah Properties',
    'bin lahej',
    'Bina Real Estate L.L.C. ',
    'Binary tower',
    'Bingatti',
    'Binghatti',
    'Binghatti Developers',
    'Binghatti Holding',
    'binghatti stars',
    'Binhamoodah Properties',
    'Bloom',
    'BLOOM HEIGHTS PROPERTIES L.L.C',
    'Bloom Holding',
    'Bloom Holdings',
    'bloom properties',
    'Bloom Properties Developments',
    'Bloom Properties LLC',
    'Bloom Towers',
    'Blue Water',
    'Bluechip Developments',
    'Bluechip Real Estate  Development L.LC',
    'BLUECHIP REAL ESTATE DEVELOPMENT L.L.C,',
    'Bluechip Real Estate Development LLC',
    'Boission Limited',
    'BONNINGTON',
    'Bonnington Hospitality ',
    'Bonnington Hospitality Jlt',
    'Bonnington Hotels',
    'Bonnington Hotels Ltd',
    'Bonnington Hotels. Ltd',
    'Bonningtons',
    'Bonyan ',
    'Bonyan Emirates Properties',
    'bonyan international',
    'Bonyan International Investment ',
    'Bonyan International Investment Group',
    'Bonyan International Investment Group (Holding) (L.L.C)',
    'BONYAN LLC',
    'Botanica Tower',
    'Brighton Holdings Limited',
    'Brookes Corporation',
    'Bukhatir Properties International (L.L.C)',
    'Burj Al Dua\'A Limited',
    'Burj Al Nujoom',
    'Burj Alalam Holdings Limited',
    'Burj Daman ',
    'Business Bay Development',
    'Bux Holdings Limited',
    'Byblos Real Estate Broker',
    'California Spanish Villas Limited',
    'Canal Residence',
    'Capital Bay - B',
    'Capital Trust Gulf Limited',
    'Cappadocia ',
    'carlton',
    'carlton downtown ',
    'Cartel Development',
    'Cayan',
    'Cayan Development',
    'Cayan Developments',
    'Cayan Group',
    'Cayan International Real Estate Develoment LL',
    'CAYAN Palma',
    'cayan properties development  ',
    'CAYAN Property',
    'Cayan Property Development',
    'Cayan Real Estate Investment & Development L.L.C',
    'Cayan Real Estate Investment & Development LL',
    'Cayan Real Estate Investment and Development',
    'Cenita Global Limited',
    'Centurion',
    'CGTL',
    'Chaimaa',
    'Chaimaa Holding ',
    'Chaimaa holdings',
    'Chapal World L.L.C.',
    'Chi Development Group Limited',
    'Chimaa',
    'CHISOL',
    'Churchill Tower 2',
    'citadines ',
    'city apartment',
    'Cliff Dwellings Enterprises Ltd',
    'Comfort Homes Ltd',
    'Concord Developments',
    'Concord Tower',
    'Condor Properties Limited',
    'Corporate Finance House',
    'CPL Real Estate',
    'CPL real Estate Development',
    'CPL Real Estate Development L.L.C',
    'Creativ Properties Limited',
    'CREDO',
    'Credo Group',
    'CREDO INVESTMENT',
    'Credo Investments',
    'Credo Investments F Z E',
    'Crown',
    'Crown Holding Limited',
    'Crown One Holding Limited',
    'CTA STAR',
    'CTGL',
    'Custom',
    'Custom Built',
    'Customized ',
    'D1',
    'Daimond',
    'Daimond Developer ',
    'damaac',
    'Damac',
    'Damac  Properties ',
    'DAMAC Crescent Properties',
    'Damac Crescent Properties LLC',
    'Damac Developer ',
    'Damac Developers ',
    'Damac Development ',
    'Damac Development L.L.C',
    'Damac Group',
    'damac hills',
    'Damac Hills Golf Horizon A',
    'DAMAC HILLS/ ROCKWOOD',
    'Damac Paramount',
    'Damac Properties',
    'Damac Properties Co L.L.C',
    'DAMAC PROPERTIES CO LLC',
    'DAMAC PROPERTIES CP. LLC.',
    'Damac Properties LLC',
    'Damac Propertiex',
    'DAMAC PROPERTY',
    'Damac star',
    'Damac Waves B',
    'Damac World Real Estate LLC',
    'Damag',
    'daman',
    'Daman Investment ',
    'Daman Investments',
    'Daman Real Estate Capital Partners Ltd',
    'Damaq',
    'Damc',
    'DAMMAC',
    'Dana Property Development(L L C)',
    'Danbe',
    'danube',
    'Danube Developer',
    'Danube Home',
    'DANUBE PEROPERTIES',
    'Danube Properties',
    'Danube Properties Development ',
    'Danube Properties LLC',
    'Dar Al Arkaan',
    'Dar Al Arkan',
    'Dar-Al-Arkan',
    'das',
    'das holdings',
    'Dayaar',
    'dayyar',
    'De',
    'DEC',
    'DEC Tower',
    'DEC tower 1',
    'Deeraj',
    'Deeraj & East Co',
    'Deeraj & East Cost ',
    'DEEYAR',
    'Define Properties Llc-Fzc',
    'Desert Dream Investments & Development',
    'Desert Dream Investments & Development Properties (L.L.C)',
    'Desert Home Fzco',
    'Deval',
    'Deval Development L.L.C',
    'Deval Real Estate Development',
    'Developer: Bonnington Hotels Ltd',
    'Deyaar',
    'Deyaar Developers ',
    'Deyaar Development ',
    'Deyaar Development P.J.S.C',
    'Deyaar Development PJSC',
    'Deyaar Development PJSC.',
    'Deyar',
    'Deyar development',
    'Deyar PJSC',
    'DEYYAR',
    'DHEERAJ',
    'Dheeraj & East Coast (L.L.C)',
    'Dheeraj & East Coast L.L.C',
    'Dheeraj & East Coast LLC',
    'Dheeraj East Coast',
    'Dheeraj estate ',
    'dheeraj group',
    'Dheeraj Group & East Coast LLC',
    'Dheraaj',
    'Diamond Developers Co. Limited ',
    'DIP',
    'Dipak Kumar Shah/Wentworth&Trio Properties Ltd',
    'dirc',
    'Discovery Gardens',
    'Discovery Gardens (L.L.C)',
    'Distinguish Real Estate',
    'District One',
    'DMCC',
    'Doora',
    'Dora Bay',
    'DORRA',
    'Dorra Bay',
    'Dorra Development',
    'DORRA DEVELOPMENTS',
    'dorra V1 limited',
    'DP',
    'DPG',
    'drc',
    'Dream',
    'Dream Tower',
    'DREAM TWR',
    'DREAM TWR 1',
    'DREC',
    'DSC ',
    'DSC Master Developer',
    'Dsec Corporation Fzc',
    'DSO',
    'Du',
    'dubai Al quoz',
    'Dubai Arch',
    'Dubai Asset Management',
    'Dubai Festival City',
    'Dubai Golf City (L.L.C)',
    'Dubai Guernsey Property Investment Limited',
    'Dubai Hills',
    'dubai hills estate',
    'DUBAI HILLS ESTATE L.L.C',
    'Dubai Holding',
    'Dubai Holding Property',
    'Dubai Holdings',
    'Dubai Holdings Group',
    'Dubai Industrial Park',
    'Dubai Investment Park',
    'Dubai Investment Real Estate',
    'Dubai Investment Real Estate (L L C)',
    'Dubai Investment Real Estate Company',
    'Dubai Investments',
    'Dubai Investments park Development Co. LLC',
    'Dubai Investments Real  Estate Company',
    'Dubai Investments Real Estate Company',
    'dubai land',
    'Dubai Life Style City L L C',
    'Dubai Multi Commodities Center',
    'Dubai Porperties',
    'dubai pro',
    'Dubai Proeprties',
    'Dubai Properites',
    'Dubai Properities ',
    'DUBAI PROPERTES',
    'Dubai Properties',
    'Dubai Properties Group',
    'Dubai Properties Group, TECOM Group',
    'Dubai Properties LLC',
    'Dubai Property',
    'Dubai Property Group',
    'Dubai Property Ring Ltd',
    'Dubai Propeties',
    'Dubai proprties',
    'Dubai Real Estate Centre (DREC)',
    'dubai real estate cooperation',
    'Dubai Silicon Oasis',
    'Dubai Silicon Oasis Authority',
    'Dubai South',
    'Dubai South The Pulse',
    'Dubai Sport City',
    'Dubai Sports City',
    'Dubai Sports City L.L.C',
    'Dubai Walls',
    'Dubai Water Front (L.L.C)',
    'DubaiHoldings',
    'dubia property ',
    'Dujan Properties Ltd',
    'dune',
    'Dune Real Estate Developers L.L.C.',
    'dunes',
    'Dunes Group Developments Limited',
    'Dunes Properties',
    'Dunes Property Investments Ltd',
    'Dunes Village Real Estate',
    'durar ',
    'Durar Al Emarat Properties LLC',
    'Durar Properties ',
    'Dusit International',
    'Dutco',
    'DWTC',
    'Dyaar',
    'Eagle Hills',
    'Eamar',
    'Earth developer llc',
    'Earth Developers',
    'Earth Developers L.L.C ',
    'Eastern Group Limited',
    'EAT PROJECT LTD',
    'Eemar',
    'Eisa Bin Nasser Bin Abdullatif Alserkal',
    'Elington ',
    'Elington Properties',
    'Elite',
    'Elite City',
    'ELITE DEVELOPERS',
    'Elite Resi',
    'elligton ',
    'Ellingt',
    'Ellington',
    'Ellington Developer',
    'Ellington Properties',
    'ELLINGTON PROPERTIES DEVELOPMENT L.L.C',
    'Ellington Properties Development LLC',
    'EMAAAR',
    'Emaad',
    'Emaar',
    'Emaar Developer',
    'Emaar Developers',
    'Emaar Development',
    'Emaar Development LLC',
    'Emaar Dubai LLC',
    'Emaar Dubai South',
    'Emaar Park Island Sanibel',
    'Emaar Properites ',
    'EMAAR Properties',
    'Emaar Properties (PJSC)',
    'Emaar South',
    'Emerald',
    'Emerald Palace Group ',
    'Emerald Resi',
    'Emerald Residence',
    'Emirates ',
    'Emirates D1 Limited',
    'Emirates For Industrial Cities Co.',
    'emirates garden',
    'Emirates Hills',
    'emirates National Investment',
    'Emirates National Investment Co',
    'Emirates National Investment Co (L.L.C)',
    'Emirates National Investments',
    'Emirates Properties Investment',
    'Emirates Properties Investments',
    'Emirates Properties Investments Ltd',
    'Emirates Property Investment Co',
    'Emirates Pv Dubai Limited',
    'Emirates Residential And Commercial Properties',
    'Emkay Properties',
    'Emmar',
    'emmr',
    'Empire',
    'Empire Abrabia Real Estate Developers LLC',
    'Empire Arabia LLC',
    'Empire Arabia Real Estate Developers LLC',
    'Empire Prestige Properties',
    'Enaar',
    'ENI',
    'Ensha',
    'Enshaa',
    'Enshaa Development LTD',
    'ENSHAA PSC',
    'EPG',
    'Esca Properties Llc',
    'ESCAN',
    'Escan Investments',
    'Escan Twr',
    'Espada Busines Center',
    'Espada Business Center',
    'Estimar reality',
    'eta',
    'ETA - ASCON',
    'ETA Devloper',
    'eta star',
    'ETA Star - Giga',
    'ETA Star -Giga',
    'ETA Star developer ',
    'ETA STAR PROEPRTY DEVELOPERS L.L.C.',
    'ETA star property developer',
    'Eta Star Property Developers L.L.C.',
    'Etstaar',
    'Ever Shine Investments Limited',
    'evershine 1',
    'Executive Bay ',
    'executive Holding Limited ',
    'Executive Holding LTD',
    'Executive Holdings Ltd.',
    'FAB',
    'fAB property',
    'Fabson Import Export Limited',
    'FairView Residence',
    'Fakhruddin',
    'Fakhruddin Holdings',
    'Fakhruddin Properties',
    'Fakhruddin Properties Limited',
    'Fakhrudiin',
    'Fakhrudin Properties ',
    'FakhrudinProperties ',
    'Falak  Properties',
    'falak properties',
    'Falaknaz',
    'Falcon City ',
    'falcon city of wonder',
    'falcon city of wonder LLC',
    'falcon City of Wonders',
    'Falcon City Of Wonders (L.L.C)',
    'Falcon City of Wonders LLC',
    'Falconcity of Wonders',
    'FAM Holding',
    'Fandah Palace Development',
    'Fandah Palace Development LLC',
    'faraa developer',
    'FARRA Development',
    'Fatima International ',
    'Fatima International (Fzc)',
    'Fatimah International ',
    'Faz',
    'FGB',
    'FGB Properties',
    'First Central',
    'First Central Group',
    'First Group',
    'First Gulf Bank',
    'First Homes Limited',
    'FIVE',
    'FIVE Development',
    'Five Elements',
    'Five Elements Real Estate Development LLC',
    'Five holdings',
    'Five Real Estate',
    'Five Real Estate Development',
    'Fortis',
    'Fortis Plus Holdings Limited',
    'Fortune ',
    'Fortune Avenue Investment Limited',
    'Fortune Bay Investment Holdings Limited',
    'Fortune Dxb Ltd',
    'Fortune Group',
    'Fortune Investments',
    'Fortune Serene Limited',
    'Fortune Tower',
    'Forum Group DMCC',
    'four points ',
    'Fujairah contracting',
    'Full Circle Technical Co.',
    'G & Co',
    'G AND CO',
    'G&Co',
    'G&Co.',
    'Galadari Investment Office Limited',
    'Garden Homes Frond D Palm Jumeirah ',
    'Gargash',
    'Gate',
    'gates',
    'GDP Investment',
    'Geepas',
    'Gemini',
    'Gemini Splendor',
    'GGICCO',
    'GGICO',
    'GGICO Properties',
    'GGR',
    'ghaya',
    'GHREIWATI PROPERT',
    'Ghreiwati Properties',
    'Ghurair Star Giga',
    'GIGA',
    'gigco',
    'gigico',
    'Giovanni ',
    'GJ Real Estate',
    'Global',
    'Global Group Holdings Limited',
    'Global Group International Limited',
    'Global Investment Group FZC',
    'Global Procurment Fzc',
    'Global Realty Partners',
    'gold crest ',
    'Gold crest 1',
    'Gold crest 2',
    'Gold Vision Development Limited',
    'Goldcrest',
    'Goldcrest Executive',
    'Goldcrest Views 2',
    'Golden Beach Properties Limited',
    'Golden Circle ',
    'GOLDEN CIRCLES',
    'Golf vedutah',
    'gpd',
    'GPD INVESTMENT',
    'Gpd Investments (L.L.C)',
    'Gpd Investments Spv Eight Limited ',
    'Gpd Investments Spv Four Limited',
    'Gpd Investments Spv One Limited',
    'Gpd Investments Spv Three Limited',
    'GPD Investmets',
    'GRANDMIDWEST',
    'Green',
    'Green & Co',
    'Green Desert Ventures',
    'Green Desert Ventures Inc',
    'Green Emirates',
    'Green Emirates Properties',
    'Green Emirates Properties Co.Psc',
    'Green Group',
    'Green Lake',
    'Green Lakes',
    'Green Properties ',
    'Green Properties Development Co',
    'Green Properties Development LLC',
    'Green Yard',
    'Green Yard Properties',
    'Green Yards Properties',
    'Greens',
    'Greens properties',
    'greenyard',
    'Greenyard properties development LLC',
    'GreenYard property development LLC',
    'Grm Limited',
    'Groovy',
    'Grovey',
    'GROVY',
    'GROVY REAL ESTATE  DEVELOPMENT LLC',
    'Grovy Real Estate Development',
    'Grovy Real Estate Development LLC',
    'Gulf Developers Fzc',
    'Gulf General Investmenst',
    'Gulf General Investment',
    'Gulf General Investments (GGICO',
    'Gulf General Investments (GGICO)',
    'Gulf General Investments Co. (P.S.C)',
    'GULF GENERAL INVESTMENTS CO.(PSC)',
    'Gulf General Investments(GGICO)',
    'Gulf Investments (Fze)',
    'Gulf Land',
    'Gulf shores ',
    'Gulf Shores Inc',
    'Gulf Sotheby’s International Realty',
    'Gulf Towers Investment Llc',
    'H.A.B.N',
    'H.H SHEIKH SUROOR BIN MOHAMMED AL NAHYAN ',
    'Habn',
    'Habtoor',
    'HABTOOR GROUP LLC',
    'halcon',
    'Halcon Real Estate',
    'Halcon Real Estate Developmemnt (L.L.C)',
    'Halcon Real Estate Development',
    'Halcon Real Estate.',
    'Hamza Namera Int\'l ',
    'Hamza namera International',
    'Hamza Namera International (Fzc)',
    'hamza tower',
    'Hamza-Namera International',
    'HAN Woldwide',
    'HAN worldwide Enterprises',
    'Hans Investments',
    'Harbor Real Estate',
    'Harbour ',
    'HASSAN AL AMIR',
    'hayati',
    'HDS ',
    'hds business centre ',
    'HDS GROUP',
    'HDS Tower ',
    'Heights ',
    'Her Limited',
    'High Rise Properties',
    'hiranadani',
    'Hiranandani',
    'Hircon ',
    'Hircon International',
    'Hircon International L.L.C',
    'Hircon International LLC',
    'HMB Homes',
    'HMB properties',
    'Horizon Infrastructure (Fzc) ',
    'Hub Canal',
    'Humaid Bindrai',
    'hyatt',
    'Hydra Properties ( L.L.C )',
    'Iah',
    'IBT',
    'ICON',
    'Icon 2',
    'IFA',
    'IFA Hotels & Resorts',
    'ifa hotels and resort ',
    'IFA Nakheel',
    'IFA Properties',
    'IFA Resort and Spa',
    'IGO',
    'IGO Property Development',
    'IHG',
    'Ilyas & Mustafa Galadari Management Investment & Development(L.L.C)',
    'Imad Developers ',
    'imam',
    'Iman',
    'iman developer',
    'Iman Developers',
    'IMPERIAL AVENUE ',
    'IMS',
    'Imtiaz Developments',
    'Indigo',
    'indigo icon',
    'Indigo Properties',
    'Indigo Properties LLC',
    'Innovation SEZ Developer Ltd',
    'International Business Tower LLC',
    'International city',
    'International City ( L.L.C )',
    'International Merchant House (L.L.C)',
    'International Merchant House Real Estate Deve',
    'International Merchant House Real Estate Development (L.L.C)',
    'Invest Group Overseas',
    'Invest Group Overseas (IGO)',
    'Invest Properties',
    'Investment Corporation of Dubai',
    'iris ',
    'Iris Blue',
    'Irlend In The Sun Limited',
    'ishraqa',
    'Ishraqah',
    'Jab Developments Inc',
    'Jad23 Investment Limited',
    'Jad24 Investment Limited',
    'Jad25 Investment Limited',
    'JAM',
    'Jasmine Garden Limited',
    'JBC',
    'JBC Properties',
    'Jersey Properties',
    'Jersey Properties LLC',
    'JGE',
    'jlt',
    'JMS',
    'Jones Lang Lasalle UAE Limited',
    'Juma Al Majid',
    'Jumairah Village L.L.C',
    'Jumeirah Bay',
    'Jumeirah Golf Estate',
    'Jumeirah Golf Estates',
    'Jumeirah Golf Estates (JGE)',
    'Jumeirah Golf Estates (L.L.C)',
    'Jumeirah Group',
    'Jumeirah Heights (L.L.C)',
    'Jumeirah Island Co',
    'Jumeirah Islands Co. (L.L.C)',
    'Jumeirah Luxury',
    'Jumeirah Luxury Living',
    'Jumeirah Luxury Living.',
    'Jumeirah Properties Investments Ltd',
    'Jumeirah Village',
    'Jumeirah Village LLC',
    'Jumeirha Luxury Living',
    'Jumeriah Golf Estates ',
    'Jumeriah Living ',
    'Jumeriah Luxury Living ',
    'JUMIERAH GOLF ESTATE',
    'jumma al majid',
    'Jupiter Estates Limited',
    'JVC',
    'K M Properties L.L.C',
    'KARIM PROPERTIES',
    'Kempinski',
    'KG',
    'KG International FZCO',
    'KG Interntaional FZCO',
    'KG Tower',
    'khalid Al ATTAR',
    'Khalid Alattar',
    'Khalifa Alabbar Real Estate Development L.L.C',
    'Khamas',
    'Khan Saheb',
    'Khuyool Investments (L.L.C)',
    'KM Developers ',
    'KM Properties',
    'Koa Canvas',
    'L I V Real Estate Development LLC',
    'La Residence ',
    'La Rivera',
    'La Riveria',
    'La Riviera',
    'Lacasa ',
    'Laguna',
    'Laguna Tower Residence Fze',
    'Lake Central ',
    'Lake Point',
    'Lake Point JLT',
    'Lake Shore',
    'Lake Shore Appt',
    'Lake Shore JLT',
    'LAMBORGHINI',
    'LAS CASAS',
    'Lavista',
    'Laya Residences',
    'le reve',
    'LEVA',
    'LIMBORGHINI',
    'Lime Light Development',
    'Limelight Developments',
    'Limitless Llc',
    'Links Properties Investment Ltd',
    'Links Properties Investments Ltd',
    'Liquid Assets Limited',
    'Liv',
    'LIV Developers',
    'Liv Residence ',
    'Living Legends',
    'liwa ',
    'Liwan',
    'local',
    'Lokhandwala Builders',
    'Lokhandwala Builders International Limited',
    'Lokhawala Builders',
    'Lootah',
    'LOOTAH  DEVELOPERS',
    'Lootah Developer ',
    'Lootah Development',
    'Lootah Group of companies',
    'Lootah RE Development ',
    'Lootah Real Estate Development L.L.C.',
    'Lootah Real Estate Development Llc',
    'Lootah Real Estate Developments',
    'Lootha',
    'lootha Developer',
    'lootha development',
    'Lost City Development Llc',
    'LS',
    'Lucky Aeon Real Estate Development',
    'Lux Real Estate',
    'M E Development',
    'Mada',
    'MADA\'IN',
    'Mada\'In Properties Co (Mada\'In) (P.J.S.C)',
    'madain',
    'Mada’in Properties',
    'Maddinson Holding',
    'Madina',
    'Madison Holding Fzco',
    'Madison Holdings',
    'MAF',
    'MAG',
    'Mag 214',
    'MAG 218',
    'Mag 5',
    'MAG 5 property developments llc',
    'MAG Developer',
    'Mag Development LLC',
    'Mag Developments',
    'Mag eye',
    'MAG Group',
    'Mag Group Fze',
    'MAG Groups',
    'MAG Lifestyle Development',
    'MAG PD',
    'Mag Property',
    'Mag Property Development',
    'MAG214',
    'MAG218',
    'MAGDY',
    'majed alfuttaim',
    'Majid Al Futtaim',
    'Makaseb Properties',
    'Makeen ',
    'Makeen Properties',
    'Manazel',
    'Manazil',
    'Manchester',
    'Manchester Properties',
    'Manchester Real Estate',
    'Manchester Tower',
    'Mandate International Limited',
    'Maple Emaar',
    'Marina ',
    'marina arcade ',
    'Marina Arcade Real Estate LLC',
    'Marina Breeze Limited',
    'MARINA CROWN',
    'marina diamond ',
    'Marina Exclusive Limited',
    'Marina Gate',
    'marina heights ',
    'MARINA INFINITY LTD',
    'Marina Mansion',
    'Marina Park',
    'Marina Pearl',
    'Marina Residence',
    'Marina Residence A',
    'Marina Sail',
    'Marina Star Limited',
    'Marina Terrace',
    'Marina View A',
    'Marina View Twr A',
    'Markaz',
    'Markaz Developer',
    'MARQUISE SQUARE RE DEVELOPER L.L.C',
    'Marquise Square Real Estate Developer',
    'marriott',
    'Mars Investments Ltd.',
    'Mars Properties',
    'Mars Properties Investment Limited',
    'Mars Properties Investment Ltd',
    'Mars Properties Investment LTD.',
    'Mars Properties Investments LTD.',
    'marsa dubai',
    'Marwa homes',
    'marwa homes 2',
    'Marzouk Rashed Abdulla Mohamed Al Rashdan',
    'Masaken Real Estate Development Llc',
    'Mashroom LLC',
    'masood giga',
    'Matix Estate Limited F.Z.',
    'MAXIMMUS',
    'Maximum Group',
    'maximus',
    'Maximus International-F.Z.C',
    'Maysan Real Estate Investment Co. LLC',
    'Mazaya',
    'Mazaya 18',
    'Mazaya 5',
    'Mazaya Business Avenue ',
    'mazaya Group ',
    'Mazaya Holding',
    'MAZAYA HOLDING CO',
    'Mazaya Holding Co.',
    'Mazaya Holdings',
    'Mazaya Holdings Co.',
    'Mazaya Holdings L.L.C.',
    'Mazaya real estate',
    'Mazya',
    'MEERAS',
    'Meeras Development',
    'Meileinstein Developments',
    'MEILENSTEIN',
    'Meilenstein Developments',
    'Meilenstein Real Estate Development',
    'Memon ',
    'Memon Developer',
    'Memon Developments (Fzc)',
    'Memon Group ',
    'Memon Investments',
    'MEMON INVESTMENTS(L.L.C)',
    'Mera Home Limited',
    'Meraaas',
    'Meraas',
    'Meraas development',
    'Meraas Estate LLC',
    'Meraas Estates',
    'Meraas Estates L.L.C',
    'Meraas Holding',
    'meraki',
    'Meraki Developer',
    'Meraki Developers',
    'MERAKI DEVELOPERS LLC',
    'Meraki Group ',
    'MERASS',
    'mercure',
    'merls',
    'Merwess Abdulaziz',
    'Meydan',
    'Meydan and Sobha Developers',
    'Meydan City Corporation',
    'Meydan Group',
    'Meydan Group llc',
    'meydan sabha',
    'Meydan Shoba ',
    'Meydan Sobha',
    'Meydan Sobha LLC',
    'Meydan-Sobha',
    'MH',
    'Middle East Development',
    'Middle East Development LLC',
    'Middle East Real Estate',
    'Milano Giovanni',
    'Milenstein',
    'Millennium ',
    'Millennium Plaza Hotel',
    'Millenstain',
    'Miraas',
    'Mirage',
    'Mirjana Resources Ltd',
    'Mismak',
    'Mismak Properties',
    'Mismak Properties (FAB)',
    'Mismak Properties (First Gulf Bank)',
    'Mismak Properties Co (L.L.C)',
    'Mismak Properties Co LLC',
    'mismak properties [FAB]',
    'Mismak Property',
    'Mismak Prperty',
    'mo',
    'Modern Real Estate',
    'Mohammad Aqil Ali & Ahmad Ali Alzarooni',
    'Mohammad Rahif Hakmi',
    'Mohammed Rahif',
    'Mohd. Hussain & Bros',
    'Moonjiz Properties Limited',
    'Motor City',
    'move n pick',
    'Movenpick',
    'MR',
    'Mrg Ltd',
    'Ms. SH. Suroor BM Al Nahyan BLDG.MGT',
    'Msaab Mohammed Alzwaid & Hamad Ali Aldubikhi & Saleh Ibrahim Alqasir',
    'mu',
    'Muhammad Nabeel Joz',
    'MURABA',
    'Mustafa bin Abdul Latif Group',
    'myra',
    'Myra Developer',
    'Myra Developers',
    'Myra Development ',
    'Myra Properties',
    'Myra Properties Development',
    'Myra Real Estate Development',
    'Myra Real Estate Development LLC',
    'N M S REAL ESTATE DEVELOPMENT',
    'NAGA',
    'Nahkeel',
    'NAKEEL',
    'Nakeel Developers',
    'Nakheel',
    'Nakheel .(P J S C)',
    'Nakheel developer',
    'Nakheel Developers',
    'Nakheel Developments',
    'Nakheel PJSC',
    'Nakheel PJSC.',
    'Nakheel Properties',
    'NAKHEL',
    'Nakhil',
    'NAKHILL',
    'Naresco Group',
    'NASHAM',
    'nashama',
    'nashma',
    'NASHMI ',
    'Nasser Bin Abdullatif Alserkal Est.',
    'Nasser Lootah',
    'nassima',
    'National Bomds ',
    'national bonds',
    'National Bonds Corporation',
    'National Properties',
    'Ncc Urban Infrastructure Company Limited',
    'Neo Solutions Limited',
    'Neptune Properties Investment Limited',
    'New City ',
    'New City Developers',
    'NEW HOMES DEVELOPMENT',
    'New world Develepments LLC',
    'New World Development',
    'New World Developments LLC',
    'New World Investments Limited',
    'Nkaheel',
    'NMS Real Estate Developement',
    'North Dubailand Projects',
    'North Dubailand Projects L.L.C',
    'NORTH DUBAILAND PROJECTS LLC',
    'Noura Tower',
    'NSHAMA',
    'Nshama Developer',
    'Nshama Developers',
    'NSHAMA Development',
    'NSHAMA DEVELOPMENT LLC',
    'Nshama llc',
    'NSHAMA Properties',
    'Nshama Town Square ',
    'nshama townsquare',
    'NSHIMI',
    'NSHMA',
    'Nshmi ',
    'NSHMI DEVELOPMENT ',
    'NSHMI DEVELOPMENT L.L.C.',
    'Nshmi Development LLC',
    'NSHMI DEVELOPMENT LTD.',
    'Nshmi Developments LLC',
    'Nvestments House Co ( L.L.C )',
    'O2  ',
    'Oakgrove Global Limited',
    'Oasis Group Ventures Ltd',
    'Oberoi',
    'Oberoi Group',
    'Ocean pacific ',
    'OCTA Properties',
    'OMINIYAT ',
    'Ominyat',
    'Omniyat',
    'Omniyat Properties ',
    'Omniyat Properties Development Corporation',
    'Omnyat',
    'One Broker Group',
    'one earth ',
    'ORA INTL',
    'Orascom',
    'Oriental Pearls',
    'Oriental Pearls Real Estate Development LLC',
    'Oriental Perals',
    'Orion Real Estate Development',
    'Orion Real Estate Development LLC ',
    'ORO24 Real Estate Developments',
    'ORRA',
    'Orra International',
    'Orra Intl',
    'Oryx',
    'OST CONSTRACTION ',
    'Owner',
    'oxford',
    'Oxford residence',
    'Pacific ',
    'Pal Developments',
    'palm',
    'Palm Jumeirah',
    'Palma ',
    'Palma Developer',
    'Palma Holding',
    'Palma Holdings',
    'Pan Global',
    'Pan Global Development LLC',
    'PAn golabl',
    'Pana',
    'Pantheon ',
    'Pantheon Developer',
    'Pantheon Development ',
    'Pantheon Developments',
    'Pantheon Properties',
    'Pantheon Properties Ltd',
    'Pantheon Real Estate Development',
    'Panther Real Estate Development',
    'Paradise',
    'Paramount Real Estate Llc',
    'Park Hotel Apartments',
    'Park Island',
    'park lane',
    'Park Place',
    'Park Place Tower',
    'Park Villas',
    'Parshwa Holdings Limited',
    'PDLM RESIDENTAIL LLC',
    'Peak Properties Limited',
    'Pearl Dubai Fz Llc',
    'Pearl Properties',
    'Pinnacle Twr',
    'Plama',
    'Planetex Holdings Co.Limited',
    'Platinum Development ',
    'Platinum Residence',
    'Plazzo',
    'Plazzo Real Estate',
    'Plazzo Real Estate Development',
    'Plus International Two Limited',
    'Plus Intetnational One Limited',
    'Pnd Investments Ltd',
    'Point Development Limited',
    'polo',
    'Posh Holdings Limited',
    'Prescott',
    'Prescott Developers',
    'Prescott Enterprises Ltd',
    'Prescott Holdings Limited',
    'Prescott Investments Limited',
    'Prescott Real Estate Development',
    'Presscote',
    'pri',
    'Prime Development',
    'Princess Tower',
    'Princess Twr',
    'Prism tower ',
    'privat ',
    'private',
    'PRIVATE BUILDER',
    'Private by Mr Zarouni',
    'private developer',
    'Private owner',
    'privet Developer ',
    'Profile Residence Limited',
    'Properties Investment ',
    'Properties Investment LLC',
    'Property Developer',
    'Provident',
    'Provident Estate',
    'pullman',
    'pulse',
    'Pyamod Homes Consultancy Llc',
    'Pyamod International (Fze)',
    'Qureshi Faisal Abdul Aziz',
    'R.K.M Real Estate (Llc)',
    'Radiant Star',
    'Radisson ',
    'Rafi M Saeed Group',
    'Rafi M. Saeed Group',
    'Rak properties',
    'Rakeen',
    'Rakez ',
    'ramada ',
    'Ramadan Mousa Mishmish',
    'Rams Properties Investment Limited',
    'Ramy ',
    'Range 3 Investment Limited',
    'Range 4 Development  ',
    'Range 4 Developments',
    'RANI DEVELOPER',
    'Rani International Development L L C',
    'Rashed Darwish Al Ketbi Real Estate Development',
    'real homes',
    'Reality Capital',
    'Realty Capital',
    'Realty Capital Middle East Fz-Llc',
    'Realty properties',
    'Recom investments ',
    'Reef',
    'Reef Real Estate Investment Co ( L.L.C. )',
    'Reef Real Estate Investment Co LLC',
    'Reef Real Estate Investment Co.',
    'REEF REAL ESTATE INVESTMENT COMPANY',
    'Reef Real Estate Investment LLC',
    'Reef Real Estate Investments LLC',
    'Reef Real investment ',
    'reef residences',
    'Reem Developments',
    'regal ',
    'Regina',
    'Remah Holding Limited',
    'Remraam L.L.C',
    'Reportage',
    'Reportage Properties',
    'revera ',
    'Revi Real Estate Development',
    'REVI REAL ESTATE DEVELOPMENT LLC',
    'Rgm Limited',
    'RHS Group ',
    'Rigel',
    'Ritaj ',
    'Riveria',
    'Riviera Group',
    'Riviera Holdings Limited',
    'Riviera Properties Development',
    'Rmg Limited',
    'Rockwell',
    'Rockwell Real Estate Development LLC',
    'Romeo & Juliet Tower Limited',
    'Romil Investments Limited',
    'Rose Home Investment',
    'Rose Home Investment LLC',
    'Rose Homes',
    'Rose Homes Inestment',
    'Rose Homes International',
    'Rose Homes Investment',
    'ROSE HOMES INVESTMENT (L.L.C.)',
    'Rose Homes Investment (L.Lc)',
    'Rose Homes Investment LLC',
    'Rosebay',
    'Rosebay Developer',
    'Rosebay Real Estate Development LLC',
    'Rosebay Real Estate Development LLC FZ',
    'Rosehomes investment ',
    'rotana',
    'roxana',
    'Royal Centurion Real Estate Development',
    'Royal Centurion Real Estate Development LLC',
    'Royal Pearls',
    'RSG',
    'Rufi Century Tower Limited',
    'Rufi Down Town Residency Limited',
    'Rufi Gardens Limited',
    'Rufi Golf Greens Ltd',
    'Rufi Park View Limited',
    'Rufi Royal Crest Limited',
    'Rufi Twin Towers Limited',
    'Rufi Water Front Residency Limited',
    'Rukan',
    'S.P. International 1 Limited',
    'sa',
    'saab real estate',
    'SABA',
    'saba developer',
    'Saba Group',
    'Saba Properties',
    'Saba Properties Jlt',
    'SABA property ',
    'Saba Real Estate',
    'Sadaf 2',
    'Safe Developers',
    'Safeer',
    'Saga enterprises',
    'saga international',
    'Sahara Livings Limited',
    'salam',
    'saleh bin lahej',
    'Saleh Bin Lahej Building',
    'Saleh Bin Lahej Real Estate',
    'saleh construction',
    'saleh developer',
    'Saleh Mohd.Bin Lahej',
    'Salem ahmed al moosa',
    'Salya ',
    'Salya Homes Limited',
    'Samaa Developer',
    'samana',
    'Samana Developer',
    'Samana Developers',
    'samana greens',
    'Samana Group ',
    'Sameer Mahmoud Al Ali',
    'Sanali Holding Fze',
    'Sandhurst Capital Limited',
    'Sandos',
    'Sariin S.R.O ( Representativf Office )',
    'Satnam Singh',
    'SBA',
    'scala',
    'Schon',
    'Se7en Tides',
    'Seasif Group Fzco',
    'Season',
    'SEASONS COMMUNITY',
    'Seasons Development Limited',
    'Seasons Developments',
    'SEGREX Limited ',
    'Segrex LTD',
    'Select',
    'Select Dubai',
    'SELECT GLOBAL DEVELOPMENT',
    'Select Global Development LLC',
    'Select Global Developments LLC',
    'Select Group',
    'Select Group Company',
    'Select Properties',
    'Select Property',
    'Select-group ',
    'Selected Group',
    'Seracom Holding Limited',
    'Sereina',
    'sevanam holdings ltd',
    'SEVEN TIBES',
    'Seven Tides',
    'Seven Tides Dubai',
    'Seven Tides International',
    'Seven Tides Limited',
    'Seven Tides RE',
    'shaf',
    'Shaikh Holdings',
    'Shaikh Holdings Limited',
    'Shaikhani Group',
    'Shams',
    'Shams Eldien Eissawy Naja & Tarek Mahmoud Fils Halim & Tarek Ossama Abdou',
    'shangrila',
    'Shapoorji Pallonji',
    'Sharjah Oasis',
    'Shaukhani group ',
    'Sheffield Real Estat.L .L .C',
    'Sheffield Real Estate',
    'sheikhani group',
    'Sheth Estate (International) Limited',
    'Sheth Estate International Ltd',
    'Shoba',
    'shobha ',
    'Shobha Developers',
    'shobha hartland',
    'Shoumous Properties',
    'SIADAH Int. Real Estate Development LLC',
    'Sidra Limited',
    'Sienna Lakes Ltd',
    'signature ',
    'Signature Developers',
    'Signature Living',
    'Silcon',
    'SILICON',
    'Silicon Gate1',
    'silver ',
    'Silver Sky Properties L.L.C',
    'Silver Star Tower Limited',
    'SIT',
    'SKAI',
    'Skie',
    'SKY',
    'sky courts',
    'Sky Estate Limited',
    'Sky Overseas Property Developement LLC',
    'SKY OVERSEAS REAL ESTATE DEVELOPMENT L.L.C',
    'Sky Overseas Real Estate Development LLC',
    'Skycourt',
    'Skyview Tower',
    'SLS Dubai',
    'SLS Dubai Residences',
    'smana developers',
    'Smart Home Properties',
    'Smart Investment Limited',
    'SOAMS',
    'sobah',
    'Sobha ',
    'Sobha Developers',
    'Sobha Group',
    'Sobha Hartland',
    'Sobha Investments Limited',
    'Sobha llc',
    'SOBHA MEYDAN',
    'Sobha Properties Limited',
    'Sobha Realty',
    'Sobha Ventures Limited',
    'SOL',
    'SOL BAY',
    'SOL Properties',
    'solid ',
    'solid properties',
    'somewhere',
    'Sondos',
    'Sooma Developer',
    'Sooma Properties',
    'Souq Residences Fzco',
    'southridge ',
    'sparkle',
    'Sport City ',
    'Sports City ',
    'Sports City Authority',
    'Springs ',
    'Springs Villa',
    'SRG',
    'SS',
    'Star Giga',
    'Star Group Global',
    'Star Group Global.',
    'Star Surveying & Evaluating Services',
    'stargiga',
    'Stars Development Company – Ajmal Makan',
    'STUDIO ONE',
    'Stylus International Ltd',
    'Suhail Bahwan Group',
    'Sulafa',
    'Sulafa Twr',
    'Sun and Sand Developer',
    'Sun Valley Holdings Limited',
    'Sungwon (Fze)',
    'Sunn and Sand Developers',
    'Sunny beach ',
    'Sunny Beach Properties Ltd O.F',
    'Sunrise Transcon Real Estate Development LLC',
    'Supreme Capital Limited',
    'suweidi',
    'Swiss',
    'SWISS PROPERTIES',
    'Swiss Property',
    'Swiss Tower',
    'Syann Palm Ltd',
    'Syann Park 1 Limited',
    'Syed Ali Anwer Mehfooz',
    'Syndicate Developer Limited',
    'Syresh Chordia Paras Chordia',
    'T F G Real Estate L.L.C',
    'Taameer',
    'Tabeer Starwood',
    'Tabiya',
    'Tabiyan',
    'Tabyan',
    'Taisir Limited',
    'TAJ',
    'Tajir',
    'TAMANI ART',
    'Tamani Arts',
    'Tamcon',
    'Tamdeen Properties (L.L.C) Dubai Br',
    'TAMEEM',
    'tameer',
    'Tameer Developer',
    'Tameer Developments',
    'Tameer Holdig',
    'Tameer Holding',
    'Tameer Holding Development LTD',
    'Tameer Holding Investment',
    'Tameer Holding Investment (L.L.C)',
    'Tameer Holding Investment LLC',
    'Tameer Holding Investment;\tGemstone Developme',
    'Tameer Holdings',
    'Tameer Real Estate',
    'Tamer Holding ',
    'tammini',
    'tamniyat',
    'Tamouh',
    'Tamweel',
    'Tanminyat',
    'Tanmiyat',
    'Tanmiyat Global',
    'Tanmiyat Global Real Estate  ',
    'Tanmiyat Real Estate Development',
    'Tanmyat',
    'Tasameem',
    'Taskeen Properties',
    'TAV',
    'Tawakina development',
    'Tawakla Real Estate Development LLC',
    'Taziz',
    'tebyan',
    'Tebyan And  Devmark',
    'Tebyan Developer',
    'Tebyan Real Estate Development',
    'TECOM',
    'TECOM Group',
    'TECOM Group, Dubai Holding Group',
    'TECOM Investments ',
    'TEK HOLDING CORP.',
    'temcon',
    'tencom',
    'Tervini Biulders and Promoters',
    'Tessalit Limited',
    'Texture',
    'Texture Holding Ltd',
    'Texture Holding Ltd.',
    'Texture Holdings',
    'TFG',
    'Tharwaa Escan Investments ',
    'The 8',
    'The Address Marina',
    'The Belvedere',
    'the binary',
    'The Developer Properties',
    'The Developer Properties L.L.C',
    'The Developer Properties LLC ',
    'The Developers LLC',
    'The Estate',
    'THE FIELDS',
    'the first group',
    'The Heart of Europe Real Estate Development',
    'The Lagoon Phase One LLC',
    'The MAG',
    'The Onyx For Development Limited',
    'The Palladium',
    'The Palm - Deira (L.L.C)',
    'The Palm - Jabel Ali Co. (L.L.C)',
    'The Palm - Jumeirah Co. (L.L.C)',
    'The Palm Jumeirah',
    'The Palm Jumeirah Co. LLC',
    'The Palm Vacation Club Fze',
    'The Pavillion Holding Limite',
    'The Point',
    'The Rotana',
    'The Select Group ',
    'The Tiffany Group',
    'The World ( L.L.C )',
    'Thuriah',
    'Tiffany',
    'Tiger',
    'Tiger Building',
    'Tiger Developments',
    'TIGER group',
    'Tiger Properties',
    'Tiger Property',
    'TILAL AL GHAF ',
    'Tima Holdings Limited',
    'Time Group',
    'TIME PLACE',
    'Time Properties',
    'Time Property ',
    'TIMES',
    'Times Properties ',
    'Titan',
    'Titans Development',
    'Tonino Lamborghini Residences Dubai',
    'Torch Select Limited',
    'Torch Select LTD',
    'Torch Tower',
    'Town Center Management Limited',
    'Town Square',
    'TP',
    'Trans Trading Globle Corp',
    'Trend Capital Gmbh & Co. Dubai Sports City Kg',
    'Trevini Builders & Promoters',
    'Tri Panet',
    'Tri planet ',
    'TRI PLANET INTERNATIONALFZC',
    'Trident',
    'Trident Development',
    'trident grand ',
    'Trident International ',
    'Trident International Holding',
    'Trident International Holding (Fzco)',
    'trident International Holdings ',
    'Trident International Holdings (FZCO)',
    'trigrant',
    'TriPlanet',
    'Triplanet Developers',
    'Triplanet Development',
    'Triplanet International',
    'Triplanet International FZC',
    'Triplanet International Limited',
    'Triplanet International Ltd',
    'TRIPLANET RANGE 2 INVESTMENTS LIMITED',
    'Triplanet Range Group Elite Group',
    'Triplanet Real Estate',
    'Triplannet',
    'TRIPLANT',
    'Tristar',
    'Triveni Builders & Promoters Limited',
    'Triveni Builders and Promoters Ltd. (TBPL)',
    'Tulip',
    'Tulip Developers',
    'two seasons',
    'Ubora',
    'Uni Estate',
    'uniestate ',
    'Uniestate Property',
    'UNION',
    'Union Proeprties',
    'Union Properties',
    'Union Properties P.J.S.C',
    'Union Properties PJSC',
    'Union Property ',
    'union prperties',
    'unique properties',
    'United Developments (Fze)',
    'United Holdings ( L.L.C )',
    'United Properties Limited',
    'Universal Developers (Fzc)',
    'UP',
    'Urbana Emaar South',
    'UTMOST PROPERTIES',
    'UTMOST-Properties',
    'V Resorts Ltd',
    'Vakson',
    'vakson real estate',
    'valencia park',
    'Vascon',
    'Vascon Trading Ltd',
    'Vascon Trading Ltd.',
    'Venus Development',
    'VENUS INTERNATIONAL',
    'Venus International Holding Ltd',
    'Venus Properties Investment Ltd',
    'Verve',
    'Verve Group ',
    'vezul',
    'Vezul Investment LLC',
    'vicc',
    'Viceroy',
    'Victory Heights',
    'Victory Heights Real Estate Development L.L.C',
    'villa',
    'Villa Pera ',
    'vincentore palacio',
    'Vincetore',
    'Vincitore',
    'Vincitore Boulevard',
    'Vincitore LLC',
    'vincitore palacio',
    'Vincitore Real Estate Development LLC',
    'Vincitore Real Estate LLC',
    'Vincitore Realty ',
    'Virtue',
    'VIRTUE LAND REAL ESTATE DEVELOPMENT L.L.C',
    'Virtue Properties',
    'Vision Avenue Homes (Fzc) ',
    'wasl',
    'Wasl Properties',
    'Watford Limited',
    'Wealthcare Investments Ltd',
    'Wenworth And Trio Holdings Limited',
    'West Side',
    'West Wharf',
    'westar',
    'Westar properties',
    'Westar Properties Limited',
    'Westburry',
    'Westburry Square',
    'Wind One Inc Limited',
    'World Trade Centre',
    'Yacht bay',
    'Yansoon 4',
    'Yasmin ',
    'Zaabeel Investment',
    'zabeel',
    'ZABEEL INVESTMENT',
    'Zabeel Investments (L.L.C)',
    'Zabeel Properties',
    'Zahya Living',
    'Zanzebeel',
    'Zareen Developments Limited',
    'Zarouni Group of Companies',
    'Zarwah Development',
    'Zarwah Developments',
    'Zaya',
    'zaya hameni',
    'Zenith',
    'Zenith Holding',
    'Zenith Holdings',
    'Zenith Smart Real Estate Development',
    'Zero Five Zero Limited',
    'Zumurud',
    'اعمار',
    '‎Armada Group',
]

export const getStageColor = stage => {
    let returnColor = ''

    switch (stage) {
        case 'Untouched':
            returnColor = '#FF3E4D'
            break
        case 'Contacted':
            returnColor = '#ff8c00'
            break
        case 'Proposal Presented':
            returnColor = '#00CCCD'
            break
        case 'Overdue Followup':
            returnColor = '#8B78E6'
            break
        default:
            returnColor = '#44d936'
    }

    return returnColor
}
export const getStatusColor = stage => {
    let returnColor = ''

    switch (stage) {
        case 'Open':
            returnColor = '#56c2cc'
            break
        case 'Won':
            returnColor = '#5fb85c'
            break
        case 'Lost':
            returnColor = '#d44a0f'
            break
        case 'Unqualified':
            returnColor = '#e6b115'

            break
    }

    return returnColor
}
export const getInviteStatusColor = stage => {
    let returnColor = ''

    switch (stage) {

        case 'Accepted':
            returnColor = '#5fb85c'
            break
        case 'Rejected':
            returnColor = '#d44a0f'
            break
        case 'Pending':
            returnColor = '#e6b115'

            break
    }

    return returnColor
}
export const getPlatformColor = platform => {
    let returnColor = ''

    switch (platform) {

        case 'inbound':
            returnColor = '#ff8c00'
            break
        case 'landing':
            returnColor = '#26ae60'
            break
        case 'facebook':
            returnColor = '#00CCCD'
            break
        case 'gmail':
            returnColor = '#44d936'
            break
        default:
            returnColor = '#44d936'
    }

    return returnColor
}
export const getRatingColor = platform => {
    let returnColor = ''

    switch (platform) {

        case 'Warm':
            returnColor = '#ff8c00'
            break
        case 'Hot':
            returnColor = '#d44a0f'
            break
        case 'Cold':
            returnColor = '#00CCCD'
            break
    }

    return returnColor
}

export const shareRatio = ['50:50', '90:10', '60:40', '80:40']
export const leadStages = [
    {value: 'Contacted', label: 'Contacted'},
    {value: 'Proposal Presented', label: 'Proposal Presented'},
    {value: 'Appointment Scheduled', label: 'Appointment Scheduled'},
    {value: 'Not Answered', label: 'Not Answered'},
    {vale: 'Not Yet Updated', label: 'Not Yet Updated'},
    {value: 'Qualified Lead', label: 'Qualified Lead'},
    {value: 'Client Needs Time', label: 'Client Needs Time'},
    {value: 'Budget Differs', label: 'Budget Differs'},
    {value: 'Requested Call Back', label: 'Requested Call Back'},
    {value: 'Incorrect Number', label: 'Incorrect Number'},
    {value: 'Interested for Viewing', label: 'Interested for Viewing'},
    {value: 'Arranging Meeting', label: 'Arranging Meeting'},
    {value: 'Viewing Done', label: 'Viewing Done'},
    {value: 'Spam Lead', label: 'Spam Lead'},
    {value: 'Called No Answer', label: 'Called No Answer'},
    {value: 'Agent', label: 'Agent'},
    {value: 'Registered by Mistake', label: 'Registered by Mistake'},
    {value: 'Wrong Number', label: 'Wrong Number'},
    {value: 'Never Registered', label: 'Never Registered'},
    {value: 'Contact Made', label: 'Contact Made'},
    {value: 'Phone Switched Off', label: 'Phone Switched Off'}
]



class Request {

    constructor() {
        // asyncStorage
        //   .getToken()
        //   .then((data) => {
        //     localStorage.getItem('token') = data
        //   })
    }

    error = err => {
        try {
            if (err.response.status === 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.clear()
                window.location.reload()
            }
        } catch (e) {
        }
    }

    deleteHeads(data) {
        return new Promise((next, error) => {
            authAxios
                .delete(
                    '/heads',
                    {data: {_id: data}},
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        },
                    },
                )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    error(err)
                    this.error(err)
                })
        })
    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/login', data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addWebsite(data) {
        return new Promise(next => {
            authAxios
                .post(
                    '/website',
                    {data},
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        },
                    },
                )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    editWebsite(data) {
        return new Promise(next => {
            authAxios
                .put(
                    '/website',
                    {data},
                    {
                        headers: {
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        },
                    },
                )
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getWebsites(data) {
        return new Promise(next => {
            authAxios
                .post('/websites', {...data})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getWebsite(slug) {
        return new Promise(next => {
            authAxios
                .get(`/website/${slug}`)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getTags(slug) {
        return new Promise(next => {
            authAxios
                .get(`/tags`)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    editWebsiteScreens({slug, data}) {
        return new Promise(next => {
            authAxios
                .put(`/website/${slug}`, data)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    toggleWebsiteEnabled({urlSlug}) {
        return new Promise(next => {
            authAxios
                .get(`/website/${urlSlug}/toggle`)
                .then(d => {
                    next()
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    toogleWebsiteOnline({urlSlug}) {
        return new Promise(next => {
            authAxios
                .get(`/website/${urlSlug}/isOnline`)
                .then(d => {
                    next()
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    retryScreenshot({urlSlug}) {
        return new Promise(next => {
            authAxios
                .get(`/website/${urlSlug}/retryScreenshot`)
                .then(d => {
                    next()
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteWebsite({urlSlug}) {
        return new Promise(next => {
            authAxios
                .delete(`/website/${urlSlug}`)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    undoDeleteWebsite({urlSlug}) {
        return new Promise(next => {
            authAxios
                .patch(`/website/${urlSlug}`)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllWebsites(data) {
        return new Promise(next => {
            authAxios
                .get('/websites', {params: {...data}})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    falseScreenshotsGenerated({_id}) {
        return new Promise(next => {
            authAxios
                .get(`/website/screenShotGenerated/${_id}`)
                .then(d => {
                    next()
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getTechnology() {
        return new Promise(next => {
            authAxios
                .get(`/technology`)
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    ////////////heree bnw

    getAllAddAccounts(data) {
        return new Promise(next => {
            authAxios
                .get('/addAccounts', {...data})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }
}

export const handlePreview = async (file) => {
    let url
    if (file && file.response && file.response.files[0].path) {
        file.response.path = file.response.files[0].path.replace(/^public/, '')
        url = `${API_URL}/${file.response.path}`
    } else {
        url = `${API_URL}/${file.url}`
    }

    window.open(url, '_blank')
}

export const renderPrice = val => {
    if (val) {
        const {priceType, max, min} = val
        if (priceType == 'Fixed') {
            return (
                <CF
                    value={max}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={` ${currencyFormat}`}
                    renderText={value => (
                        <div className={styles.priceField}>Fixed: {value}</div>
                    )}
                />
            )
        } else {
            return (
                <div>
                    <CF
                        value={min}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={` ${currencyFormat}`}
                        renderText={value => (
                            <div className={styles.minPriceField}>Min: {value}</div>
                        )}
                    />

                    <CF
                        value={max}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={` ${currencyFormat}`}
                        renderText={value => (
                            <div className={styles.priceField}>Max: {value}</div>
                        )}
                    />
                </div>
            )
        }
    } else {
        return ''
    }
}
export const renderPriceForDetails = val => {
    if (val) {
        const {priceType, max, min} = val
        if (priceType == 'Fixed') {
            return (
                <span>{max} ${currencyFormat}</span>
            )
        } else {
            return (
                <span>
          {min} {currencyFormat} - ${max} {currencyFormat}
        </span>
            )
        }
    } else {
        return ''
    }
}

export const getTimeOptions = (date) => {
    let afterDate = date || moment()
    let timeOptionsArr = []
    let finalArray
    if (afterDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
        async.forEach(followUpTime, (doc, cb) => {
            let compareTime = moment(doc, ['h:mma'])
            if (moment().add('hour', 1).isBefore(compareTime)) {
                timeOptionsArr.push(doc)
            }
            cb()
        }, () => {
            finalArray = timeOptionsArr
        })
    } else {
        finalArray = followUpTime
    }
    return finalArray
}

export default new Request()
