import React, { Component, Suspense } from 'react'
import { Route, Link, Redirect, withRouter } from 'react-router-dom'
import memoizeOne from 'memoize-one'
import moment from 'moment'
import socketio from 'socket.io-client'
import IdleTimer from 'react-idle-timer'

import menuData, { getUrlPath } from '../../routes'
import Exception from '../../components/Exception'
import AuthLayout from '../../layouts/AuthLayout'
import BasicLayout from '../../layouts/BasicLayout'

import Emitter from '../../emitter'
import { API_URL as apiUrl, API_URL } from '../../request'

import Login from '../../containers/login'
import ProposalDetails from '../../containers/proposalDetails'

import '../../index.css'
import _ from 'lodash'
// import CreateProposal from '../project/views/createProposal'
// import HiringQuestionnaire from '../hiringQuestionnaire/views'
// import HiringQuestionnaireEdit from '../hiringQuestionnaire/views/edit'
// import ThankYou from '../hiringQuestionnaire/views/thank'

const CreateProposal = React.lazy(() => import( '../project/views/createProposal'))
const ProposalLayout = React.lazy(() => import( '../../layouts/ProposalLayout'))

const Exp = () => (
  <Exception
    type="404"
    desc={'You Seems lost !!'}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
)
const Exp403 = () => (
  <Exception
    type="403"
    desc={'Sorry You Don\'t have access to this area !!'}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
)

class BasicLayoutWrapper extends Component {
  render () {
    const {menuData, component, path, user, isLazy} = this.props

    if (!user) {
      return <Redirect to="/login"/>
    }

    let menuItem = _(menuData)
      .thru(function (coll) {
        return _.union(coll, _.map(coll, 'children'))
      })
      .flatten()
      .find({path: path})

    if (menuItem &&
      menuItem.authority !== undefined &&
      menuItem.authority.indexOf(user.userType) === -1
    ) {
      console.log('this user should not be here ', path)
      return <Exp403/>
    }

    if (menuItem && !menuItem.title) {
      menuItem.title = 'Untitled'
    }

    if (menuItem.justShowMenu) {
      return <div></div>
    }

    return (
      <BasicLayout
        className={'testing'}
        location={window.location}
        pageTitle={`${menuItem && menuItem.title} - BnW Dubai CRM`}
        menuData={menuData}>
        {!isLazy ? (!!component ? <this.props.component/> : <Exp/>) : (!!component ? <Suspense
          fallback={<div>Loading</div>}>
          <this.props.component/>
        </Suspense> : <Exp/>)}

      </BasicLayout>
    )

  }
}

class App extends Component {
  constructor (props) {

    super(props)
    this.state = {
      token: localStorage.getItem('token'),
      pageDisplay: {
        display: 'block',
      },
      user:
        localStorage.getItem('user') != 'undefined'
        ? JSON.parse(localStorage.getItem('user'))
        : null,
    }

    this.idleTimer = null

    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)

    this.socket = socketio.connect(apiUrl)
    this.socket.on('logout_user_force', () => {
      console.log(' logout_user_force logout_user_force logout_user_force logout_user_force ')
      this.logout()
    })

    this.socket.on('connect', async () => {
      console.log('conneciton succssful')
      this.reAuthenticate(this.socket)
    })

    this.socket.on('reloadUsers', () => {
      Emitter.emit('reloadUsers')
    })

  }

  componentDidMount () {
    this.reAuthenticate(this.socket)
    Emitter.on('reAuth', () => {
      this.reAuthenticate(this.socket)
    })
  }

  componentWillUnmount () {
    Emitter.off('reAuth', () => {

    })
  }

  reAuthenticate = async (socket) => {
    let userToken = localStorage.getItem('token')
    if (userToken) {
      console.log('reAuthenticate with token at ' + new Date())
      socket.emit('authentication', {userToken})
    }
  }

  logout = async () => {
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    if (user) {
      console.log(user)
      user.isLoggedIn = false
      user.logoutTime = moment()
      // let x = await Request.updateUserDetails(user)
      // await Request.addLoginHistory({
      //   logoutTime: user.logoutTime,
      //   type: 'Logout',
      //   email: user && user.email,
      //   name: user && user.name,
      //   userType: user && user.userType,
      //   mobile: x && x.data && x.data.mobile,
      // })

      localStorage.removeItem('token')
      localStorage.removeItem('user')
      window.location.reload()
    }
  }

  handleOnAction (event) {
    // console.log('user did something ')
    this.socket.emit('userIsActive')
  }

  handleOnActive (event) {
    this.socket.emit('userIsActive')
  }

  handleOnIdle (event) {
    this.socket.emit('userIsIdle')
  }

  render () {

    const {user} = this.state
    const {location} = this.props

    const displayStyle = {
      display: location.pathname == '/proposal/create' ? 'block' : 'none',
    }

    return (<>

      {localStorage.getItem('token') && <IdleTimer
        ref={ref => {
          this.idleTimer = ref
        }}
        timeout={30 * 60 * 1000}
        eventsThrottle={30 * 1000}
        onActive={this.handleOnActive}
        onIdle={this.handleOnIdle}
        onAction={this.handleOnAction}
        debounce={250}
      />}


      {user && <div style={displayStyle}>
        <BasicLayout
          className={'testing'}
          location={window.location}
          pageTitle={`BnW Dubai CRM`}
          menuData={menuData}>
          {/*<CreateProposal/>*/}

          <Suspense fallback={<div>Loading</div>}>
            <CreateProposal/>
          </Suspense>

        </BasicLayout>
      </div>}

      <div>
        {menuData.map((item, key) => {
          if (!item.children) {
            return (
              <Route
                exact
                path={item.path}
                key={item.path}
                render={route => {
                  return (
                    <BasicLayoutWrapper
                      component={item.component}
                      isLazy={item.isLazy}
                      path={item.path}
                      user={user}
                      menuData={menuData}
                    />
                  )
                }}
              />
            )
          }
        })}

        {menuData.map((item, key) => {
          if (item.children) {
            return item.children.map((child, k) => {
              return (
                <Route
                  exact
                  path={child.path}
                  key={child.path}
                  render={route => {
                    return (
                      <BasicLayoutWrapper
                        component={child.component}
                        path={child.path}
                        isLazy={child.isLazy}
                        user={user}
                        menuData={menuData}
                      />
                    )
                  }}
                />
              )
            })
          }
        })}

        <Route
          exact
          path="/login"
          render={route => {
            return (
              <AuthLayout location={window.location} menuData={menuData}>
                <Login/>
              </AuthLayout>
            )
          }}
        />

        <Route
          exact
          path="/proposalDetails"
          render={route => {
            return (
              <Suspense fallback={<div>Loading</div>}>
                <ProposalLayout location={window.location} menuData={menuData}>

                </ProposalLayout>
              </Suspense>
            )
          }}
        />

        <Route
          exact
          path="/"
          render={route => {
            if (user && user.userType === 'finance') {
              return (<Redirect to="/dashboard/finance"/>)

            } else if (user && user.userType === 'adminManager') {
              return <Redirect to="/attendance/add"/>
            } else if (user && user.userType !== 'admin') {
              return <Redirect to="/leads/viewUserPage"/>
            } else {
              return <Redirect to="/dashboard"/>
            }
          }}
        />

        {/*<Route
          exact
          path="/hiring"
          render={route => {
            return (
              <HiringQuestionnaire/>
            )
          }}
        />
        <Route
          exact
          path="/hiringEdit"
          render={route => {
            return (
              <HiringQuestionnaireEdit/>
            )
          }}
        />
        <Route
          exact
          path="/thank"
          render={route => {
            return (
              <ThankYou/>
            )
          }}
        />*/}


      </div>
    </>)
  }
}

export default withRouter(App)
