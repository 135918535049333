import { API_URL } from '../../../request'

export const addUserUrl = () => {
  return API_URL + `/addUser`
}

export const listUsersUrl = () => {
  return API_URL + `/allUser`
}

export const updateUserUrl = () => {
  return API_URL + `/updateUser`
}
export const getUserUrl = () => {
  return API_URL + `/getUser`
}
export const downloadMailerUrl = (id) => {
  return API_URL + `/generate/footer/${id}`
}

export const downloadVCardUrl = (id) => {
  return API_URL + `/team-profile-print/${id}`
}
export const checkinListUrl = () => {
  return API_URL + `/user/checkIn/list`
}

