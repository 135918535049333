import {  addAppointmentUrl,listAppointmentUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'


export const addAppointmentAction = async params => {
  try {
    let { data } = await axios.post(
      addAppointmentUrl(),
      { ...params },
      getToken()
    )

    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const appointmentList = async params => {
  try {
    let { data } = await axios.get(
      listAppointmentUrl(), { params: { ...params }, ...getToken() })
    if (data.error) {
      notification.error({
        message: data.message
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
