import {
  userNotificationToken,
  dateWiseFollowupCountApi,
  platformCountApi,
  userDashboardCountApi,
  managerTargetDataUrl,
  managerAgentsTargetDataUrl,
  managerAgentsTargetTotalDataUrl,
  managerAgentsLeadsDataUrl,
  managerWiseTargetClosingDataUrl,
} from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import Request, { getToken } from '../../../request'

const {detect} = require('detect-browser')

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(' this is a test')
  if (401 === error.response.status) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.clear()
    window.location.reload()
  } else {
    return false
  }
})

export const registerUserToken = async params => {
  try {
    console.log('registerUserToken use registration token')
    let browser = detect()
    let {data} = await axios.post(
      userNotificationToken(),
      {...params, browser},
      getToken(),
    )
    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    Request.error(err)
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const dateWiseFollowupCount = async params => {
  try {
    let {data} = await axios.get(
      dateWiseFollowupCountApi(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const platformCount = async params => {
  try {
    let {data} = await axios.get(
      platformCountApi(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const userDashboardCount = async params => {
  try {
    let {data} = await axios.get(
      userDashboardCountApi(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const managerTargetData = async params => {
  try {
    let {data} = await axios.get(
      managerTargetDataUrl(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const managerAgentsTargetData = async params => {
  try {
    let {data} = await axios.get(
      managerAgentsTargetDataUrl(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const managerAgentsTargetTotalData = async params => {
  try {
    let {data} = await axios.get(
      managerAgentsTargetTotalDataUrl(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const managerAgentsLeadsData = async params => {
  try {
    let {data} = await axios.get(
      managerAgentsLeadsDataUrl(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const managerWiseTargetClosingData = async params => {
  try {
    let {data} = await axios.get(
      managerWiseTargetClosingDataUrl(), {params: {...params}, ...getToken()},
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
