import { addTargetUrl, userTargetCountUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'

export const addTargetAction = async params => {
  try {
    let { data } = await axios.post(
      addTargetUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const listTargetAction = async params => {
  try {
    let { data } = await axios.get(
      addTargetUrl(), { params: { ...params }, ...getToken() }
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const updateTargetAction = async params => {
  try {
    let { data } = await axios.put(
      addTargetUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const userTargetCount = async params => {
  try {
    let { data } = await axios.get(
      userTargetCountUrl(), { params: { ...params }, ...getToken() }
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
