import { addClosingUrl, closingStatusUrl, closingCountUrl } from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'

export const addClosingAction = async params => {
  try {
    let { data } = await axios.post(
      addClosingUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const listClosingAction = async params => {
  try {
    let { data } = await axios.get(
      addClosingUrl(), { params: { ...params }, ...getToken() }
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updateClosingStatusAction = async params => {
  try {
    let { data } = await axios.put(
      closingStatusUrl(),
      { ...params },
      getToken()
    )

    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}

export const userClosingCount = async params => {
  try {
    let { data } = await axios.get(
      closingCountUrl(), { params: { ...params }, ...getToken() }
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
