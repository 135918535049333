import React, { Component, PureComponent } from 'react'
import { Icon, Button, Drawer } from 'antd'
import { Link } from 'react-router-dom'
import { debounce } from 'lodash-decorators'
import styles from './index.less'
import RightContent from './RightContent'
import { MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined } from '@ant-design/icons'
import AddLead from '../../containers/leads/views/add'
import ProjectView from '../../containers/project/views/searchProject'
import { PlusOutlined } from '@ant-design/icons'

export default class GlobalHeader extends Component {

  state = {
    openDrawer: false
  }

  constructor(props) {
    super(props)

  }

  componentWillUnmount() {
    this.triggerResizeEvent.cancel()
  }

  @debounce(600)

  triggerResizeEvent() {
    // eslint-disable-line
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, false)
    window.dispatchEvent(event)
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props
    onCollapse(!collapsed)
    this.triggerResizeEvent()
  }

  render() {
    const { collapsed, isMobile, logo } = this.props
    const { openDrawer, openProjectDrawer } = this.state
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    return (
      <div className={styles.header}>
        {isMobile && (
          <Link to="/" className={styles.logo} key="logo">
            <img src={logo} alt="logo" width="32" />
          </Link>
        )}
        <span className={styles.trigger} onClick={this.toggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </span>
        <Button hidden={user && user.userType === 'finance'} size={'small'} type={'primary'} onClick={() => {
          this.setState({ openDrawer: true, drawerKey: Date.now() })
        }
        }>Quick Lead <PlusOutlined /></Button>
        <Button hidden={user && user.userType === 'finance'} size={'small'} style={{ marginLeft: '10px' }}
                type={'primary'} onClick={() => {
          this.setState({ openProjectDrawer: true, projectDrawerKey: Date.now() })
        }
        }>Projects<HomeOutlined /></Button>
        <RightContent {...this.props} />

        <Drawer placement="right"
                width={700}
                key={this.state.drawerKey}
                onClose={() => {
                  this.setState({ openDrawer: false })
                }
                }
                visible={openDrawer}
        >
          <div style={{ paddingTop: 50 }}>


            <AddLead closeDrawer={() => {
              this.setState({ openDrawer: false })

            }} />
          </div>
        </Drawer>
        <Drawer placement="right"
                width={700}
                title={<h2>Search Project</h2>}
                key={this.state.projectDrawerKey}
                onClose={() => {
                  this.setState({ openProjectDrawer: false })
                }
                }
                visible={openProjectDrawer}
        >
          <div>
            <ProjectView closeDrawer={() => {
              this.setState({ openDrawer: false })

            }} />
          </div>
        </Drawer>
      </div>
    )
  }

}
