import React, {useEffect, useState} from 'react'

import {
    EmailActivityModal,
    FollowUpActivityModal,
    AppointmentActivityModal,
    WhatsAppActivityModal, ProposalActivityModal
} from '../leads/views/helpers'
import {Form, Button, Card, List, Tabs, Timeline, Tag, Divider, Typography, Space, Tooltip} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {listProposals} from '../leads/actions'
import {UserOutlined, ClockCircleOutlined} from '@ant-design/icons'

const IconText = ({icon, text}) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
)
export const RenderComments = (props) => {
    let {data, showInActivities} = props

    let newData = _.filter(data, (x) => x.type == 'comment')
    if (showInActivities) {
        return <div>
            <Timeline mode={'left'}>
                {_.map(newData, (val, key) => {
                    return (
                        <Timeline.Item key={key}>
                            {val.message}
                        </Timeline.Item>
                    )
                })}
            </Timeline>
        </div>
    } else {
        data = _.orderBy(data, ['time'], ['desc'])
        return (<List id={'commentList'}
                      itemLayout="vertical"
                      dataSource={data}
                      renderItem={(item, key) => (
                          <List.Item style={{marginTop: 0}} actions={[
                              <IconText icon={UserOutlined} text={<small>{item && item.user}</small>}
                                        key={key}/>,
                              <IconText icon={ClockCircleOutlined}
                                        text={<Tooltip
                                            title={moment(item && item.time).format('lll')}><small>{item && item.time ? moment(item.time).fromNow() : ''}</small></Tooltip>}
                                        key={key}/>
                          ]}>
                              <small>{item.msg}</small>
                          </List.Item>
                      )}
        />)
    }


}

const {Text, Link} = Typography

const {TabPane} = Tabs

const getCallColor = (callType, duration) => {
    if (callType === 'outgoing' && duration == 0) {
        return {

            color: '#FBB917',
            fontWeight: 'bold'

        }
    } else if (callType === 'outgoing' && duration > 0) {
        return {
            color: '#43BE31',
            fontWeight: 'bold'

        }
    } else if (callType === 'incoming') {
        return {

            color: '#1287A5',
            fontWeight: 'bold'

        }
    } else {
        return {
            color: 'red',
            fontWeight: 'bold'

        }
    }
}


const Activities = props => {
    let user = {...JSON.parse(localStorage.getItem('user'))}
    const [loader, setLoader] = useState(false)
    const [activeKey, setActiveKey] = useState('0')
    let {activities, handleActivity, leadId} = props
    const [singleActivity, setEmailActivity] = useState('')
    const [singleFollowUpActivity, setFollowUpActivity] = useState('')
    const [singleAppointmentActivity, setAppointmentActivity] = useState('')
    const [emailModal, setEmailModal] = useState(false)
    const [followUpModal, setFollowUpModal] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState(false)
    const [singleWhatsappActivity, setWhatsAppActivity] = useState('')
    const [whatsAppModal, setWhatsAppModal] = useState(false)
    const [singleProposalActivity, setProposalActivity] = useState('')
    const [proposalModal, setProposalModal] = useState(false)
    const [projects, setProjects] = useState([])
    const [proposalId, setProposalId] = useState('')


    const setActivity = (activity, newKey, key) => {
        if (activity.type === 'callLog') {
            return <Timeline.Item key={newKey}>
                              <span>{activity && activity.callLogId
                                  ? <Tag
                                      style={getCallColor(activity.callLogId.callType,
                                          activity.callDuration)}>{_.startCase(activity.callType)}</Tag>
                                  : ''}</span> Call of
                duration <span>{moment.utc(activity.callDuration * 1000).format('HH:mm:ss')} </span> of
                <b>{activity.user}</b> on <b>
                {moment(activity.callingTime ? activity.callingTime : activity.createdAt).format('lll')}
            </b>

            </Timeline.Item>
        } else if (activity.type == 'email')
            return <Timeline.Item key={newKey}><a onClick={() => {
                setEmailModal(true)
                setEmailActivity(activity)
            }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
            </Timeline.Item>
        else if (activity.type == 'proposalCreated' || activity.type == 'phoneChanged')
            return <Timeline.Item key={newKey}><a onClick={() => {
            }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
            </Timeline.Item>
        else if (activity.type == 'followUp')
            return <Timeline.Item key={newKey}><a onClick={() => {
                setFollowUpModal(true)
                setFollowUpActivity(activity)
            }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
            </Timeline.Item>
        else if (activity.type == 'appointment')
            return <Timeline.Item key={newKey}><a onClick={() => {
                setAppointmentModal(true)
                setAppointmentActivity(activity)

            }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
            </Timeline.Item>
        else if (activity.type == 'value' || activity.type == 'status' || activity.type == 'stage' || activity.type == 'snooze' || activity.type == 'shared' || activity.type == 'leadConvert')
            return <Timeline.Item
                key={newKey}><a>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a></Timeline.Item>
        else if (activity.type == 'whatsApp')
            return <Timeline.Item key={newKey}><a onClick={() => {
                setWhatsAppModal(true)
                setWhatsAppActivity(activity)
            }}>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format('lll')}</b></a>
            </Timeline.Item>
        else if (activity.type == 'proposalView')
            return <Timeline.Item key={newKey}><a onClick={() => {
                setProposalActivity(activity)
                setProposalModal(true)
            }}>{activity.message}</a>
            </Timeline.Item>
        else if (activity.type == 'photoDownload' || activity.type == 'brochureDownload' || activity.type == 'planDownload')
            return <Timeline.Item key={newKey}><a onClick={() => {
                setProposalActivity(activity)
                setProposalModal(true)
            }}>{activity.message} for Project <b>{activity.projectName}</b></a>
            </Timeline.Item>
        else if (activity.type == 'comment')
            return <Timeline.Item key={newKey}><a onClick={() => {

            }}>
                {activity.message}
            </a>
            </Timeline.Item>
    }
    const cancelActivityModal = () => {
        setEmailModal(false)
        setFollowUpModal(false)
        setAppointmentModal(false)
        setWhatsAppModal(false)
        setProposalModal(false)
    }


    const fetchLeadProjects = async () => {
        if (leadId) {
            let {data} = await listProposals({leadId: leadId})
            if (!data) return
            if (data && data.length) {
                setProposalId(data[0]._id)
                setProjects(data && data[0].projects)
            }
        }

    }
    useEffect(() => {
        fetchLeadProjects()
    }, [leadId])


    const RenderActivities = (props) => {
        let {data} = props
        let newData = _.groupBy(data, (x) => moment(x.createdAt).format('YYYY-MM-DD'))


        let findAArr = []
        _.each(newData, (val, k) => {
            findAArr.push({date: k, activities: val})
        })


        return _.map(findAArr, (val, key) => {
            return <div>
                <h3><Tag color={'lightgreen'}>{val.date}</Tag></h3>
                <Timeline mode={'left'}>
                    {_.map(val.activities, (ii, k) => {
                        let newKey = `${ii._id}`
                        return setActivity(ii, newKey, k)
                    })}

                </Timeline>


            </div>
        })

    }
    // const RenderComments = (props) => {
    //     let {data} = props
    //     let newData = _.filter(data, (x) => x.type == 'comment')
    //
    //     return <div>
    //
    //         <Timeline mode={'left'}>
    //             {_.map(newData, (val, key) => {
    //                 return (
    //                     <Timeline.Item key={key}>
    //                         {val.message}
    //                     </Timeline.Item>
    //                 )
    //             })}
    //         </Timeline>
    //     </div>
    //
    //
    // }


    return (

        <React.Fragment>
            <Card style={{marginTop: '30px'}}>
                <Tabs
                    type="line"
                    activeKey={activeKey}
                    defaultActiveKey="0"
                    onTabClick={async (key) => {
                        setActiveKey(key)
                        if (handleActivity) {
                            handleActivity(key)
                        }
                    }}
                >
                    <TabPane tab={'All Activities'} key={0}>
                        <RenderActivities renderKey="0" data={activities}/>
                    </TabPane>
                    <TabPane tab={'Communication'} key={1}>
                        <RenderActivities renderKey="1" data={activities}/>
                    </TabPane>
                    <TabPane tab={'FollowUp'} key={2}>
                        <RenderActivities renderKey="2" data={activities}/>
                    </TabPane>
                    <TabPane tab={'Call'} key={3}>
                        <RenderActivities renderKey="3" data={activities}/>
                    </TabPane>
                    <TabPane tab={'Projects'} key={4}>


                        <Link
                            href={
                                window.location.origin + '/proposal/create?id=' + props.leadId
                            }
                            target="_blank">
                            {projects && projects.length ? 'Click to change proposal' : 'Click to create new proposal'}
                        </Link>
                        <Link
                            style={{float: 'right'}}
                            href={
                                window.location.origin + '/proposalDetails?id=' + proposalId
                            }
                            target="_blank">
                            {projects && projects.length ? 'Click to View Proposal' : ''}
                        </Link>
                        <Divider></Divider>
                        <Timeline>
                            {_.map(projects, (ii) => {
                                return <Timeline.Item>{ii && ii.projectName}</Timeline.Item>

                            })}

                        </Timeline>


                    </TabPane>
                    <TabPane tab={'Proposals'} key={5}>
                        <RenderActivities renderKey="5" data={activities}/>
                    </TabPane>
                    <TabPane tab={'Comments'} key={6}>

                        <RenderComments renderKey={'6'} data={activities} showInActivities={true}/>
                    </TabPane>


                    {user && user.userType == 'admin' && < TabPane tab={'Assign Logs'} key={7}>
                        <Timeline>
                            {_.map(props && props.assignLogs, (ii) => {
                                return <Timeline.Item>Lead assign
                                    to {ii.assigneeName} by {ii.assignByName} on {moment(ii.assignTime).format('lll')}</Timeline.Item>

                            })}

                        </Timeline>


                    </TabPane>}
                </Tabs>
            </Card>

            {emailModal && EmailActivityModal({cancelActivityModal, emailModal, singleActivity})}

            {followUpModal && FollowUpActivityModal(
                {cancelActivityModal, followUpModal, singleFollowUpActivity})}
            {appointmentModal && AppointmentActivityModal(
                {cancelActivityModal, appointmentModal, singleAppointmentActivity})}
            {whatsAppModal && WhatsAppActivityModal(
                {cancelActivityModal, whatsAppModal, singleWhatsappActivity})}
            {proposalModal && ProposalActivityModal(
                {cancelActivityModal, proposalModal, singleProposalActivity})}
        </React.Fragment>
    )
}

export default Activities
