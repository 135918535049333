export const country = [
    {
        "countryName": "Andorra",
        "frenchName": "Andorre",
        "isoCode": "AD"
    },
    {
        "countryName": "Republic of Kosovo",
        "frenchName": "Republic of Kosovo",
        "isoCode": "XK"
    },
    {
        "countryName": "Sint Maarten",
        "frenchName": "Saint Martin",
        "isoCode": "SX"
    },
    {
        "countryName": "Canary Islands",
        "frenchName": "Canaries, Iles",
        "isoCode": "IC"
    },
    {
        "countryName": "Bonaire",
        "frenchName": "Bonaire",
        "isoCode": "BQ"
    },
    {
        "countryName": "Curacao",
        "frenchName": "Curacao",
        "isoCode": "CW"
    },
    {
        "countryName": "Yugoslavia",
        "frenchName": "Yougoslavie",
        "isoCode": "YU"
    },
    {
        "countryName": "United Arab Emirates",
        "frenchName": "Émirats arabes unis",
        "isoCode": "AE"
    },
    {
        "countryName": "Afghanistan",
        "frenchName": "Afghanistan",
        "isoCode": "AF"
    },
    {
        "countryName": "Antigua and Barbuda",
        "frenchName": "Antigua-et-Barbuda",
        "isoCode": "AG"
    },
    {
        "countryName": "Anguilla",
        "frenchName": "Anguilla",
        "isoCode": "AI"
    },
    {
        "countryName": "Albania",
        "frenchName": "Albanie",
        "isoCode": "AL"
    },
    {
        "countryName": "Armenia",
        "frenchName": "Arménie",
        "isoCode": "AM"
    },
    {
        "countryName": "Netherlands Antilles",
        "frenchName": "Antilles néerlandaises",
        "isoCode": "AN"
    },
    {
        "countryName": "Angola",
        "frenchName": "Angola",
        "isoCode": "AO"
    },
    {
        "countryName": "Antarctica",
        "frenchName": "Antarctique",
        "isoCode": "AQ"
    },
    {
        "countryName": "Argentina",
        "frenchName": "Argentine",
        "isoCode": "AR"
    },
    {
        "countryName": "American Samoa",
        "frenchName": "Samoa américaines",
        "isoCode": "AS"
    },
    {
        "countryName": "Austria",
        "frenchName": "Autriche",
        "isoCode": "AT"
    },
    {
        "countryName": "Australia",
        "frenchName": "Australie",
        "isoCode": "AU"
    },
    {
        "countryName": "Aruba",
        "frenchName": "Aruba",
        "isoCode": "AW"
    },
    {
        "countryName": "Åland",
        "frenchName": "Îles Åland",
        "isoCode": "AX"
    },
    {
        "countryName": "Azerbaijan",
        "frenchName": "Azerbaïdjan",
        "isoCode": "AZ"
    },
    {
        "countryName": "Bosnia and Herzegovina",
        "frenchName": "Bosnie-Herzégovine",
        "isoCode": "BA"
    },
    {
        "countryName": "Barbados",
        "frenchName": "Barbade",
        "isoCode": "BB"
    },
    {
        "countryName": "Bangladesh",
        "frenchName": "Bangladesh",
        "isoCode": "BD"
    },
    {
        "countryName": "Belgium",
        "frenchName": "Belgique",
        "isoCode": "BE"
    },
    {
        "countryName": "Burkina Faso",
        "frenchName": "Burkina Faso",
        "isoCode": "BF"
    },
    {
        "countryName": "Bulgaria",
        "frenchName": "Bulgarie",
        "isoCode": "BG"
    },
    {
        "countryName": "Bahrain",
        "frenchName": "Bahreïn",
        "isoCode": "BH"
    },
    {
        "countryName": "Burundi",
        "frenchName": "Burundi",
        "isoCode": "BI"
    },
    {
        "countryName": "Benin",
        "frenchName": "Bénin",
        "isoCode": "BJ"
    },
    {
        "countryName": "Saint Barthélemy",
        "frenchName": "Saint-Barthélemy",
        "isoCode": "BL"
    },
    {
        "countryName": "Bermuda",
        "frenchName": "Bermudes",
        "isoCode": "BM"
    },
    {
        "countryName": "Brunei Darussalam",
        "frenchName": "Brunéi Darussalam",
        "isoCode": "BN"
    },
    {
        "countryName": "Bolivia",
        "frenchName": "Bolivie (État plurinational de)",
        "isoCode": "BO"
    },
    {
        "countryName": "Brazil",
        "frenchName": "Brésil",
        "isoCode": "BR"
    },
    {
        "countryName": "Bahamas",
        "frenchName": "Bahamas",
        "isoCode": "BS"
    },
    {
        "countryName": "Bhutan",
        "frenchName": "Bhoutan",
        "isoCode": "BT"
    },
    {
        "countryName": "Bouvet Island",
        "frenchName": "Île Bouvet",
        "isoCode": "BV"
    },
    {
        "countryName": "Botswana",
        "frenchName": "Botswana",
        "isoCode": "BW"
    },
    {
        "countryName": "Belarus",
        "frenchName": "Bélarus",
        "isoCode": "BY"
    },
    {
        "countryName": "Belize",
        "frenchName": "Belize",
        "isoCode": "BZ"
    },
    {
        "countryName": "Canada",
        "frenchName": "Canada",
        "isoCode": "CA"
    },
    {
        "countryName": "Cocos (Keeling) Islands",
        "frenchName": "Îles Cocos",
        "isoCode": "CC"
    },
    {
        "countryName": "Congo (Kinshasa)",
        "frenchName": "République démocratique du Congo",
        "isoCode": "CD"
    },
    {
        "countryName": "Central African Republic",
        "frenchName": "République centrafricaine",
        "isoCode": "CF"
    },
    {
        "countryName": "Congo (Brazzaville)",
        "frenchName": "Congo",
        "isoCode": "CG"
    },
    {
        "countryName": "Switzerland",
        "frenchName": "Suisse",
        "isoCode": "CH"
    },
    {
        "countryName": "Côte d'Ivoire",
        "frenchName": "Côte d'Ivoire",
        "isoCode": "CI"
    },
    {
        "countryName": "Cook Islands",
        "frenchName": "Îles Cook",
        "isoCode": "CK"
    },
    {
        "countryName": "Chile",
        "frenchName": "Chili",
        "isoCode": "CL"
    },
    {
        "countryName": "Cameroon",
        "frenchName": "Cameroun",
        "isoCode": "CM"
    },
    {
        "countryName": "China",
        "frenchName": "Chine",
        "isoCode": "CN"
    },
    {
        "countryName": "Colombia",
        "frenchName": "Colombie",
        "isoCode": "CO"
    },
    {
        "countryName": "Costa Rica",
        "frenchName": "Costa Rica",
        "isoCode": "CR"
    },
    {
        "countryName": "Cuba",
        "frenchName": "Cuba",
        "isoCode": "CU"
    },
    {
        "countryName": "Cape Verde",
        "frenchName": "Cabo Verde",
        "isoCode": "CV"
    },
    {
        "countryName": "Christmas Island",
        "frenchName": "Île Christmas",
        "isoCode": "CX"
    },
    {
        "countryName": "Cyprus",
        "frenchName": "Chypre",
        "isoCode": "CY"
    },
    {
        "countryName": "Czech Republic",
        "frenchName": "République tchèque",
        "isoCode": "CZ"
    },
    {
        "countryName": "Germany",
        "frenchName": "Allemagne",
        "isoCode": "DE"
    },
    {
        "countryName": "Djibouti",
        "frenchName": "Djibouti",
        "isoCode": "DJ"
    },
    {
        "countryName": "Denmark",
        "frenchName": "Danemark",
        "isoCode": "DK"
    },
    {
        "countryName": "Dominica",
        "frenchName": "Dominique",
        "isoCode": "DM"
    },
    {
        "countryName": "Dominican Republic",
        "frenchName": "République dominicaine",
        "isoCode": "DO"
    },
    {
        "countryName": "Algeria",
        "frenchName": "Algérie",
        "isoCode": "DZ"
    },
    {
        "countryName": "Ecuador",
        "frenchName": "Équateur",
        "isoCode": "EC"
    },
    {
        "countryName": "Estonia",
        "frenchName": "Estonie",
        "isoCode": "EE"
    },
    {
        "countryName": "Egypt",
        "frenchName": "Égypte",
        "isoCode": "EG"
    },
    {
        "countryName": "Western Sahara",
        "frenchName": "République arabe sahraouie démocratique",
        "isoCode": "EH"
    },
    {
        "countryName": "Eritrea",
        "frenchName": "Érythrée",
        "isoCode": "ER"
    },
    {
        "countryName": "Spain",
        "frenchName": "Espagne",
        "isoCode": "ES"
    },
    {
        "countryName": "Ethiopia",
        "frenchName": "Éthiopie",
        "isoCode": "ET"
    },
    {
        "countryName": "Finland",
        "frenchName": "Finlande",
        "isoCode": "FI"
    },
    {
        "countryName": "Fiji",
        "frenchName": "Fidji",
        "isoCode": "FJ"
    },
    {
        "countryName": "Falkland Islands",
        "frenchName": "Malouines",
        "isoCode": "FK"
    },
    {
        "countryName": "Micronesia",
        "frenchName": "Micronésie (États fédérés de)",
        "isoCode": "FM"
    },
    {
        "countryName": "Faroe Islands",
        "frenchName": "Îles Féroé (Membre associé)",
        "isoCode": "FO"
    },
    {
        "countryName": "France",
        "frenchName": "France",
        "isoCode": "FR"
    },
    {
        "countryName": "Gabon",
        "frenchName": "Gabon",
        "isoCode": "GA"
    },
    {
        "countryName": "United Kingdom",
        "frenchName": "Royaume-Uni",
        "isoCode": "GB"
    },
    {
        "countryName": "Grenada",
        "frenchName": "Grenade",
        "isoCode": "GD"
    },
    {
        "countryName": "Georgia",
        "frenchName": "Géorgie",
        "isoCode": "GE"
    },
    {
        "countryName": "French Guiana",
        "frenchName": "Guyane",
        "isoCode": "GF"
    },
    {
        "countryName": "Guernsey",
        "frenchName": "Guernesey",
        "isoCode": "GG"
    },
    {
        "countryName": "Ghana",
        "frenchName": "Ghana",
        "isoCode": "GH"
    },
    {
        "countryName": "Gibraltar",
        "frenchName": "Gibraltar",
        "isoCode": "GI"
    },
    {
        "countryName": "Greenland",
        "frenchName": "Groenland",
        "isoCode": "GL"
    },
    {
        "countryName": "Gambia",
        "frenchName": "Gambie",
        "isoCode": "GM"
    },
    {
        "countryName": "Guinea",
        "frenchName": "Guinée",
        "isoCode": "GN"
    },
    {
        "countryName": "Guadeloupe",
        "frenchName": "Guadeloupe",
        "isoCode": "GP"
    },
    {
        "countryName": "Equatorial Guinea",
        "frenchName": "Guinée équatoriale",
        "isoCode": "GQ"
    },
    {
        "countryName": "Greece",
        "frenchName": "Grèce",
        "isoCode": "GR"
    },
    {
        "countryName": "South Georgia and South Sandwich Islands",
        "frenchName": "Géorgie du Sud-et-les Îles Sandwich du Sud",
        "isoCode": "GS"
    },
    {
        "countryName": "Guatemala",
        "frenchName": "Guatemala",
        "isoCode": "GT"
    },
    {
        "countryName": "Guam",
        "frenchName": "Guam",
        "isoCode": "GU"
    },
    {
        "countryName": "Guinea-Bissau",
        "frenchName": "Guinée-Bissau",
        "isoCode": "GW"
    },
    {
        "countryName": "Guyana",
        "frenchName": "Guyana",
        "isoCode": "GY"
    },
    {
        "countryName": "Hong Kong",
        "frenchName": "Hong Kong",
        "isoCode": "HK"
    },
    {
        "countryName": "Heard and McDonald Islands",
        "frenchName": "Îles Heard-et-MacDonald",
        "isoCode": "HM"
    },
    {
        "countryName": "Honduras",
        "frenchName": "Honduras",
        "isoCode": "HN"
    },
    {
        "countryName": "Croatia",
        "frenchName": "Croatie",
        "isoCode": "HR"
    },
    {
        "countryName": "Haiti",
        "frenchName": "Haïti",
        "isoCode": "HT"
    },
    {
        "countryName": "Hungary",
        "frenchName": "Hongrie",
        "isoCode": "HU"
    },
    {
        "countryName": "Indonesia",
        "frenchName": "Indonésie",
        "isoCode": "ID"
    },
    {
        "countryName": "Ireland",
        "frenchName": "Irlande",
        "isoCode": "IE"
    },
    {
        "countryName": "Israel",
        "frenchName": "Israël",
        "isoCode": "IL"
    },
    {
        "countryName": "Isle of Man",
        "frenchName": "Île de Man",
        "isoCode": "IM"
    },
    {
        "countryName": "India",
        "frenchName": "Inde",
        "isoCode": "IN"
    },
    {
        "countryName": "British Indian Ocean Territory",
        "frenchName": "Territoire britannique de l'océan Indien",
        "isoCode": "IO"
    },
    {
        "countryName": "Iraq",
        "frenchName": "Iraq",
        "isoCode": "IQ"
    },
    {
        "countryName": "Iran",
        "frenchName": "Iran",
        "isoCode": "IR"
    },
    {
        "countryName": "Iceland",
        "frenchName": "Islande",
        "isoCode": "IS"
    },
    {
        "countryName": "Italy",
        "frenchName": "Italie",
        "isoCode": "IT"
    },
    {
        "countryName": "Jersey",
        "frenchName": "Jersey",
        "isoCode": "JE"
    },
    {
        "countryName": "Jamaica",
        "frenchName": "Jamaïque",
        "isoCode": "JM"
    },
    {
        "countryName": "Jordan",
        "frenchName": "Jordanie",
        "isoCode": "JO"
    },
    {
        "countryName": "Japan",
        "frenchName": "Japon",
        "isoCode": "JP"
    },
    {
        "countryName": "Kenya",
        "frenchName": "Kenya",
        "isoCode": "KE"
    },
    {
        "countryName": "Kyrgyzstan",
        "frenchName": "Kirghizistan",
        "isoCode": "KG"
    },
    {
        "countryName": "Cambodia",
        "frenchName": "Cambodge",
        "isoCode": "KH"
    },
    {
        "countryName": "Kiribati",
        "frenchName": "Kiribati",
        "isoCode": "KI"
    },
    {
        "countryName": "Comoros",
        "frenchName": "Comores",
        "isoCode": "KM"
    },
    {
        "countryName": "Saint Kitts and Nevis",
        "frenchName": "Saint-Kitts-et-Nevis",
        "isoCode": "KN"
    },
    {
        "countryName": "Korea, North",
        "frenchName": "République populaire démocratique de Corée",
        "isoCode": "KP"
    },
    {
        "countryName": "Korea, South",
        "frenchName": "République de Corée",
        "isoCode": "KR"
    },
    {
        "countryName": "Kuwait",
        "frenchName": "Koweït",
        "isoCode": "KW"
    },
    {
        "countryName": "Cayman Islands",
        "frenchName": "Îles Caïmans",
        "isoCode": "KY"
    },
    {
        "countryName": "Kazakhstan",
        "frenchName": "Kazakhstan",
        "isoCode": "KZ"
    },
    {
        "countryName": "Laos",
        "frenchName": "République démocratique populaire lao",
        "isoCode": "LA"
    },
    {
        "countryName": "Lebanon",
        "frenchName": "Liban",
        "isoCode": "LB"
    },
    {
        "countryName": "Saint Lucia",
        "frenchName": "Sainte-Lucie",
        "isoCode": "LC"
    },
    {
        "countryName": "Liechtenstein",
        "frenchName": "Liechtenstein",
        "isoCode": "LI"
    },
    {
        "countryName": "Sri Lanka",
        "frenchName": "Sri Lanka",
        "isoCode": "LK"
    },
    {
        "countryName": "Liberia",
        "frenchName": "Libéria",
        "isoCode": "LR"
    },
    {
        "countryName": "Lesotho",
        "frenchName": "Lesotho",
        "isoCode": "LS"
    },
    {
        "countryName": "Lithuania",
        "frenchName": "Lituanie",
        "isoCode": "LT"
    },
    {
        "countryName": "Luxembourg",
        "frenchName": "Luxembourg",
        "isoCode": "LU"
    },
    {
        "countryName": "Latvia",
        "frenchName": "Lettonie",
        "isoCode": "LV"
    },
    {
        "countryName": "Libya",
        "frenchName": "Libye",
        "isoCode": "LY"
    },
    {
        "countryName": "Morocco",
        "frenchName": "Maroc",
        "isoCode": "MA"
    },
    {
        "countryName": "Monaco",
        "frenchName": "Monaco",
        "isoCode": "MC"
    },
    {
        "countryName": "Moldova",
        "frenchName": "République de Moldova",
        "isoCode": "MD"
    },
    {
        "countryName": "Montenegro",
        "frenchName": "Monténégro",
        "isoCode": "ME"
    },
    {
        "countryName": "Saint Martin (French part)",
        "frenchName": "Saint-Martin",
        "isoCode": "MF"
    },
    {
        "countryName": "Madagascar",
        "frenchName": "Madagascar",
        "isoCode": "MG"
    },
    {
        "countryName": "Marshall Islands",
        "frenchName": "Îles Marshall",
        "isoCode": "MH"
    },
    {
        "countryName": "Macedonia",
        "frenchName": "ex-République yougoslave de Macédoine",
        "isoCode": "MK"
    },
    {
        "countryName": "Mali",
        "frenchName": "Mali",
        "isoCode": "ML"
    },
    {
        "countryName": "Myanmar",
        "frenchName": "Myanmar",
        "isoCode": "MM"
    },
    {
        "countryName": "Mongolia",
        "frenchName": "Mongolie",
        "isoCode": "MN"
    },
    {
        "countryName": "Macau",
        "frenchName": "Macao",
        "isoCode": "MO"
    },
    {
        "countryName": "Northern Mariana Islands",
        "frenchName": "Îles Mariannes du Nord",
        "isoCode": "MP"
    },
    {
        "countryName": "Martinique",
        "frenchName": "Martinique",
        "isoCode": "MQ"
    },
    {
        "countryName": "Mauritania",
        "frenchName": "Mauritanie",
        "isoCode": "MR"
    },
    {
        "countryName": "Montserrat",
        "frenchName": "Montserrat",
        "isoCode": "MS"
    },
    {
        "countryName": "Malta",
        "frenchName": "Malte",
        "isoCode": "MT"
    },
    {
        "countryName": "Mauritius",
        "frenchName": "Maurice",
        "isoCode": "MU"
    },
    {
        "countryName": "Maldives",
        "frenchName": "Maldives",
        "isoCode": "MV"
    },
    {
        "countryName": "Malawi",
        "frenchName": "Malawi",
        "isoCode": "MW"
    },
    {
        "countryName": "Mexico",
        "frenchName": "Mexique",
        "isoCode": "MX"
    },
    {
        "countryName": "Malaysia",
        "frenchName": "Malaisie",
        "isoCode": "MY"
    },
    {
        "countryName": "Mozambique",
        "frenchName": "Mozambique",
        "isoCode": "MZ"
    },
    {
        "countryName": "Namibia",
        "frenchName": "Namibie",
        "isoCode": "NA"
    },
    {
        "countryName": "New Caledonia",
        "frenchName": "Nouvelle-Calédonie",
        "isoCode": "NC"
    },
    {
        "countryName": "Niger",
        "frenchName": "Niger",
        "isoCode": "NE"
    },
    {
        "countryName": "Norfolk Island",
        "frenchName": "Île Norfolk",
        "isoCode": "NF"
    },
    {
        "countryName": "Nigeria",
        "frenchName": "Nigéria",
        "isoCode": "NG"
    },
    {
        "countryName": "Nicaragua",
        "frenchName": "Nicaragua",
        "isoCode": "NI"
    },
    {
        "countryName": "Netherlands",
        "frenchName": "Pays-Bas",
        "isoCode": "NL"
    },
    {
        "countryName": "Norway",
        "frenchName": "Norvège",
        "isoCode": "NO"
    },
    {
        "countryName": "Nepal",
        "frenchName": "Népal",
        "isoCode": "NP"
    },
    {
        "countryName": "Nauru",
        "frenchName": "Nauru",
        "isoCode": "NR"
    },
    {
        "countryName": "Niue",
        "frenchName": "Nioué",
        "isoCode": "NU"
    },
    {
        "countryName": "New Zealand",
        "frenchName": "Nouvelle-Zélande",
        "isoCode": "NZ"
    },
    {
        "countryName": "Oman",
        "frenchName": "Oman",
        "isoCode": "OM"
    },
    {
        "countryName": "Panama",
        "frenchName": "Panama",
        "isoCode": "PA"
    },
    {
        "countryName": "Peru",
        "frenchName": "Pérou",
        "isoCode": "PE"
    },
    {
        "countryName": "French Polynesia",
        "frenchName": "Polynésie française",
        "isoCode": "PF"
    },
    {
        "countryName": "Papua New Guinea",
        "frenchName": "Papouasie-Nouvelle-Guinée",
        "isoCode": "PG"
    },
    {
        "countryName": "Philippines",
        "frenchName": "Philippines",
        "isoCode": "PH"
    },
    {
        "countryName": "Pakistan",
        "frenchName": "Pakistan",
        "isoCode": "PK"
    },
    {
        "countryName": "Poland",
        "frenchName": "Pologne",
        "isoCode": "PL"
    },
    {
        "countryName": "Saint Pierre and Miquelon",
        "frenchName": "Saint-Pierre-et-Miquelon",
        "isoCode": "PM"
    },
    {
        "countryName": "Pitcairn",
        "frenchName": "Îles Pitcairn",
        "isoCode": "PN"
    },
    {
        "countryName": "Puerto Rico",
        "frenchName": "Porto Rico",
        "isoCode": "PR"
    },
    {
        "countryName": "Palestine",
        "frenchName": "Palestine",
        "isoCode": "PS"
    },
    {
        "countryName": "Portugal",
        "frenchName": "Portugal",
        "isoCode": "PT"
    },
    {
        "countryName": "Palau",
        "frenchName": "Palaos",
        "isoCode": "PW"
    },
    {
        "countryName": "Paraguay",
        "frenchName": "Paraguay",
        "isoCode": "PY"
    },
    {
        "countryName": "Qatar",
        "frenchName": "Qatar",
        "isoCode": "QA"
    },
    {
        "countryName": "Reunion",
        "frenchName": "La Réunion",
        "isoCode": "RE"
    },
    {
        "countryName": "Romania",
        "frenchName": "Roumanie",
        "isoCode": "RO"
    },
    {
        "countryName": "Serbia",
        "frenchName": "Serbie",
        "isoCode": "RS"
    },
    {
        "countryName": "Russia",
        "frenchName": "Fédération de Russie",
        "isoCode": "RU"
    },
    {
        "countryName": "Rwanda",
        "frenchName": "Rwanda",
        "isoCode": "RW"
    },
    {
        "countryName": "Saudi Arabia",
        "frenchName": "Arabie saoudite",
        "isoCode": "SA"
    },
    {
        "countryName": "Solomon Islands",
        "frenchName": "Îles Salomon",
        "isoCode": "SB"
    },
    {
        "countryName": "Seychelles",
        "frenchName": "Seychelles",
        "isoCode": "SC"
    },
    {
        "countryName": "Sudan",
        "frenchName": "Soudan",
        "isoCode": "SD"
    },
    {
        "countryName": "Sweden",
        "frenchName": "Suède",
        "isoCode": "SE"
    },
    {
        "countryName": "Singapore",
        "frenchName": "Singapour",
        "isoCode": "SG"
    },
    {
        "countryName": "Saint Helena",
        "frenchName": "Sainte-Hélène, Ascension et Tristan da Cunha",
        "isoCode": "SH"
    },
    {
        "countryName": "Slovenia",
        "frenchName": "Slovénie",
        "isoCode": "SI"
    },
    {
        "countryName": "Svalbard and Jan Mayen Islands",
        "frenchName": "Svalbard et ile Jan Mayen",
        "isoCode": "SJ"
    },
    {
        "countryName": "Slovakia",
        "frenchName": "Slovaquie",
        "isoCode": "SK"
    },
    {
        "countryName": "Sierra Leone",
        "frenchName": "Sierra Leone",
        "isoCode": "SL"
    },
    {
        "countryName": "San Marino",
        "frenchName": "Saint-Marin",
        "isoCode": "SM"
    },
    {
        "countryName": "Senegal",
        "frenchName": "Sénégal",
        "isoCode": "SN"
    },
    {
        "countryName": "Somalia",
        "frenchName": "Somalie",
        "isoCode": "SO"
    },
    {
        "countryName": "Suriname",
        "frenchName": "Suriname",
        "isoCode": "SR"
    },
    {
        "countryName": "Sao Tome and Principe",
        "frenchName": "Sao Tomé-et-Principe",
        "isoCode": "ST"
    },
    {
        "countryName": "El Salvador",
        "frenchName": "El Salvador",
        "isoCode": "SV"
    },
    {
        "countryName": "Syria",
        "frenchName": "République arabe syrienne",
        "isoCode": "SY"
    },
    {
        "countryName": "Swaziland",
        "frenchName": "Swaziland",
        "isoCode": "SZ"
    },
    {
        "countryName": "Turks and Caicos Islands",
        "frenchName": "Îles Turques-et-Caïques",
        "isoCode": "TC"
    },
    {
        "countryName": "Chad",
        "frenchName": "Tchad",
        "isoCode": "TD"
    },
    {
        "countryName": "French Southern Lands",
        "frenchName": "Terres australes et antarctiques françaises",
        "isoCode": "TF"
    },
    {
        "countryName": "Togo",
        "frenchName": "Togo",
        "isoCode": "TG"
    },
    {
        "countryName": "Thailand",
        "frenchName": "Thaïlande",
        "isoCode": "TH"
    },
    {
        "countryName": "Tajikistan",
        "frenchName": "Tadjikistan",
        "isoCode": "TJ"
    },
    {
        "countryName": "Tokelau",
        "frenchName": "Tokélaou (Membre associé)",
        "isoCode": "TK"
    },
    {
        "countryName": "Timor-Leste",
        "frenchName": "Timor-Leste",
        "isoCode": "TL"
    },
    {
        "countryName": "Turkmenistan",
        "frenchName": "Turkménistan",
        "isoCode": "TM"
    },
    {
        "countryName": "Tunisia",
        "frenchName": "Tunisie",
        "isoCode": "TN"
    },
    {
        "countryName": "Tonga",
        "frenchName": "Tonga",
        "isoCode": "TO"
    },
    {
        "countryName": "Turkey",
        "frenchName": "Turquie",
        "isoCode": "TR"
    },
    {
        "countryName": "Trinidad and Tobago",
        "frenchName": "Trinité-et-Tobago",
        "isoCode": "TT"
    },
    {
        "countryName": "Tuvalu",
        "frenchName": "Tuvalu",
        "isoCode": "TV"
    },
    {
        "countryName": "Taiwan",
        "frenchName": "Taïwan / (République de Chine (Taïwan))",
        "isoCode": "TW"
    },
    {
        "countryName": "Tanzania",
        "frenchName": "République-Unie de Tanzanie",
        "isoCode": "TZ"
    },
    {
        "countryName": "Ukraine",
        "frenchName": "Ukraine",
        "isoCode": "UA"
    },
    {
        "countryName": "Uganda",
        "frenchName": "Ouganda",
        "isoCode": "UG"
    },
    {
        "countryName": "United States Minor Outlying Islands",
        "frenchName": "Îles mineures éloignées des États-Unis",
        "isoCode": "UM"
    },
    {
        "countryName": "United States of America",
        "frenchName": "États-Unis d'Amérique",
        "isoCode": "US"
    },
    {
        "countryName": "Uruguay",
        "frenchName": "Uruguay",
        "isoCode": "UY"
    },
    {
        "countryName": "Uzbekistan",
        "frenchName": "Ouzbékistan",
        "isoCode": "UZ"
    },
    {
        "countryName": "Vatican City",
        "frenchName": "Saint-Siège",
        "isoCode": "VA"
    },
    {
        "countryName": "Saint Vincent and the Grenadines",
        "frenchName": "Saint-Vincent-et-les Grenadines",
        "isoCode": "VC"
    },
    {
        "countryName": "Venezuela",
        "frenchName": "Venezuela (République bolivarienne du)",
        "isoCode": "VE"
    },
    {
        "countryName": "Virgin Islands, British",
        "frenchName": "Îles Vierges britanniques",
        "isoCode": "VG"
    },
    {
        "countryName": "Virgin Islands, U.S.",
        "frenchName": "Îles Vierges des États-Unis",
        "isoCode": "VI"
    },
    {
        "countryName": "Vietnam",
        "frenchName": "Viet Nam",
        "isoCode": "VN"
    },
    {
        "countryName": "Vanuatu",
        "frenchName": "Vanuatu",
        "isoCode": "VU"
    },
    {
        "countryName": "Wallis and Futuna Islands",
        "frenchName": "Wallis-et-Futuna",
        "isoCode": "WF"
    },
    {
        "countryName": "Samoa",
        "frenchName": "Samoa",
        "isoCode": "WS"
    },
    {
        "countryName": "Yemen",
        "frenchName": "Yémen",
        "isoCode": "YE"
    },
    {
        "countryName": "Mayotte",
        "frenchName": "Mayotte",
        "isoCode": "YT"
    },
    {
        "countryName": "South Africa",
        "frenchName": "Afrique du Sud",
        "isoCode": "ZA"
    },
    {
        "countryName": "Zambia",
        "frenchName": "Zambie",
        "isoCode": "ZM"
    },
    {
        "countryName": "Zimbabwe",
        "frenchName": "Zimbabwe",
        "isoCode": "ZW"
    }
]
export const phoneCodeList=[
    {
        "name": "Afghanistan",
        "phoneCode": "+93",
        "code": "AF"
    },
    {
        "name": "Aland Islands",
        "phoneCode": "+358",
        "code": "AX"
    },
    {
        "name": "Albania",
        "phoneCode": "+355",
        "code": "AL"
    },
    {
        "name": "Algeria",
        "phoneCode": "+213",
        "code": "DZ"
    },
    {
        "name": "AmericanSamoa",
        "phoneCode": "+1684",
        "code": "AS"
    },
    {
        "name": "Andorra",
        "phoneCode": "+376",
        "code": "AD"
    },
    {
        "name": "Angola",
        "phoneCode": "+244",
        "code": "AO"
    },
    {
        "name": "Anguilla",
        "phoneCode": "+1264",
        "code": "AI"
    },
    {
        "name": "Antarctica",
        "phoneCode": "+672",
        "code": "AQ"
    },
    {
        "name": "Antigua and Barbuda",
        "phoneCode": "+1268",
        "code": "AG"
    },
    {
        "name": "Argentina",
        "phoneCode": "+54",
        "code": "AR"
    },
    {
        "name": "Armenia",
        "phoneCode": "+374",
        "code": "AM"
    },
    {
        "name": "Aruba",
        "phoneCode": "+297",
        "code": "AW"
    },
    {
        "name": "Australia",
        "phoneCode": "+61",
        "code": "AU"
    },
    {
        "name": "Austria",
        "phoneCode": "+43",
        "code": "AT"
    },
    {
        "name": "Azerbaijan",
        "phoneCode": "+994",
        "code": "AZ"
    },
    {
        "name": "Bahamas",
        "phoneCode": "+1242",
        "code": "BS"
    },
    {
        "name": "Bahrain",
        "phoneCode": "+973",
        "code": "BH"
    },
    {
        "name": "Bangladesh",
        "phoneCode": "+880",
        "code": "BD"
    },
    {
        "name": "Barbados",
        "phoneCode": "+1246",
        "code": "BB"
    },
    {
        "name": "Belarus",
        "phoneCode": "+375",
        "code": "BY"
    },
    {
        "name": "Belgium",
        "phoneCode": "+32",
        "code": "BE"
    },
    {
        "name": "Belize",
        "phoneCode": "+501",
        "code": "BZ"
    },
    {
        "name": "Benin",
        "phoneCode": "+229",
        "code": "BJ"
    },
    {
        "name": "Bermuda",
        "phoneCode": "+1441",
        "code": "BM"
    },
    {
        "name": "Bhutan",
        "phoneCode": "+975",
        "code": "BT"
    },
    {
        "name": "Bolivia, Plurinational State of",
        "phoneCode": "+591",
        "code": "BO"
    },
    {
        "name": "Bosnia and Herzegovina",
        "phoneCode": "+387",
        "code": "BA"
    },
    {
        "name": "Botswana",
        "phoneCode": "+267",
        "code": "BW"
    },
    {
        "name": "Brazil",
        "phoneCode": "+55",
        "code": "BR"
    },
    {
        "name": "British Indian Ocean Territory",
        "phoneCode": "+246",
        "code": "IO"
    },
    {
        "name": "Brunei Darussalam",
        "phoneCode": "+673",
        "code": "BN"
    },
    {
        "name": "Bulgaria",
        "phoneCode": "+359",
        "code": "BG"
    },
    {
        "name": "Burkina Faso",
        "phoneCode": "+226",
        "code": "BF"
    },
    {
        "name": "Burundi",
        "phoneCode": "+257",
        "code": "BI"
    },
    {
        "name": "Cambodia",
        "phoneCode": "+855",
        "code": "KH"
    },
    {
        "name": "Cameroon",
        "phoneCode": "+237",
        "code": "CM"
    },
    {
        "name": "Canada",
        "phoneCode": "+1",
        "code": "CA"
    },
    {
        "name": "Cape Verde",
        "phoneCode": "+238",
        "code": "CV"
    },
    {
        "name": "Cayman Islands",
        "phoneCode": "+ 345",
        "code": "KY"
    },
    {
        "name": "Central African Republic",
        "phoneCode": "+236",
        "code": "CF"
    },
    {
        "name": "Chad",
        "phoneCode": "+235",
        "code": "TD"
    },
    {
        "name": "Chile",
        "phoneCode": "+56",
        "code": "CL"
    },
    {
        "name": "China",
        "phoneCode": "+86",
        "code": "CN"
    },
    {
        "name": "Christmas Island",
        "phoneCode": "+61",
        "code": "CX"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "phoneCode": "+61",
        "code": "CC"
    },
    {
        "name": "Colombia",
        "phoneCode": "+57",
        "code": "CO"
    },
    {
        "name": "Comoros",
        "phoneCode": "+269",
        "code": "KM"
    },
    {
        "name": "Congo",
        "phoneCode": "+242",
        "code": "CG"
    },
    {
        "name": "Congo, The Democratic Republic of the Congo",
        "phoneCode": "+243",
        "code": "CD"
    },
    {
        "name": "Cook Islands",
        "phoneCode": "+682",
        "code": "CK"
    },
    {
        "name": "Costa Rica",
        "phoneCode": "+506",
        "code": "CR"
    },
    {
        "name": "Cote d'Ivoire",
        "phoneCode": "+225",
        "code": "CI"
    },
    {
        "name": "Croatia",
        "phoneCode": "+385",
        "code": "HR"
    },
    {
        "name": "Cuba",
        "phoneCode": "+53",
        "code": "CU"
    },
    {
        "name": "Cyprus",
        "phoneCode": "+357",
        "code": "CY"
    },
    {
        "name": "Czech Republic",
        "phoneCode": "+420",
        "code": "CZ"
    },
    {
        "name": "Denmark",
        "phoneCode": "+45",
        "code": "DK"
    },
    {
        "name": "Djibouti",
        "phoneCode": "+253",
        "code": "DJ"
    },
    {
        "name": "Dominica",
        "phoneCode": "+1767",
        "code": "DM"
    },
    {
        "name": "Dominican Republic",
        "phoneCode": "+1849",
        "code": "DO"
    },
    {
        "name": "Ecuador",
        "phoneCode": "+593",
        "code": "EC"
    },
    {
        "name": "Egypt",
        "phoneCode": "+20",
        "code": "EG"
    },
    {
        "name": "El Salvador",
        "phoneCode": "+503",
        "code": "SV"
    },
    {
        "name": "Equatorial Guinea",
        "phoneCode": "+240",
        "code": "GQ"
    },
    {
        "name": "Eritrea",
        "phoneCode": "+291",
        "code": "ER"
    },
    {
        "name": "Estonia",
        "phoneCode": "+372",
        "code": "EE"
    },
    {
        "name": "Ethiopia",
        "phoneCode": "+251",
        "code": "ET"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "phoneCode": "+500",
        "code": "FK"
    },
    {
        "name": "Faroe Islands",
        "phoneCode": "+298",
        "code": "FO"
    },
    {
        "name": "Fiji",
        "phoneCode": "+679",
        "code": "FJ"
    },
    {
        "name": "Finland",
        "phoneCode": "+358",
        "code": "FI"
    },
    {
        "name": "France",
        "phoneCode": "+33",
        "code": "FR"
    },
    {
        "name": "French Guiana",
        "phoneCode": "+594",
        "code": "GF"
    },
    {
        "name": "French Polynesia",
        "phoneCode": "+689",
        "code": "PF"
    },
    {
        "name": "Gabon",
        "phoneCode": "+241",
        "code": "GA"
    },
    {
        "name": "Gambia",
        "phoneCode": "+220",
        "code": "GM"
    },
    {
        "name": "Georgia",
        "phoneCode": "+995",
        "code": "GE"
    },
    {
        "name": "Germany",
        "phoneCode": "+49",
        "code": "DE"
    },
    {
        "name": "Ghana",
        "phoneCode": "+233",
        "code": "GH"
    },
    {
        "name": "Gibraltar",
        "phoneCode": "+350",
        "code": "GI"
    },
    {
        "name": "Greece",
        "phoneCode": "+30",
        "code": "GR"
    },
    {
        "name": "Greenland",
        "phoneCode": "+299",
        "code": "GL"
    },
    {
        "name": "Grenada",
        "phoneCode": "+1473",
        "code": "GD"
    },
    {
        "name": "Guadeloupe",
        "phoneCode": "+590",
        "code": "GP"
    },
    {
        "name": "Guam",
        "phoneCode": "+1671",
        "code": "GU"
    },
    {
        "name": "Guatemala",
        "phoneCode": "+502",
        "code": "GT"
    },
    {
        "name": "Guernsey",
        "phoneCode": "+44",
        "code": "GG"
    },
    {
        "name": "Guinea",
        "phoneCode": "+224",
        "code": "GN"
    },
    {
        "name": "Guinea-Bissau",
        "phoneCode": "+245",
        "code": "GW"
    },
    {
        "name": "Guyana",
        "phoneCode": "+595",
        "code": "GY"
    },
    {
        "name": "Haiti",
        "phoneCode": "+509",
        "code": "HT"
    },
    {
        "name": "Holy See (Vatican City State)",
        "phoneCode": "+379",
        "code": "VA"
    },
    {
        "name": "Honduras",
        "phoneCode": "+504",
        "code": "HN"
    },
    {
        "name": "Hong Kong",
        "phoneCode": "+852",
        "code": "HK"
    },
    {
        "name": "Hungary",
        "phoneCode": "+36",
        "code": "HU"
    },
    {
        "name": "Iceland",
        "phoneCode": "+354",
        "code": "IS"
    },
    {
        "name": "India",
        "phoneCode": "+91",
        "code": "IN"
    },
    {
        "name": "Indonesia",
        "phoneCode": "+62",
        "code": "ID"
    },
    {
        "name": "Iran, Islamic Republic of Persian Gulf",
        "phoneCode": "+98",
        "code": "IR"
    },
    {
        "name": "Iraq",
        "phoneCode": "+964",
        "code": "IQ"
    },
    {
        "name": "Ireland",
        "phoneCode": "+353",
        "code": "IE"
    },
    {
        "name": "Isle of Man",
        "phoneCode": "+44",
        "code": "IM"
    },
    {
        "name": "Israel",
        "phoneCode": "+972",
        "code": "IL"
    },
    {
        "name": "Italy",
        "phoneCode": "+39",
        "code": "IT"
    },
    {
        "name": "Jamaica",
        "phoneCode": "+1876",
        "code": "JM"
    },
    {
        "name": "Japan",
        "phoneCode": "+81",
        "code": "JP"
    },
    {
        "name": "Jersey",
        "phoneCode": "+44",
        "code": "JE"
    },
    {
        "name": "Jordan",
        "phoneCode": "+962",
        "code": "JO"
    },
    {
        "name": "Kazakhstan",
        "phoneCode": "+77",
        "code": "KZ"
    },
    {
        "name": "Kenya",
        "phoneCode": "+254",
        "code": "KE"
    },
    {
        "name": "Kiribati",
        "phoneCode": "+686",
        "code": "KI"
    },
    {
        "name": "Korea, Democratic People's Republic of Korea",
        "phoneCode": "+850",
        "code": "KP"
    },
    {
        "name": "Korea, Republic of South Korea",
        "phoneCode": "+82",
        "code": "KR"
    },
    {
        "name": "Kuwait",
        "phoneCode": "+965",
        "code": "KW"
    },
    {
        "name": "Kyrgyzstan",
        "phoneCode": "+996",
        "code": "KG"
    },
    {
        "name": "Laos",
        "phoneCode": "+856",
        "code": "LA"
    },
    {
        "name": "Latvia",
        "phoneCode": "+371",
        "code": "LV"
    },
    {
        "name": "Lebanon",
        "phoneCode": "+961",
        "code": "LB"
    },
    {
        "name": "Lesotho",
        "phoneCode": "+266",
        "code": "LS"
    },
    {
        "name": "Liberia",
        "phoneCode": "+231",
        "code": "LR"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "phoneCode": "+218",
        "code": "LY"
    },
    {
        "name": "Liechtenstein",
        "phoneCode": "+423",
        "code": "LI"
    },
    {
        "name": "Lithuania",
        "phoneCode": "+370",
        "code": "LT"
    },
    {
        "name": "Luxembourg",
        "phoneCode": "+352",
        "code": "LU"
    },
    {
        "name": "Macao",
        "phoneCode": "+853",
        "code": "MO"
    },
    {
        "name": "Macedonia",
        "phoneCode": "+389",
        "code": "MK"
    },
    {
        "name": "Madagascar",
        "phoneCode": "+261",
        "code": "MG"
    },
    {
        "name": "Malawi",
        "phoneCode": "+265",
        "code": "MW"
    },
    {
        "name": "Malaysia",
        "phoneCode": "+60",
        "code": "MY"
    },
    {
        "name": "Maldives",
        "phoneCode": "+960",
        "code": "MV"
    },
    {
        "name": "Mali",
        "phoneCode": "+223",
        "code": "ML"
    },
    {
        "name": "Malta",
        "phoneCode": "+356",
        "code": "MT"
    },
    {
        "name": "Marshall Islands",
        "phoneCode": "+692",
        "code": "MH"
    },
    {
        "name": "Martinique",
        "phoneCode": "+596",
        "code": "MQ"
    },
    {
        "name": "Mauritania",
        "phoneCode": "+222",
        "code": "MR"
    },
    {
        "name": "Mauritius",
        "phoneCode": "+230",
        "code": "MU"
    },
    {
        "name": "Mayotte",
        "phoneCode": "+262",
        "code": "YT"
    },
    {
        "name": "Mexico",
        "phoneCode": "+52",
        "code": "MX"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "phoneCode": "+691",
        "code": "FM"
    },
    {
        "name": "Moldova",
        "phoneCode": "+373",
        "code": "MD"
    },
    {
        "name": "Monaco",
        "phoneCode": "+377",
        "code": "MC"
    },
    {
        "name": "Mongolia",
        "phoneCode": "+976",
        "code": "MN"
    },
    {
        "name": "Montenegro",
        "phoneCode": "+382",
        "code": "ME"
    },
    {
        "name": "Montserrat",
        "phoneCode": "+1664",
        "code": "MS"
    },
    {
        "name": "Morocco",
        "phoneCode": "+212",
        "code": "MA"
    },
    {
        "name": "Mozambique",
        "phoneCode": "+258",
        "code": "MZ"
    },
    {
        "name": "Myanmar",
        "phoneCode": "+95",
        "code": "MM"
    },
    {
        "name": "Namibia",
        "phoneCode": "+264",
        "code": "NA"
    },
    {
        "name": "Nauru",
        "phoneCode": "+674",
        "code": "NR"
    },
    {
        "name": "Nepal",
        "phoneCode": "+977",
        "code": "NP"
    },
    {
        "name": "Netherlands",
        "phoneCode": "+31",
        "code": "NL"
    },
    {
        "name": "Netherlands Antilles",
        "phoneCode": "+599",
        "code": "AN"
    },
    {
        "name": "New Caledonia",
        "phoneCode": "+687",
        "code": "NC"
    },
    {
        "name": "New Zealand",
        "phoneCode": "+64",
        "code": "NZ"
    },
    {
        "name": "Nicaragua",
        "phoneCode": "+505",
        "code": "NI"
    },
    {
        "name": "Niger",
        "phoneCode": "+227",
        "code": "NE"
    },
    {
        "name": "Nigeria",
        "phoneCode": "+234",
        "code": "NG"
    },
    {
        "name": "Niue",
        "phoneCode": "+683",
        "code": "NU"
    },
    {
        "name": "Norfolk Island",
        "phoneCode": "+672",
        "code": "NF"
    },
    {
        "name": "Northern Mariana Islands",
        "phoneCode": "+1670",
        "code": "MP"
    },
    {
        "name": "Norway",
        "phoneCode": "+47",
        "code": "NO"
    },
    {
        "name": "Oman",
        "phoneCode": "+968",
        "code": "OM"
    },
    {
        "name": "Pakistan",
        "phoneCode": "+92",
        "code": "PK"
    },
    {
        "name": "Palau",
        "phoneCode": "+680",
        "code": "PW"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "phoneCode": "+970",
        "code": "PS"
    },
    {
        "name": "Panama",
        "phoneCode": "+507",
        "code": "PA"
    },
    {
        "name": "Papua New Guinea",
        "phoneCode": "+675",
        "code": "PG"
    },
    {
        "name": "Paraguay",
        "phoneCode": "+595",
        "code": "PY"
    },
    {
        "name": "Peru",
        "phoneCode": "+51",
        "code": "PE"
    },
    {
        "name": "Philippines",
        "phoneCode": "+63",
        "code": "PH"
    },
    {
        "name": "Pitcairn",
        "phoneCode": "+872",
        "code": "PN"
    },
    {
        "name": "Poland",
        "phoneCode": "+48",
        "code": "PL"
    },
    {
        "name": "Portugal",
        "phoneCode": "+351",
        "code": "PT"
    },
    {
        "name": "Puerto Rico",
        "phoneCode": "+1939",
        "code": "PR"
    },
    {
        "name": "Qatar",
        "phoneCode": "+974",
        "code": "QA"
    },
    {
        "name": "Romania",
        "phoneCode": "+40",
        "code": "RO"
    },
    {
        "name": "Russia",
        "phoneCode": "+7",
        "code": "RU"
    },
    {
        "name": "Rwanda",
        "phoneCode": "+250",
        "code": "RW"
    },
    {
        "name": "Reunion",
        "phoneCode": "+262",
        "code": "RE"
    },
    {
        "name": "Saint Barthelemy",
        "phoneCode": "+590",
        "code": "BL"
    },
    {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "phoneCode": "+290",
        "code": "SH"
    },
    {
        "name": "Saint Kitts and Nevis",
        "phoneCode": "+1869",
        "code": "KN"
    },
    {
        "name": "Saint Lucia",
        "phoneCode": "+1758",
        "code": "LC"
    },
    {
        "name": "Saint Martin",
        "phoneCode": "+590",
        "code": "MF"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "phoneCode": "+508",
        "code": "PM"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "phoneCode": "+1784",
        "code": "VC"
    },
    {
        "name": "Samoa",
        "phoneCode": "+685",
        "code": "WS"
    },
    {
        "name": "San Marino",
        "phoneCode": "+378",
        "code": "SM"
    },
    {
        "name": "Sao Tome and Principe",
        "phoneCode": "+239",
        "code": "ST"
    },
    {
        "name": "Saudi Arabia",
        "phoneCode": "+966",
        "code": "SA"
    },
    {
        "name": "Senegal",
        "phoneCode": "+221",
        "code": "SN"
    },
    {
        "name": "Serbia",
        "phoneCode": "+381",
        "code": "RS"
    },
    {
        "name": "Seychelles",
        "phoneCode": "+248",
        "code": "SC"
    },
    {
        "name": "Sierra Leone",
        "phoneCode": "+232",
        "code": "SL"
    },
    {
        "name": "Singapore",
        "phoneCode": "+65",
        "code": "SG"
    },
    {
        "name": "Slovakia",
        "phoneCode": "+421",
        "code": "SK"
    },
    {
        "name": "Slovenia",
        "phoneCode": "+386",
        "code": "SI"
    },
    {
        "name": "Solomon Islands",
        "phoneCode": "+677",
        "code": "SB"
    },
    {
        "name": "Somalia",
        "phoneCode": "+252",
        "code": "SO"
    },
    {
        "name": "South Africa",
        "phoneCode": "+27",
        "code": "ZA"
    },
    {
        "name": "South Sudan",
        "phoneCode": "+211",
        "code": "SS"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "phoneCode": "+500",
        "code": "GS"
    },
    {
        "name": "Spain",
        "phoneCode": "+34",
        "code": "ES"
    },
    {
        "name": "Sri Lanka",
        "phoneCode": "+94",
        "code": "LK"
    },
    {
        "name": "Sudan",
        "phoneCode": "+249",
        "code": "SD"
    },
    {
        "name": "Suriname",
        "phoneCode": "+597",
        "code": "SR"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "phoneCode": "+47",
        "code": "SJ"
    },
    {
        "name": "Swaziland",
        "phoneCode": "+268",
        "code": "SZ"
    },
    {
        "name": "Sweden",
        "phoneCode": "+46",
        "code": "SE"
    },
    {
        "name": "Switzerland",
        "phoneCode": "+41",
        "code": "CH"
    },
    {
        "name": "Syrian Arab Republic",
        "phoneCode": "+963",
        "code": "SY"
    },
    {
        "name": "Taiwan",
        "phoneCode": "+886",
        "code": "TW"
    },
    {
        "name": "Tajikistan",
        "phoneCode": "+992",
        "code": "TJ"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "phoneCode": "+255",
        "code": "TZ"
    },
    {
        "name": "Thailand",
        "phoneCode": "+66",
        "code": "TH"
    },
    {
        "name": "Timor-Leste",
        "phoneCode": "+670",
        "code": "TL"
    },
    {
        "name": "Togo",
        "phoneCode": "+228",
        "code": "TG"
    },
    {
        "name": "Tokelau",
        "phoneCode": "+690",
        "code": "TK"
    },
    {
        "name": "Tonga",
        "phoneCode": "+676",
        "code": "TO"
    },
    {
        "name": "Trinidad and Tobago",
        "phoneCode": "+1868",
        "code": "TT"
    },
    {
        "name": "Tunisia",
        "phoneCode": "+216",
        "code": "TN"
    },
    {
        "name": "Turkey",
        "phoneCode": "+90",
        "code": "TR"
    },
    {
        "name": "Turkmenistan",
        "phoneCode": "+993",
        "code": "TM"
    },
    {
        "name": "Turks and Caicos Islands",
        "phoneCode": "+1649",
        "code": "TC"
    },
    {
        "name": "Tuvalu",
        "phoneCode": "+688",
        "code": "TV"
    },
    {
        "name": "Uganda",
        "phoneCode": "+256",
        "code": "UG"
    },
    {
        "name": "Ukraine",
        "phoneCode": "+380",
        "code": "UA"
    },
    {
        "name": "United Arab Emirates",
        "phoneCode": "+971",
        "code": "AE"
    },
    {
        "name": "United Kingdom",
        "phoneCode": "+44",
        "code": "GB"
    },
    {
        "name": "United States",
        "phoneCode": "+1",
        "code": "US"
    },
    {
        "name": "Uruguay",
        "phoneCode": "+598",
        "code": "UY"
    },
    {
        "name": "Uzbekistan",
        "phoneCode": "+998",
        "code": "UZ"
    },
    {
        "name": "Vanuatu",
        "phoneCode": "+678",
        "code": "VU"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "phoneCode": "+58",
        "code": "VE"
    },
    {
        "name": "Vietnam",
        "phoneCode": "+84",
        "code": "VN"
    },
    {
        "name": "Virgin Islands, British",
        "phoneCode": "+1284",
        "code": "VG"
    },
    {
        "name": "Virgin Islands, U.S.",
        "phoneCode": "+1340",
        "code": "VI"
    },
    {
        "name": "Wallis and Futuna",
        "phoneCode": "+681",
        "code": "WF"
    },
    {
        "name": "Yemen",
        "phoneCode": "+967",
        "code": "YE"
    },
    {
        "name": "Zambia",
        "phoneCode": "+260",
        "code": "ZM"
    },
    {
        "name": "Zimbabwe",
        "phoneCode": "+263",
        "code": "ZW"
    }
]

