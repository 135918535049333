import React from 'react'
import {
  Input, Select, DatePicker, Modal,
  Form, notification, Button, Radio
} from 'antd'
import moment from 'moment'

export const EmailActivityModal = (props) => {
  const { cancelActivityModal, emailModal, singleActivity } = props
  return <Modal
    visible={emailModal}
    title="Email"
    onCancel={cancelActivityModal}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
  >

    <div>
      <div>
        <h3><b>Receiver :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleActivity && singleActivity.emailId}</div>

      </div>
      <div>
        <h3><b>Subject :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleActivity && singleActivity.emailSubject}</div>

      </div>
      <div>
        <h3><b>Message :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }} dangerouslySetInnerHTML={{ __html: singleActivity && singleActivity.emailMessage }}></div>

      </div>
    </div>

  </Modal>

}
export const FollowUpActivityModal = (props) => {
  const { cancelActivityModal, followUpModal, singleFollowUpActivity } = props
  return <Modal
    visible={followUpModal}
    title="Followup"
    onCancel={cancelActivityModal}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
  >

    <div>
      <div>
        <h3><b>FollowUp Date :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{moment(singleFollowUpActivity && singleFollowUpActivity.followUpDate).format('ll')}</div>

      </div>
      <div>
        <h3><b>Time :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleFollowUpActivity && singleFollowUpActivity.followUpTime}</div>

      </div>
      <div>
        <h3><b>Description :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleFollowUpActivity && singleFollowUpActivity.followUpDescription}</div>

      </div>
    </div>


  </Modal>
}

export const AppointmentActivityModal = (props) => {
  const { cancelActivityModal, appointmentModal, singleAppointmentActivity } = props
  console.log(singleAppointmentActivity)
  return <Modal
    visible={appointmentModal}
    title="Appointment"
    onCancel={cancelActivityModal}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
  >

    <div>
      <div>
        <h3><b>Appointment Date :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{moment(singleAppointmentActivity && singleAppointmentActivity.appointmentDate).format('ll')}</div>

      </div>
      <div>
        <h3><b>Time :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleAppointmentActivity && singleAppointmentActivity.appointmentTime}</div>

      </div>
      <div>
        <h3><b>Description :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleAppointmentActivity && singleAppointmentActivity.appointmentDescription}</div>

      </div>
      <div>
        <h3><b>Type :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleAppointmentActivity && singleAppointmentActivity.appointmentType}</div>

      </div>
      <div>
        <h3><b>Location :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleAppointmentActivity && singleAppointmentActivity.appointmentLocation}</div>

      </div>
    </div>


  </Modal>
}
export const WonModal = (props) => {
  let user = { ...JSON.parse(localStorage.getItem('user')) }
  const { form, onCancel, updateTheLead, wonModal, estimateCloseDate, leadId } = props
  return <Modal
    visible={wonModal}
    title="Won Deal"
    okText="Save"
    cancelText="Cancel"
    onCancel={onCancel}
    onOk={() => {
      props.form.validateFields()
        .then(async (values) => {
          props.form.resetFields()
          values.status = 'Won'
          values.stage = 'Contacted'
          values.leadCloseDate = Date.now()
          values.estimateCloseDate = values.estimateCloseDate || estimateCloseDate
          updateTheLead(values)
          onCancel()

        })
        .catch(info => {
          console.log('Validate Failed:', info)
        })
    }}
  >
    <p> Before you mark this deal as won, please update the following information as needed.</p>

    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ estimateCloseDate: estimateCloseDate ? moment(estimateCloseDate) : moment() }}

    >
      <Form.Item
        name="statusDescription"
        label="Won Description"
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="estimateCloseDate" label="Closed Date" rules={[{ required: true }]}>
        <DatePicker />
      </Form.Item>

    </Form>


  </Modal>

}
export const LostModal = (props) => {
  let user = { ...JSON.parse(localStorage.getItem('user')) }
  const { form, onCancel, updateTheLead, lostModal, estimateCloseDate, leadId } = props
  return <Modal
    visible={lostModal}
    title="Lost Deal"
    okText="Save"
    cancelText="Cancel"
    onCancel={onCancel}
    onOk={() => {
      props.form.validateFields()
        .then(values => {
          props.form.resetFields()
          values.status = 'Lost'
          values.stage = 'Contacted'
          values.leadCloseDate = Date.now()
          values.estimateCloseDate = values.estimateCloseDate || estimateCloseDate
          updateTheLead(values)
          onCancel()

        })
        .catch(info => {
          console.log('Validate Failed:', info)
        })
    }}
  >
    <p> Before you mark this deal as lost, please update the following information as needed.</p>

    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ estimateCloseDate: estimateCloseDate ? moment(estimateCloseDate) : moment() }}

    >
      <Form.Item
        name="statusDescription"
        label="Lost Description"
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item name="estimateCloseDate" label="Closed Date"
                 rules={[{ required: true }]}
      >
        <DatePicker />
      </Form.Item>

    </Form>


  </Modal>

}
export const WhatsAppActivityModal = (props) => {
  const { cancelActivityModal, whatsAppModal, singleWhatsappActivity } = props
  return <Modal
    visible={whatsAppModal}
    title="WhatsApp Text"
    onCancel={cancelActivityModal}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
  >
    <div>{singleWhatsappActivity && singleWhatsappActivity.whatsAppMessage}</div>
  </Modal>

}
export const ProposalActivityModal = (props) => {
  const { cancelActivityModal, proposalModal, singleProposalActivity } = props
  console.log(singleProposalActivity)
  return <Modal
    visible={proposalModal}
    title="Proposal"
    onCancel={cancelActivityModal}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
  >
    <div>
      <div>
        <h3><b>Browser Name :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleProposalActivity.proposalAccessDetails.browserName}</div>

      </div>
      <div>
        <h3><b>OS Name :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleProposalActivity.proposalAccessDetails.osName}</div>

      </div>
      <div>
        <h3><b>OS Version :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleProposalActivity.proposalAccessDetails.osVersion}</div>

      </div>
      <div>
        <h3><b>Browser Version :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleProposalActivity.proposalAccessDetails.browserVersion}</div>

      </div>
      <div>
        <h3><b>Device Type :</b></h3>
        <div style={{
          border: '1px solid #DAE0E2',
          margin: '5px',
          backgroundColor: '#DAE0E2',
          width: '100%',
          padding: '3px'
        }}>{singleProposalActivity.proposalAccessDetails.deviceType}</div>

      </div>
      <div style={{ marginTop: '5px' }}>
        <Radio disabled={true} defaultChecked={singleProposalActivity.proposalAccessDetails.isMobile}>Is Mobile</Radio>
        <Radio disabled={true} defaultChecked={singleProposalActivity.proposalAccessDetails.isDesktop}>Is
          Desktop</Radio>

      </div>

    </div>


  </Modal>
}
