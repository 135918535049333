import { API_URL } from '../../../request'

export const addClosingUrl = () => {
  return API_URL + `/closing`
}

export const closingStatusUrl = () => {
  return API_URL + `/closing/status`
}
export const closingCountUrl = () => {
  return API_URL + `/user/closing`
}

