import React, {useEffect, useState, useRef} from 'react'
import styles from './style.less'
import {
    Button,
    Modal, Carousel,
    Spin,
    Tooltip,
} from 'antd'
import SimpleReactLightbox, {SRLWrapper, useLightbox} from "simple-react-lightbox";

import {downloadProjectFiles, downloadProjectVariationsFiles} from './actions/index'

import Appointment from '../appointment/views/index'
import {MdApartment, MdOutlineBedroomParent, MdPhoto} from 'react-icons/md'
import {FaRegHandshake, FaRegBuilding} from 'react-icons/fa'
import {AiOutlineCloudDownload} from 'react-icons/ai'
import {BiBath} from 'react-icons/bi'
import {GoLocation} from 'react-icons/go'
import GoogleMapReact from 'google-map-react'
import {mapKey, renderPriceForDetails} from '../../request'
import {browserName, osName, osVersion, browserVersion, deviceType, isDesktop, isMobile} from 'react-device-detect'
import {useMediaQuery} from 'react-responsive'
import _ from 'lodash';


const defaultProps = {
    center: {
        lat: 24.374716562246505,
        lng: 54.57033648551117,
    },
    zoom: 11,
}


const ProposalDetails = props => {

    const [loading, setLoading] = useState(true)
    const [visible, setVisible] = React.useState(false)
    const [projectId, setProjectId] = React.useState(null)
    const [modalKey, setModalKey] = React.useState(null)
    const [projArr, setArr] = React.useState([])
    const [slideArr, setSlide] = React.useState([
        /* {
             src: 'https://picsum.photos/200',
             caption: 'Lorem ipsum dolor sit amet',
             height: 'auto'
         }*/
    ])
    const {proposal, projects} = props;


    const isDesktopOrLaptop = useMediaQuery({minWidth: 481})

    useEffect(() => {
        setArr(projects)
    }, [projects])
    const showModal = (projectId) => {
        setProjectId(projectId)
        setVisible(true)
        setModalKey(Date.now())
    }
    const handleCancel = () => {
        setVisible(false)
        setProjectId(null)
    }
    const downloadMediaFiles = async ({type, projectName, projectId}) => {

        let tempArr = _.cloneDeep(projArr);
        let findIn = _.find(tempArr, (vv) => {
            return vv._id == projectId;
        })
        findIn.downloading = true;
        setArr(tempArr);


        let {file, fileExt} = await downloadProjectFiles({
            type,
            projectId,
            projectName,
            leadId: proposal.leadId,
            proposalAccessDetails: {
                browserName,
                osName,
                osVersion,
                browserVersion,
                deviceType,
                isMobile,
                isDesktop,
            },

        })
        if (file) {
            var link = document.createElement('a')
            document.body.appendChild(link)
            link.setAttribute('type', 'hidden')
            link.href = file
            link.download = `${projectName}-${type}.${fileExt}`
            link.click()
            document.body.removeChild(link)
        }

        let afterTemp = _.cloneDeep(projArr);
        let findInNN = _.find(tempArr, (vv) => {
            return vv._id == projectId;
        })
        findInNN.downloading = false;
        setArr(afterTemp);

    }
    const downloadVariantFiles = async ({type, projectId, projectName}) => {

        // setLoading(true)
        let {file, fileExt} = await downloadProjectVariationsFiles({
            type,
            projectId,
            projectName
        })
        if (file) {
            var link = document.createElement('a')
            document.body.appendChild(link)
            link.setAttribute('type', 'hidden')
            link.href = file
            link.download = `${projectName}-${type}.${fileExt}`
            link.click()
            document.body.removeChild(link)
        }
        // setLoading(true)


    }

    useEffect(() => {
        setLoading(false)
    }, [])

    const {openLightbox, closeLightbox} = useLightbox()

    const handleApiLoadData = (map, maps, lat, lng) => {
        if (lat && lng) {
            let marker = new maps.Marker({
                animation: maps.Animation.DROP,
                position: {lat, lng},
                map,
            })
        }

    }

    const onChange = (a, b, c) => {
        console.log(a, b, c)
    }

    const setSlideShow = (arr) => {
        console.log(arr)
        setSlide(arr);
        setTimeout(() => {
            openLightbox();
        }, 500)
    }
    // const [links, setLinks] = useState(items)

    return (
        <div>
            {loading ? (
                <Spin tip="Loading..."/>
            ) : (
                <div className="proposalDetails">


                    {/*  <button onClick={() => openLightbox()}>
                        Open the lightbox
                    </button>
                    <button onClick={() => closeLightbox()}>
                        Close the lightbox
                    </button>*/}
                    <SRLWrapper elements={slideArr}/>


                    <div className={isDesktopOrLaptop ? styles.customerDetails : styles.mobilcustomerDetails}>
                        <div className={styles.proposalFor}>
                            <div className={styles.propFor}>Proposal For :</div>
                            <div className={styles.customerName}>Mr/Mrs {proposal?.customer ?? ''}</div>
                        </div>

                    </div>
                    {projArr && projArr.map((prop, key) => (
                        <div key={key} className={isDesktopOrLaptop ? styles.projArr : styles.projArrMobile}>

                            <div className={styles.imgDetails}>

                                {
                                    prop.featureImg ? <img className={styles.caroImg} src={prop.featureImg}/> : null
                                }
                                {/*  {
                                    prop.photos ? <Carousel afterChange={onChange}>
                                        {
                                            prop.photos.map((val, sinke) => {
                                                if (val.remoteUrl) {
                                                    return <img className={styles.caroImg} key={sinke}
                                                                src={val.remoteUrl}/>
                                                } else {
                                                    return ''
                                                }

                                            })
                                        }
                                    </Carousel> : null
                                }*/}

                            </div>
                            <div className={styles.projDetail}>

                                <div className={styles.nameCtr}>
                                    <div style={{flex: 1}}>
                                        <div className={styles.name}>{prop.name.toUpperCase()}</div>
                                        {
                                            prop.price ?
                                                <div
                                                    className={styles.price}>{renderPriceForDetails(prop.price)}</div> : null
                                        }

                                        {
                                            prop.area ? <div className={styles.size}>{prop.area.from} sqft
                                                - {prop.area.to} sqft</div> : null}

                                        {prop.address ? <div className={styles.address}><GoLocation
                                            className={styles.location}/> {prop.address}</div> : null}

                                    </div>
                                    <div>
                                        {
                                            prop.builderIcon ?
                                                <img className={styles.builderImg}
                                                     src={prop.builderIcon}/> : null
                                        }
                                    </div>


                                </div>
                                <div className={styles.actionCtr}>
                                    <div className={styles.infoCtr}>
                                        {/*<div className={styles.typeCtr}>
                                            <div className={styles.type}>
                                                <div>Property</div>
                                                <div className={styles.typeVal}>
                                                    {
                                                        isDesktopOrLaptop ? <MdApartment
                                                            className={styles.icontype}/> : null
                                                    }
                                                    {prop.type}</div>
                                            </div>
                                            {prop.bedRoom &&
                                            <div className={styles.type}>
                                                <div>Beds</div>
                                                <div className={styles.typeVal}><MdOutlineBedroomParent
                                                    className={styles.icontype}/> {prop.bedRoom}</div>
                                            </div>
                                            }

                                            {prop.bathRoom &&
                                            <div className={styles.type}>
                                                <div>Bath</div>
                                                <div className={styles.typeVal}><MdOutlineBedroomParent
                                                    className={styles.icontype}/> {prop.bathRoom}
                                                </div>
                                            </div>
                                            }
                                            {!isDesktopOrLaptop ?
                                                <div className={styles.type} style={{textAlign: 'right'}}>
                                                    {
                                                        prop.builderIcon ?
                                                            <img className={styles.builderImg}
                                                                 src={prop.builderIcon}/> : null
                                                    }
                                                </div> : null
                                            }

                                        </div>*/}

                                        <div className={styles.btnCtr}>
                                            {prop.photos && prop.photos.length ?
                                                <div className={styles.firstBtn}>
                                                    <Tooltip title="Download Photos">


                                                        <button className={styles.btns}
                                                                disabled={prop.downloading}
                                                                onClick={() => {
                                                                    setSlideShow(prop.photos)
                                                                    /* downloadMediaFiles({
                                                                         type: 'photos',
                                                                         projectName: prop.name,
                                                                         projectId: prop._id,
                                                                     })*/
                                                                }}>
                                                            <img className={styles.btnIcon}
                                                                 src={'/photos.png'}/>
                                                            <span>Photos</span>
                                                        </button>
                                                    </Tooltip>
                                                </div> : null}
                                            {
                                                prop.brochure && prop.brochure.length ? <div
                                                    className={styles.firstBtn}>
                                                    <Tooltip title="Download Brochure">
                                                        <button
                                                            disabled={prop.downloading}
                                                            className={styles.btns}
                                                            onClick={() => {
                                                                if (prop.brochure[0].src) {
                                                                    window.open(prop.brochure[0].src, "_blank")
                                                                }


                                                                /* downloadMediaFiles({
                                                                     type: 'brochure',
                                                                     projectId: prop._id,
                                                                     projectName: prop.name,
                                                                 })*/
                                                            }}
                                                        >
                                                            {/* <AiOutlineCloudDownload className={styles.btnIcon}/>
                                                            Brochure*/}

                                                            <img className={styles.btnIcon}
                                                                 src={'/brochure.png'}/>
                                                            <span>Brochure</span>

                                                        </button>
                                                    </Tooltip>
                                                </div> : null
                                            }

                                            {prop.plan && prop.plan.length ? <div className={styles.firstBtn}>

                                                <Tooltip title="Download Plan">
                                                    <button
                                                        disabled={prop.downloading}
                                                        className={styles.btns}
                                                        onClick={() => {
                                                            setSlideShow(prop.plan)
                                                            /*downloadMediaFiles({
                                                                type: 'plan',
                                                                projectId: prop._id,
                                                                projectName: prop.name,
                                                            })*/
                                                        }}
                                                    >
                                                        {/*<AiOutlineCloudDownload className={styles.btnIcon}/> Plan*/}

                                                        <img className={styles.btnIcon}
                                                             src={'/plan.png'}/>
                                                        <span>Plan</span>
                                                    </button>
                                                </Tooltip></div> : null}


                                        </div>
                                        {
                                            prop.downloading ? <div className={styles.wait}>
                                                Downloading, few secs....
                                            </div> : null
                                        }
                                        <div className={styles.appointBtn}>
                                            <Button type="primary" onClick={() => showModal(prop._id)}>
                                                <FaRegHandshake className={styles.apointIcon}/> Book Viewing
                                            </Button>

                                        </div>
                                    </div>
                                    <div className={styles.variantCtr}>

                                        {
                                            prop.variants && prop.variants.map((vv, kk) => {
                                                return (
                                                    <div key={kk} className={styles.single}>
                                                        <div className={styles.variantName}>{vv.name}</div>
                                                        {
                                                            vv.price ? <div
                                                                className={styles.variantPrice}>Price {renderPriceForDetails(vv.price)}</div> : null
                                                        }

                                                        {
                                                            vv.area ? <div className={styles.variantArea}>
                                                                {vv.area.from} sqft - {vv.area.to} sqft
                                                            </div> : null
                                                        }

                                                        <div className={styles.variantBed}>
                                                            {
                                                                vv.bedRoom ?
                                                                    <div className={styles.bedVal} title={'Bed room'}>
                                                                        <MdOutlineBedroomParent
                                                                            className={styles.icontype}/> {vv.bedRoom}
                                                                    </div> : null}
                                                            {
                                                                vv.bedRoom ?
                                                                    <div className={styles.bedVal} title={'Bath room'}>
                                                                        <BiBath
                                                                            className={styles.icontype}/> {vv.bedRoom}
                                                                    </div> : null}
                                                        </div>

                                                        <div className={styles.variantActions}>

                                                            {vv.photos && vv.photos.length ?

                                                                <Tooltip title="View Photos">
                                                                    <button className={styles.downBtn}
                                                                            disabled={prop.downloading}
                                                                            onClick={() => {
                                                                                setSlideShow(vv.photos)

                                                                                /*downloadVariantFiles({
                                                                                    type: 'photos',
                                                                                    projectName: vv.name,
                                                                                    projectId: vv._id,
                                                                                })*/
                                                                            }}>

                                                                        <img className={styles.downIcon}
                                                                             src={'/photos.png'}/>
                                                                    </button>
                                                                </Tooltip> : null}
                                                            {vv.plan && vv.plan.length ?
                                                                <Tooltip title="View Floor Plan">
                                                                    <button className={styles.downBtn}
                                                                            disabled={prop.downloading}
                                                                            onClick={() => {
                                                                                setSlideShow(vv.plan)
                                                                                /*downloadVariantFiles({
                                                                                    type: 'plan',
                                                                                    projectName: vv.name,
                                                                                    projectId: vv._id,
                                                                                })*/
                                                                            }}>
                                                                        <img className={styles.downIcon}
                                                                             src={'/plan.png'}/>
                                                                    </button>
                                                                </Tooltip> : null}

                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>

                            </div>

                            <div className={styles.projMapCtr}>
                                {
                                    prop.lat && prop.lng ? <GoogleMapReact
                                        bootstrapURLKeys={{key: mapKey}}
                                        defaultCenter={{
                                            lat: prop.lat,
                                            lng: prop.lng,
                                        }}
                                        defaultZoom={defaultProps.zoom}
                                        onGoogleApiLoaded={({map, maps}) => handleApiLoadData(map,
                                            maps,
                                            prop.lat, prop.lng,
                                        )}
                                    /> : null
                                }
                            </div>

                        </div>

                    ))}

                    <Modal
                        title="Schedule Appointment"
                        visible={visible}
                        key={modalKey}
                        confirmLoading={loading}
                        footer={[]}
                        onCancel={handleCancel}>
                        <Appointment
                            hideTitle={true}
                            projectId={projectId}
                            leadId={proposal && proposal.leadId}
                            showPreField={true}
                            handleAddAppointment={async () => {

                                handleCancel()

                            }}
                        />
                    </Modal>
                </div>
            )}
        </div>
    )
}

export default ProposalDetails
