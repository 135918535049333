import {
    addUserUrl,
    listUsersUrl,
    updateUserUrl,
    getUserUrl,
    downloadMailerUrl,
    checkinListUrl,
    downloadVCardUrl,
} from '../api/index'
import axios from 'axios'
import {notification} from 'antd'
import {API_URL, downloadData, getToken} from '../../../request'
import {updateLeadUrl} from '../../leads/api'
import {meetingUrl} from "../../meeting/api";

export const addUserAction = async params => {
    try {
        let {data} = await axios.post(
            addUserUrl(),
            {...params},
            getToken()
        )

        // if (data.error) {
        //     notification.error({
        //         message: data.message
        //     })
        // }
        return data
    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}

export const listUsersAction = async params => {
    try {
        let {data} = await axios.post(
            listUsersUrl(),
            {...params},
            getToken()
        )

        if (data.error) {
            notification.error({
                message: data.message
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}

export const editUserAction = async params => {
    try {
        let {data} = await axios.put(
            updateUserUrl(),
            {...params},
            getToken()
        )

        // if (data.error) {
        //     notification.error({
        //         message: data.message || 'Updated Successfully'
        //     })
        // }
        return data
    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}
export const getUserAction = async body => {
    try {
        let {data} = await axios.post(
            getUserUrl(),
            body,
            getToken()
        )
        return data
    } catch (err) {

        return null
    }
}
export const downloadMailer = async id => {
    try {
        let {data} = await axios.get(downloadMailerUrl(id), {params: {}, ...getToken()})
        if (data && data.availOn) {
            downloadData(data.availOn, id)
        }

        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const downloadVCard = async id => {
    try {
        let {data} = await axios.get(downloadVCardUrl(id), {params: {}, ...getToken()})
        if (data && data.availOn) {
            downloadData(data.availOn, id)
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}

export const checkinList = async params => {
    try {
        let {data} = await axios.get(
          checkinListUrl(), {params: {...params}, ...getToken()},
        )

        return data
    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}
