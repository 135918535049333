import {
    listLeadsUrl,
    listSharedUrl,
    fetchAllFacebookDataUrl,
    addLeadUrl,
    listProposalUrl,
    FetchProposalUrl,
    searchLeadsUrl,
    leadsActivitiesUrl,
    addLeadActivity,
    saveAndSendEmail,
    updateLeadUrl,
    loadLeadsUrl,
    assignLeadUrl,
    emailsListUrl,
    viewProposalUrl,
    addViewingLeadUrl,
    saveComment,
    listLeadsExportUrl,
    getLeadStatsUrl,
    assignCampaignUrl,
    assignLogsUrl

} from '../api/index'
import axios from 'axios'
import {notification} from 'antd'
import {getToken} from '../../../request'
import {updateProjectUrl} from '../../project/api'

export const listLeads = async params => {
    try {
        let {data} = await axios.post(
            listLeadsUrl(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}

export const getSharedLeads = async params => {
    try {
        let {data} = await axios.post(
            listSharedUrl(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const ExportLeadAction = async params => {
    try {
        console.log(params)
        let {data} = await axios.post(
            listLeadsExportUrl(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        console.log(err)
        notification.error({
            message: 'Error',
        })
        return null
    }
}

export const searchLead = async params => {
    try {
        let {data} = await axios.post(
            searchLeadsUrl(),
            {...params},
            getToken(),
        )
        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const fetchAllFacebookData = async params => {
    try {
        let {data} = await axios.post(
            fetchAllFacebookDataUrl(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const addLeadAction = async params => {
    try {
        let {data} = await axios.post(
            addLeadUrl(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const listProposals = async params => {
    try {
        let {data} = await axios.get(
            listProposalUrl(), {params: {...params}, ...getToken()},
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const SaveLeadsToProject = async body => {
    try {
        let {data} = await axios.post(
            listProposalUrl(), body, getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const FetchAssignedProjects = async body => {
    try {
        let {data} = await axios.post(
            FetchProposalUrl(), body, getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const leadsActivities = async params => {
    try {
        let {data} = await axios.get(
            leadsActivitiesUrl(), {params: {...params}, ...getToken()},
        )
        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const AddLeadActivity = async body => {
    try {
        let {data} = await axios.post(
            addLeadActivity(), body, getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const SaveAndSendEmail = async body => {
    try {
        let {data} = await axios.post(
            saveAndSendEmail(), body, getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const UpdateLead = async params => {
    try {
        console.log(params)
        let {data} = await axios.put(
            updateLeadUrl(params._id),
            {...params},
            getToken(),
        )

        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const GetALeadAction = async params => {
    console.log("params", params)
    try {
        let {data} = await axios.get(
            updateLeadUrl(params.id),
            {params: {...params}, ...getToken()}
        )

        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const loadLeads = async params => {
    try {
        let {data} = await axios.post(
            loadLeadsUrl(),
            {...params},
            getToken(),
        )
        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const assignLeadsFxn = async params => {
    try {
        let {data} = await axios.post(
            assignLeadUrl(),
            {...params},
            getToken()
        )
        if (data.error) {
            notification.error({
                message: data.message
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}
export const assignCampaign = async params => {
    try {
        let {data} = await axios.post(
            assignCampaignUrl(),
            {...params},
            getToken()
        )
        if (data.error) {
            notification.error({
                message: data.message
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}
export const emailsList = async params => {
    try {
        let {data} = await axios.get(
            emailsListUrl(), {params: {...params}, ...getToken()},
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const ViewProposalAction = async body => {
    try {
        let {data} = await axios.post(
            viewProposalUrl(), body, getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const deleteLead = async params => {
    try {
        let {data} = await axios.delete(updateLeadUrl(params._id), {params: {...params}, ...getToken()})

        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const addViewingLeadAction = async params => {
    try {
        console.log(params);
        let {data} = await axios.post(
            addViewingLeadUrl(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const listViewingLeads = async params => {
    try {
        let {data} = await axios.get(
            addViewingLeadUrl(), {params: {...params}, ...getToken()},
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const getLeadStats = async params => {
    try {
        let {data} = await axios.post(
            getLeadStatsUrl(), params, getToken()
        )

        /* if (data.error) {
           notification.error({
             message: data.message,
           })
         }*/
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}

export const updateViewingLeads = async params => {
    try {
        let {data} = await axios.put(
            addViewingLeadUrl(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const saveCommentAction = async params => {
    try {
        let {data} = await axios.post(
            saveComment(),
            {...params},
            getToken(),
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
export const assignLogsAction = async params => {
    try {
        let {data} = await axios.get(
            assignLogsUrl(), {params: {...params}, ...getToken()},
        )

        if (data.error) {
            notification.error({
                message: data.message,
            })
        }
        return data
    } catch (err) {
        notification.error({
            message: 'Error',
        })
        return null
    }
}
