import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import App from './containers/app'
import configureStore, { history } from './store'
// import SimpleReactLightbox from 'simple-react-lightbox'
import moment from 'moment-timezone'

moment.tz('Asia/Kolkata')

const store = configureStore(/* provide initial state if any */)
import './index.css'

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */}
      <App/>
    </ConnectedRouter>
  </Provider>,
  target,
)

