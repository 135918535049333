import React, { PureComponent, useCallback, useEffect, useState } from 'react'
import {
  Steps,
  Card, Input, Select, DatePicker, Modal, Icon, Spin, Typography, InputNumber, Skeleton,
  Row, Col, Tabs, Collapse, Button, Form, Tag, Alert, Badge,
  Avatar, Tooltip, Timeline
} from 'antd'

import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import moment from 'moment'
import CF from 'react-currency-format'
import { BookOutlined, PhoneOutlined } from '@ant-design/icons'
import styles from './styles.less'
import { loadLeads } from '../actions'
import ViewLead from '../views/viewLead'
import VisibilitySensor from 'react-visibility-sensor'
import { useSelector, useDispatch } from 'react-redux'

import { getRatingColor, getStageColor } from '../../../request'

const ViewUserLead = props => {
  const [form] = Form.useForm()
  const [loader, setLoader] = useState(false)
  const [allDeals, setAllLeads] = useState([])
  const [leadId, setLeadId] = useState('')
  const [refreshRightSide, setRefreshRightSide] = useState()
  const [paginate, setPaginate] = useState(1)
  const [pages, setPages] = useState(null)
  const [loadMoreLoader, setLoadMoreLoader] = useState(false)

  let user = JSON.parse(localStorage.getItem('user'))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
  }, [])

  const getTodoLeads = async (setLeadsNull, pageNew) => {
    let params = {
      results: pages ? pages * 20 : 20,
      page: pages ? 1 : paginate,
      count: 20
    }
    if (setLeadsNull) {
      params.page = pageNew
    }
    let oldData = _.cloneDeep(allDeals)

    // if (user && user.userType != 'admin') {
    //   params.assignTo = user && user._id
    //
    // }
    let { data } = await loadLeads({
      status: 'Open',
      stage: 'Untouched',
      snoozeTime: null,
      isFollowUp: false, ...params
    })
    console.log(data, 'data')
    setLoadMoreLoader(false)

    if (pageNew || paginate == 1) {
      if (data && data[0] && data[0]._id) {
        data[0].activeLead = true
        setAllLeads(data)
        setLeadId(data && data[0] && data[0]._id)
        setRefreshRightSide(data && data[0] && data[0]._id)

      }
    }


    if (data) {
      if (setLeadsNull) {
        setAllLeads(data)
      } else {
        data = [...oldData, ...data]
        setAllLeads(data)
      }

    }

  }
  // useEffect(async () => {
  //   getTodoLeads()
  // }, [])

  const loadMore = () => {
    setLoadMoreLoader(true)
    setPaginate(paginate + 1)
    getTodoLeads()
    //demo(Date.now())

  }

  const getAcitveStyle = (active) => {
    if (!active) {
      return {}
    }

    return {
      backgroundColor: 'white',
      borderRight: 0,
      width: 'calc(100% + 1px)'
    }

  }

  const RenderSnooze = (props) => {
    let { data } = props
    let newData = _.filter(data, (x) => x.type == 'snooze')
    newData = newData[newData.length - 1]
    if (newData) {
      return <small><Tag
        color={'red'}>{newData && newData.message} on {moment(newData.createdAt).format('lll')}</Tag></small>

    } else {
      return <small></small>
    }


  }

  return (<Spin size="large" spinning={loader}>
    <React.Fragment>
      <Row className={styles.mainLeadView}>
        <Col md={6} style={{ minHeight: '100%' }}>
          <div id="cardCont" className={styles.cardCont}>
            {allDeals && allDeals.map((val, index) => {
              let { activeLead } = val
              return (
                <>
                  <div key={index}
                       className={styles.card}
                       style={getAcitveStyle(activeLead)}
                       onClick={() => {
                         setLeadId(val._id)
                         setRefreshRightSide(val._id)
                         let allDeals2 = _.cloneDeep(allDeals)
                         allDeals2.map((v) => {
                           v.activeLead = false
                           if (v._id === val._id) {
                             v.activeLead = true
                           }
                           return v
                         })
                         setAllLeads(allDeals2)
                       }}>

                    <div className={styles.title}>

                      <small style={{ marginRight: 5 }}>#{index + 1}</small>
                      {val && (val.customer)} ({val && val.city}) <span
                      style={{ marginLeft: 'auto', color: '#4C4B4B' }}> <Tag
                      visible={(val.new == 'undefined') ? true : val.new}
                      color="orange">New</Tag></span>
                    </div>

                    <div className={styles.subtitle}>
                      <a className={styles.mobilePhone} href={`tel:${val.phone}`}>
                        <PhoneOutlined /> {val.phone}</a>
                    </div>

                    <div className={styles.info}>
                      {val && val.budget &&
                      <CF value={val.budget} displayType={'text'} thousandSeparator={true} prefix={'د.إ '}
                          renderText={value => <p className={styles.budgetName}>{value}</p>} />
                      }

                      <div>
                        <p className={styles.label}
                           style={{ backgroundColor: getStageColor(val.stage) }}>{val.stage}</p>
                        {val && val.leadRating && <p className={styles.label}><Tag
                          color={getRatingColor(val.leadRating)}>{val.leadRating}</Tag></p>}
                        <RenderSnooze data={val.activities} />
                      </div>


                      <br />
                      <p className={styles.state}>{val && val.city} {val && val.country
                        ? (val && val.country)
                        : ''} </p>

                    </div>
                    <div className={styles.actions}>
                      <Tooltip title="Appointments">
                        <Badge count={val && val.appointments && val.appointments.length}
                               style={{
                                 backgroundColor: '#fff',
                                 color: '#999',
                                 boxShadow: '0 0 0 1px #d9d9d9 inset'
                               }}>

                          <BookOutlined onClick={() => {
                            // this.openLeadDrawer(val._id)
                          }} />
                        </Badge>

                      </Tooltip>
                      {(user && user.userType == 'admin'|| user.userType == 'salesDirector') &&<span
                        style={{ marginLeft: 'auto', color: '#4C4B4B' }}><small>{moment(val && val.created_time)
                        .format('lll')}</small></span>}
                    </div>
                  </div>

                </>
              )
            })}

            {(allDeals && allDeals.length == 0) && <div className={styles.card}>
              <Skeleton avatar paragraph={{ rows: 3 }} active={true} loading={true} />,
            </div>}

            <VisibilitySensor offset={{ top: 100 }} partialVisibility onChange={(isVisible) => {
              if (isVisible && !loadMoreLoader) {
                loadMore()
              }
            }}>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={loadMore} size="small" loading={loadMoreLoader} className={styles.loadMore}
                        type="primary">Load More</Button>
              </div>
            </VisibilitySensor>

            {/*  <div style={{textAlign: 'center'}}>
                <Button onClick={loadMore} size="small" className={styles.loadMore}
                        type="primary">Load More</Button>
              </div>*/}

          </div>
        </Col>
        <Col md={18}>
          <div className={styles.leadViewWrapper}>
            <div className={styles.leadView}>
              {leadId &&
              <ViewLead leadId={leadId} key={refreshRightSide} getTodoLeads={getTodoLeads} allDeals={setAllLeads} />}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  </Spin>)
}

export default ViewUserLead
