import {getProjectsUrl, getProjectsFilesUrl, getProjectsVariationFilesUrl} from '../api/index'
import axios from 'axios'
import {notification} from 'antd'
import {getToken} from '../../../request'

var JSZipUtils = require('jszip-utils')
var JSZip = require('jszip')
import {saveAs} from 'file-saver'

export const getProjects = async params => {
    try {
        let {data} = await axios.get(getProjectsUrl(), {
            params: {...params},
            ...getToken()
        })

        if (data.error) {
            notification.error({
                message: data.message
            })
        }

        return data;

    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}

export const downloadProjectFiles = async params => {
    try {
        let {data} = await axios.post(getProjectsFilesUrl(), params, getToken())
        return data;

    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}

export const downloadProjectVariationsFiles = async params => {
    try {
        let {data} = await axios.post(getProjectsVariationFilesUrl(), params, getToken())
        return data;

    } catch (err) {
        notification.error({
            message: 'Error'
        })
        return null
    }
}
