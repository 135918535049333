import React, {Component, useEffect, useState} from 'react'
import {API_URL, timeStampFormat} from '../../request'
import {Card, Row, Col, Calendar, Tag, Switch, notification, Tooltip, Spin} from 'antd'
import {Column, Pie, Bar, Line, TinyArea, TinyLine} from '@ant-design/charts'
import ManagerAgentTargetComp from './targetChartComp'
import ManagerAgentTotalTargetComp from './managersAgentTotalTargetChart'

import styles from './styles.less'
import moment from 'moment'

import {getTokenForFirebase, onMessageListener} from '../../firebase.js'
import {
    userDashboardCount,
    registerUserToken,
    dateWiseFollowupCount,
    platformCount,
    managerTargetData
} from './actions'
import {userTargetCount} from '../targets/actions'
import {userClosingCount} from '../closing/actions'

const Dashboard = (props) => {

    const [show, setShow] = useState(false)

    // Start Notification Area
    const [isTokenFound, setTokenFound] = useState(false)
    const [startDate, setStartDate] = useState(moment().startOf('day').toDate())
    const [endDate, setEndDate] = useState(moment().endOf('day').toDate())
    const [userData, setUserCount] = useState([])
    const [followupData, setFollowupsData] = useState()
    const [platformData, setPlatformData] = useState()
    const [followupCount, setFollowupsCount] = useState()
    const [totalLeads, setTotalLeadsCount] = useState()
    const [targetCount, setTargetCount] = useState([])
    const [closingCount, setClosingCount] = useState({})
    const [loader, setLoader] = useState(false)
    const [loginUserType, setUserType] = useState()
    const [targetData, setManagerTargetData] = useState([])
    const [month, setMonth] = useState(moment().format('MMMM'))
    const [year, setYear] = useState(moment().format('YYYY'))

    getTokenForFirebase(async (data) => {
        setTokenFound(data.success)
        if (data.success) {
            let x = await registerUserToken({...data, deviceType: 'web'})
            // console.log(x, ' x')
        }
    })

    onMessageListener().then(payload => {
        setShow(true)
        notification.open({
            message: payload.notification.title,
            description: payload.notification.body,
            onClick: () => {
                console.log('Notification Clicked!')
            }
        })
    }).catch(err => console.log('failed: ', err))
    // End Notificaiton Area

    const fetchFollowupsCount = async () => {
        dateWiseFollowupCount({
            followUpDate: {
                $gte: startDate,
                $lte: endDate
            }
        }).then(({data}) => {
            setFollowupsCount(data)
            setFollowupsData(data.allFollowups)
        })
    }
    const fetchUserCount = async () => {
        setLoader(true)

        userDashboardCount({
            leadDate: {
                $gte: startDate,
                $lte: endDate
            }
        }).then(({data, totalLeads}) => {
            setUserCount(data)
            setTotalLeadsCount(totalLeads)
            setLoader(false)

        })
    }
    const fetchPlatformCount = async () => {
        platformCount({
            leadDate: {
                $gte: startDate,
                $lte: endDate
            }
        }).then(({data}) => {
            setPlatformData(data)
        })
    }
    const fetchUserTargetCount = async () => {

        let user = {...JSON.parse(localStorage.getItem('user'))}

        setUserType(user.userType)
        if (user && user.userType !== 'admin') {
            userTargetCount({}).then(({data}) => {
                if (data && data.length) {
                    setTargetCount(data)
                }
            })
        }
    }
    const fetchUserClosingTargetCount = async () => {

        let user = {...JSON.parse(localStorage.getItem('user'))}

        setUserType(user.userType)
        if (user && user.userType !== 'admin') {
            userClosingCount({}).then(({data}) => {
                if (data) {
                    setClosingCount(data)
                }
            })
        }
    }
    const fetchManagerTarget = async () => {

        let user = {...JSON.parse(localStorage.getItem('user'))}

        setUserType(user.userType)
        if (user && user.userType === 'admin') {
            managerTargetData({}).then(({data}) => {
                if (data && data.length) {

                    data = _.reject(data, (item) => {
                        return (item.userId === '620a2b67b8e4b763e10ba8df') || (item.userId === '623ef0d483b09365ebe7787f')
                    })

                    setManagerTargetData(data)
                }
            })
        }
    }


    useEffect(() => {
        fetchUserCount()
        fetchFollowupsCount()
        fetchPlatformCount()
        fetchUserTargetCount()
        fetchManagerTarget()
        fetchUserClosingTargetCount()
    }, [startDate])

    const tinyChartOverdue = {
        height: 60,
        autoFit: true,
        smooth: true,
        color: '#e73636'
    }

    const onClick = () => {
        window.location.href = `${API_URL}/auth/facebook`
    }

    const pieChartData = [
        {
            type: 'Facebook',
            value: 40
        }, {
            type: 'LandingPage',
            value: 50
        }, {
            type: 'Inbound',
            value: 10
        }]

    const pieChartConfig = {
        appendPadding: 10,
        data: platformData && platformData.length ? platformData : pieChartData,
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}'
        },
        interactions: [{type: 'element-selected'}, {type: 'element-active'}]
    }

    const agentsCallTypesData = [
        {agent: 'Shiv', type: 'missed', value: 1},
        {agent: 'Shiv', type: 'incoming', value: 5},

        {agent: 'Vanita', type: 'missed', value: 0},
        {agent: 'Vanita', type: 'incoming', value: 3},

        {agent: 'Sabrina', type: 'missed', value: 2},
        {agent: 'Sabrina', type: 'incoming', value: 7},

        {agent: 'Onkar', type: 'missed', value: 3},
        {agent: 'Onkar', type: 'incoming', value: 6},

    ]

    const agentsCallDurationData = [
        {agent: 'Shiv', type: 'total', value: 97},
        {agent: 'Shiv', type: 'OutgoingDuration', value: 72},
        {agent: 'Shiv', type: 'IncomingDuration', value: 25},
        {agent: 'Vanita', type: 'total', value: 103},
        {agent: 'Vanita', type: 'OutgoingDuration', value: 88},
        {agent: 'Vanita', type: 'IncomingDuration', value: 15},
        {agent: 'Sabrina', type: 'total', value: 111},
        {agent: 'Sabrina', type: 'OutgoingDuration', value: 76},
        {agent: 'Sabrina', type: 'IncomingDuration', value: 35},
        {agent: 'Onkar', type: 'total', value: 110},
        {agent: 'Onkar', type: 'OutgoingDuration', value: 80},
        {agent: 'Onkar', type: 'IncomingDuration', value: 30}
    ]

    const leadsSources = [
        {date: '01-Nov-21', type: 'Facebook', value: 30},
        {date: '01-Nov-21', type: 'LandingPage', value: 60},
        {date: '01-Nov-21', type: 'Inbound', value: 3},
        {date: '02-Nov-21', type: 'Facebook', value: 31},
        {date: '02-Nov-21', type: 'LandingPage', value: 63},
        {date: '02-Nov-21', type: 'Inbound', value: 2},
        {date: '03-Nov-21', type: 'Facebook', value: 33},
        {date: '03-Nov-21', type: 'LandingPage', value: 0},
        {date: '03-Nov-21', type: 'Inbound', value: 4},
        {date: '04-Nov-21', type: 'Facebook', value: 12},
        {date: '04-Nov-21', type: 'LandingPage', value: 55},
        {date: '04-Nov-21', type: 'Inbound', value: 4},
        {date: '05-Nov-21', type: 'Facebook', value: 45},
        {date: '05-Nov-21', type: 'LandingPage', value: 58},
        {date: '05-Nov-21', type: 'Inbound', value: 5},
        {date: '06-Nov-21', type: 'Facebook', value: 43},
        {date: '06-Nov-21', type: 'LandingPage', value: 53},
        {date: '06-Nov-21', type: 'Inbound', value: 6},
        {date: '07-Nov-21', type: 'Facebook', value: 47},
        {date: '07-Nov-21', type: 'LandingPage', value: 43},
        {date: '07-Nov-21', type: 'Inbound', value: 7},
        {date: '08-Nov-21', type: 'Facebook', value: 31},
        {date: '08-Nov-21', type: 'LandingPage', value: 55},
        {date: '08-Nov-21', type: 'Inbound', value: 7}
    ]

    const leadsPerDayConfig = {
        data: leadsSources,
        xField: 'date',
        yField: 'value',
        isStack: true,
        seriesField: 'type',
        columnWidthRatio: 0.4,
        color: ['#183636', '#e3ca8c', '#b526ce'],
        areaStyle: {fillOpacity: 0.7},
        appendPadding: 10,
        point: {
            shape: function shape(_ref) {
                var category = _ref.category
                return category === 'Gas fuel' ? 'square' : 'circle'
            },
            style: function style(_ref2) {
                var year = _ref2.year
                return {r: Number(year) % 4 ? 0 : 3}
            }
        },
        yAxis: {
            label: {
                formatter: function formatter(value) {
                    return value + ' Leads'
                }
            }
        }
    }

    const config2 = {
        data: agentsCallTypesData,
        xField: 'value',
        yField: 'agent',
        isStack: true,
        seriesField: 'type',
        columnWidthRatio: 0.4,
        color: ['#0dc65d', '#4e8ab8', '#ce1348']
    }

    var config3 = {
        data: agentsCallDurationData,
        xField: 'agent',
        yField: 'value',
        seriesField: 'type',
        color: ['#82d1de', '#e3ca8c', '#7fd493'],
        areaStyle: {fillOpacity: 0.7},
        appendPadding: 10,
        point: {
            shape: function shape(_ref) {
                var category = _ref.category
                return category === 'Gas fuel' ? 'square' : 'circle'
            },
            style: function style(_ref2) {
                var year = _ref2.year
                return {r: Number(year) % 4 ? 0 : 3}
            }
        },
        yAxis: {
            label: {
                formatter: function formatter(value) {
                    return value * 100
                }
            }
        }
    }
    const managerTargetDataConfig = {
        data: targetData,
        isGroup: true,
        xField: 'name',
        yField: 'value',
        seriesField: 'type',
        dodgePadding: 2,
        tooltip: {
            customContent: (title, items) => {
                return (
                    <>
                        <h5 style={{marginTop: 16}}>{title}</h5>
                        <ul style={{paddingLeft: 0}}>
                            {items?.map((item, index) => {
                                const {name, value, type, color} = item
                                return (
                                    <li
                                        key={item.value}
                                        className="g2-tooltip-list-item"
                                        data-index={index}
                                        style={{marginBottom: 4, display: 'flex', alignItems: 'center'}}
                                    >
                                        <span className="g2-tooltip-marker" style={{backgroundColor: color}}></span>
                                        <span
                                            style={{display: 'inline-flex', flex: 1, justifyContent: 'space-between'}}
                                        >
                      <span style={{margiRight: 16}}>{name}: </span>
                      <span className="g2-tooltip-list-item-value">{value} Million</span>
                    </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </>
                )
            }
        },
        label: {
            position: 'middle',
            layout: [
                {
                    type: 'interval-adjust-position'
                },
                {
                    type: 'interval-hide-overlap'
                },
                {
                    type: 'adjust-color'
                }
            ]
        }
    }
    const columns = [
        {
            title: 'Agent',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            searchTextName: 'name'
        },
        {
            key: 'email'
        },
        {
            key: 'mobile'
        },
        {
            title: 'Untouched Leads',
            key: 'untouched',
            render: (val, record) => {
                if (val > 0) {
                    return <a target={'_blank'}
                              href={`/stages/${record && record._id}/${moment(endDate).format('YYYY-MM-DD HH:mm')}/${moment(startDate).format('YYYY-MM-DD HH:mm')}/Untouched`}>{val}</a>
                } else {
                    return val
                }


            }
        },
        {
            title: 'Contacted Leads',
            key: 'contacted',
            render: (val, record) => {
                if (val > 0) {
                    return <a target={'_blank'}
                              href={`/stages/${record && record._id}/${moment(endDate).format('YYYY-MM-DD HH:mm')}/${moment(startDate).format('YYYY-MM-DD HH:mm')}/Contacted`}>{val}</a>
                } else {
                    return val
                }


            }
        },

        {
            title: 'Proposal Leads',
            key: 'proposalPresented'
        },
        {
            title: 'Unqualified Leads',
            key: 'unqualified',
            render: (val, record) => {
                if (val > 0) {
                    return <a target={'_blank'}
                              href={`/stages/${record && record._id}/${moment(endDate).format('YYYY-MM-DD HH:mm')}/${moment(startDate).format('YYYY-MM-DD HH:mm')}/Unqualified`}>{val}</a>
                } else {
                    return val
                }


            }
        },


        {
            key: 'totalFollowups',
            render: (val, record) => {
                if (val > 0) {
                    return <a target={'_blank'}
                              href={`/followups/${record && record._id}/${moment(endDate).format('YYYY-MM-DD HH:mm')}/${moment(startDate).format('YYYY-MM-DD HH:mm')}`}>{val}</a>
                } else {
                    return val
                }


            }

        },
        {
            label: 'Total Lead',
            key: 'sum',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.sum - b.sum
        },
        // {
        //   key: 'overdueWeekly',
        //   render: (val) => {
        //     return <TinyLine {...tinyChartOverdue} data={[
        //       264,
        //       417,
        //       438,
        //       887,
        //       309,
        //       397,
        //       550,
        //       575,
        //       563,
        //       430
        //     ]} />
        //   }
        // },
        {
            key: 'isLoggedIn',
            render: (val, record) => {
                return <Switch checked={val}/>
            }
        },
        {
            key: 'loginTime',
            render: (val, record) => {
                return record && record.loginTime ?
                    <Tooltip title={moment(record.loginTime).format('lll')}>
                        {moment(record.loginTime).fromNow()}
                    </Tooltip> : ''
            }
        },
        {
            key: 'inactiveSince',
            fixed: 'right',
            render: (val, record) => {
                return record && record.inActiveTime ?
                    <Tooltip title={moment(record.inActiveTime).format('lll')}>
                        {moment(record.inActiveTime).fromNow()}
                    </Tooltip> : ''
            }
        }
    ]

    return (
        <div className={'asdf'} style={{padding: 20}}>

            {
                /*<div>
                <a onClick={() => onClick()}>Facebook Login </a>
                </div>*/
            }


            <div>
                <Row>
                    <Col span={12}>
                        {!isTokenFound && <h1> Need notification permission ❗️ </h1>}
                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                        {(loginUserType !== 'admin' && loginUserType !== 'salesDirector') ? <div>
                            <h4>
                                Personal Target : {targetCount[0] && targetCount[0].loginTarget || 0} Million
                            </h4>

                        </div> : ''
                        }

                        {(loginUserType === 'salesManager' || loginUserType === 'salesDirector') && <div>
                            <h4>
                                Team Target : {targetCount[0] && targetCount[0].staffTarget || 0} Million
                            </h4>
                        </div>}

                    </Col>
                    <Col span={6} style={{textAlign: 'right'}}>
                        {(loginUserType !== 'admin' && loginUserType !== 'salesDirector') ? <div>
                            <h4>
                                Personal Achieved : {closingCount && closingCount.personalData || 0} Million
                            </h4>

                        </div> : ''
                        }

                        {(loginUserType === 'salesManager' || loginUserType === 'salesDirector') && <div>
                            <h4>
                                Team Achieved : {closingCount && closingCount.teamData || 0} Million
                            </h4>
                        </div>}

                    </Col>
                </Row>
            </div>

            <Spin spinning={loader}>

                {/*{loginUserType === 'admin' && <Row>*/}
                {/*  <Col>*/}
                {/*    <ManagerAgentTargetComp />*/}
                {/*  </Col>*/}
                {/*</Row>}*/}
                {loginUserType === 'admin' && <Row gutter={8} style={{marginTop: '12px', marginBottom: '12px'}}>
                    <Col span={12}>
                        <ManagerAgentTotalTargetComp/>
                    </Col>
                    <Col span={12}>

                        <Card>
                            <h3 className={styles.chartTitle}>
                                Manager's Target ({month} {year})
                            </h3>
                            <Column {...managerTargetDataConfig} />
                        </Card>
                    </Col>
                </Row>}

                <Row>
                    <Col span={24} className={styles.boxCol}>
                        <div className={`${styles.box}  ${styles.box1}`}>
                            <p className={styles.title}>
                                {totalLeads || 0}
                            </p>
                            <p className={styles.subtitle}>
                                Open Leads
                            </p>

                        </div>
                        <div className={`${styles.box}  ${styles.box2}`}>
                            <p className={styles.title}>
                                {followupCount && followupCount.remainingFollowupCount}

                            </p>
                            <p className={styles.subtitle}>
                                Follow Ups
                            </p>

                        </div>
                        <div className={`${styles.box}  ${styles.box3}`}>
                            <p className={styles.title}>
                                0
                            </p>
                            <p className={styles.subtitle}>
                                Overdue Followups
                            </p>
                        </div>
                    </Col>
                </Row>

                <Row className={styles.calanderRow}>
                    <Col span={8}>
                        <Calendar fullscreen={false} onChange={(date) => {
                            setStartDate(moment(date).startOf('day').toDate())
                            setEndDate(moment(date).endOf('day').toDate())

                        }}/>
                    </Col>
                    <Col span={16}>

                        <div className={styles.rulesHere}>

                            <div className={styles.dateBatch}>
                                <span className={styles.date}>{moment(startDate).format('DD')}</span>
                                <span className={styles.month}>{moment(startDate).format('MMM')}</span>
                            </div>

                            <div className={styles.followupLabel}>
                                <h2>Follow-ups</h2>
                                <h5>{followupCount && followupCount.remainingFollowupCount} Followup</h5>
                            </div>


                            <ul className={styles.ruledList}>
                                {followupData && _.map(followupData, (item, key) => {
                                    return <div key={key}>
                                        <li className={styles.followTime}>{key}</li>
                                        {_.map(item, (followup, keyy) => {
                                            return <li key={keyy} className={styles.followText}>
                                                <span>{followup.description}</span>
                                            </li>
                                        })}


                                    </div>

                                })}

                            </ul>
                        </div>

                    </Col>
                </Row>

                {/*<Row gutter={8}>*/}
                  {/*  <Col span={8}>*/}
                    {/*    <Card>*/}
                      {/*      <h3 className={styles.chartTitle}>*/}
                        {/*        Leads Calls*/}
                      {/*      </h3>*/}
                      {/*      <Bar {...config2} />*/}
                    {/*    </Card>*/}
                  {/*  </Col>*/}
                  {/*  <Col span={16}>*/}

                    {/*    <Card>*/}
                      {/*      <h3 className={styles.chartTitle}>*/}
                        {/*        Calls Duration*/}
                      {/*      </h3>*/}
                      {/*      <Column {...config3} />*/}
                    {/*    </Card>*/}
                  {/*  </Col>*/}
                {/*</Row>*/}
                {/*/!*<Row>*!/*/}
                {/*/!*  <Col span={24}>*!/*/}
                {/*/!*    <TableComp*!/*/}
                {/*/!*      columns={columns}*!/*/}
                {/*/!*      extraProps={{ scroll: { x: 1200 } }}*!/*/}
                {/*/!*      dataSource={userData || []}*!/*/}
                {/*/!*      pagination={{*!/*/}
                {/*/!*        defaultPageSize: 10,*!/*/}
                {/*/!*        showSizeChanger: true,*!/*/}
                {/*/!*        hideOnSinglePage: true*!/*/}
                {/*/!*      }} />*!/*/}
                {/*/!*  </Col>*!/*/}
                {/*/!*</Row>*!/*/}
                {/*<Row gutter={8} style={{marginTop: 8}}>*/}
                  {/*  <Col span={14}>*/}
                    {/*    <Card>*/}
                      {/*      <h3 className={styles.chartTitle}>*/}
                        {/*        Leads Sources*/}
                      {/*      </h3>*/}
                      {/*      <Line {...leadsPerDayConfig} />*/}
                    {/*    </Card>*/}
                  {/*  </Col>*/}
                  {/*  <Col span={10}>*/}
                    {/*    <Card>*/}
                      {/*      <h3 className={styles.chartTitle}>*/}
                        {/*        Leads Share*/}
                      {/*      </h3>*/}
                      {/*      <Pie {...pieChartConfig} />*/}
                    {/*    </Card>*/}
                  {/*  </Col>*/}
                {/*</Row>*/}


            </Spin>
        </div>
    )

}

export default Dashboard

