import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import counter from './counter'
import theme from './theme'
import global from './global'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  counter,
  theme,
  global,
})

export default createRootReducer
