import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Select } from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { listUsersAction } from '../../users/actions'

const { Option } = Select
const SearchManager = props => {
  const dispatch = useDispatch()
  const { loading } = useSelector(state => ({
    loading: state.global.buttonLoading
  }))

  const [users, setUsers] = useState([])
  const [searching, setSearching] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  useEffect(() => {
    fetchUser()

  }, [])
  const fetchUser = async () => {
    let userTypeFilter = {}
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (props.allowLeadSharing) {
      userTypeFilter.allowLeadSharing = true
    } else {
      if (user && user.userType == 'admin') {
        userTypeFilter.userType = ['salesManager', 'salesDirector']

      } else if (user && user.userType === 'salesDirector') {
        userTypeFilter.directorId = user && user._id
        userTypeFilter.userType = 'salesManager'
      } else {
        setShowSearch(true)
      }
    }


    let { success, data } = await listUsersAction({
      ...userTypeFilter,
      results: 1000,
      regExFilters: ['name']
    })
    if (success) {
      setUsers(data)
    }
  }


  return (
    <div hidden={showSearch}>
      {!props.allowLeadSharing ? <h4 style={{ display: 'inline-block', marginRight: '10px' }}>
        Select Manager
      </h4> : ''}
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        dropdownMatchSelectWidth={false}
        style={{
          width: 225
        }}
        placeholder="Select Manager"
        onChange={ids => {

          let findIn = _.find(users, (v) => {
            return v._id == ids
          })

          if (findIn) {
            props.onSelect({
              name: findIn.name,
              _id: findIn._id
            })
          } else {
            props.onSelect({
              name: null,
              _id: null
            })
          }

          // props.onSelect({
          //   _id: ids
          // })
        }}>
        {users &&
        users.map((val, idn) => {
          return (
            <Option key={idn} value={val._id}>
              {val.name} ({val.mobile}) ({val.userType})
            </Option>
          )
        })}
      </Select>

    </div>
  )
}
export default SearchManager
