import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'

var firebaseConfig = {
  apiKey: 'AIzaSyDCHCjPYS-cTmzdGWS9vfEGWiYMv1mHX_4',
  authDomain: 'bnwduaicrm.firebaseapp.com',
  projectId: 'bnwduaicrm',
  storageBucket: 'bnwduaicrm.appspot.com',
  messagingSenderId: '494161824308',
  appId: '1:494161824308:web:8c4351e6adb7e986295861',
}

firebase.initializeApp(firebaseConfig)
const messaging = firebase.messaging()

export const getTokenForFirebase = (setTokenFound) => {
  return messaging.getToken(
    {vapidKey: 'BEo_zHybVhtFuNhMKki5WbZOgUDY0RH3XMasifgXNHmnqFI506KkklTUZCSfJ4lUzzNMiewyqSi7_3BLvZHvsME'})
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken)
        setTokenFound({success: true, currentToken})
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.')
        setTokenFound({success: false})
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload)
    })
  })
