import { API_URL } from '../../../request'

export const userNotificationToken = () => {
  return API_URL + `/user/notificationToken`
}

export const dateWiseFollowupCountApi = () => {
  return API_URL + `/followupCount`
}
export const platformCountApi = () => {
  return API_URL + `/leadPlatformTypeChart`
}
export const userDashboardCountApi = () => {
  return API_URL + `/userDashboardCount`
}
export const managerTargetDataUrl = () => {
  return API_URL + `/managerTarget/chart`
}
export const managerAgentsTargetDataUrl = () => {
  return API_URL + `/manager/agentsTarget/chart`
}
export const managerAgentsTargetTotalDataUrl = () => {
  return API_URL + `/manager/agentsTotalTarget/chart`
}
export const managerAgentsLeadsDataUrl = () => {
  return API_URL + `/manager/agentsLeads`
}
export const managerWiseTargetClosingDataUrl = () => {
  return API_URL + `/managerWise/targetClosing/chart`
}
