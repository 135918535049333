import React, { useState } from 'react'
import { Modal, Button, Popconfirm, Radio, notification, Input } from 'antd'

const UnqualifyModal = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loader, setLoader] = useState(false)
  const [reason, setValue] = React.useState(null)
  const [otherReason, setOtherReason] = React.useState(null)

  const {
    leadData, user,
    updateTheLead,
    handleUnqualify
  } = props

  const showModal = () => {
    setValue(null)
    setOtherReason(null)
    setIsModalVisible(true)
  }

  const handleOk = async () => {


    let tempMsg = reason == 'Others' ? otherReason : reason


    if (!tempMsg) {
      notification.warning({
        message: 'Required',
        description: 'Choose a reason'
      })
      return
    }
    setLoader(true)
    await updateTheLead({
      status: 'Unqualified',
      stage: 'Contacted',
      unqualifiedReason: tempMsg
    })

    setLoader(false)
    setIsModalVisible(false)
    if (handleUnqualify) {
      handleUnqualify()
    }

  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }


  const onChange = e => {
    setOtherReason(null)
    setValue(e.target.value)
  }

  return (
    <>

      {leadData && <div>
        <Popconfirm
          title="Are you sure to unqualifiy this lead?"
          onConfirm={async () => {
            showModal()


          }}
          onCancel={() => {

          }}
          okText="Yes"
          cancelText="No">
          <Button style={{ marginLeft: '5px' }} type="danger" size={'small'}>Unqualify lead</Button>
        </Popconfirm>
      </div>}


      <Modal title="Choose a reason"

             footer={[
               <Button key="back" onClick={handleCancel}>
                 Cancel
               </Button>,
               <Button key="submit" type="primary" loading={loader} onClick={handleOk}>
                 Unqualify
               </Button>

             ]}
             visible={isModalVisible}>


        <Radio.Group onChange={onChange} value={reason}>
          <Radio value={'Invalid Query'}>Invalid Query</Radio>
          <br />
          <Radio value={'Budget Issue'}>Budget Issue</Radio>
          <br />

          <Radio value={'Others'}>Others</Radio>

        </Radio.Group>

        <div style={{ marginTop: 10 }}>


          {
            reason == 'Others' ? <Input placeholder={'Enter Reason'}
                                        onChange={(e) => setOtherReason(e.target.value)} /> : null
          }

        </div>
        <br />


      </Modal>
    </>
  )
}

export default UnqualifyModal
