import {
  addProjectUrl,
  listProjectUrl,
  updateProjectUrl,
  listPropertyTypeandAmenitiesUrl,
  searchProjectsUrl,
  addProjectVariationUrl,
  variantsListUrl,
  updateProjectVariationActionUrl
} from '../api/index'
import axios from 'axios'
import { notification } from 'antd'
import { getToken } from '../../../request'

export const addProjectAction = async params => {
  try {
    let {data} = await axios.post(
        addProjectUrl(),
        {...params},
        getToken(),
    )

    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const addProjectVariationAction = async params => {
  try {
    let {data} = await axios.post(
        addProjectVariationUrl(),
        {...params},
        getToken(),
    )

    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const listProjectAction = async params => {
  try {
    let {data} = await axios.post(listProjectUrl(), {...params}, getToken())

    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const updateProjectAction = async params => {
  try {
    let {data} = await axios.put(
      updateProjectUrl(),
      {...params},
      getToken(),
    )

    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const listPropertyTypeandAmenities = async params => {
  try {
    let {data} = await axios.post(
      listPropertyTypeandAmenitiesUrl(),
      {},
      getToken(),
    )
  console.log(data , 'api wala');
    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}


export const searchProject = async params => {
  try {
    let {data} = await axios.post(
      searchProjectsUrl(),
      {...params},
      getToken(),
    )
    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
export const variantsListAction = async params => {
  try {
    let { data } = await axios.get(
      variantsListUrl(), { params: { ...params }, ...getToken() }
    )
    return data
  } catch (err) {
    notification.error({
      message: 'Error'
    })
    return null
  }
}
export const updateProjectVariationAction = async params => {
  try {
    let {data} = await axios.put(
      updateProjectVariationActionUrl(),
      {...params},
      getToken(),
    )

    if (data.error) {
      notification.error({
        message: data.message,
      })
    }
    return data
  } catch (err) {
    notification.error({
      message: 'Error',
    })
    return null
  }
}
