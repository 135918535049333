import {API_URL} from '../../../request'

export const getProjectsUrl = () => {
  return API_URL + `/getProjects`
}

export const getProjectsFilesUrl = () => {
  return API_URL + `/getProjects/files`
}

export const getProjectsVariationFilesUrl = () => {
  return API_URL + `/getProjectVariation/files`
}
