import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Select, Input, DatePicker } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { API_URL } from '../../../request'

import { saveCommentAction } from '../actions'
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input

const AddComment = props => {

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue } = form

  const [loader, setLoader] = useState(false)
  let { handleComment, leadId, projectId } = props


  useEffect(() => {

  }, [])


  const onFinish = async values => {
    let x = null
    let user = JSON.parse(localStorage.getItem('user'))

    setLoader(true)
    values.leadId = leadId
    values.projectId = projectId

    x = await saveCommentAction(values)

    setLoader(false)
    if (!x.error) {
      resetFields()
      notification.success({
        message: x.message
      })

      if (handleComment) {
        handleComment()
      }


    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }
  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 18 }
    }
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const inputTypes = {
    fields: [


      {
        label: 'Comment',
        key: 'msg',
        type: 'textArea',
        rows: 5
        // required: true
      }


    ]
  }

  let form1 = <div>
    {props.hideTitle && <React.Fragment>
      <Form
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        {/*<GetAllFormFields apiurl={API_URL} inputSchema={inputTypes} formItemLayout={formItemLayout} />*/}

        <Form.Item {...formItemLayout}>


          <Input.Group size="large">
            <Form.Item
              name={'msg'}
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item >
              <Button loading={loader} type="primary" htmlType="submit">
                SAVE
              </Button>
            </Form.Item>
          </Input.Group>
        </Form.Item>


      </Form>


    </React.Fragment>}
  </div>

  return (
    <React.Fragment>{!props.hideTitle ? <PageHeaderWrapper>{form1}</PageHeaderWrapper> : form1}</React.Fragment>
  )
}

export default AddComment
