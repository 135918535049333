import React, { useState } from 'react'
import {
  Drawer,
  Button, Spin,
  Row,
  Col,
  Card,
  AutoComplete,
  Input,
  Tag,
  Checkbox, notification
} from 'antd'
import styles from './style.less'
import { BsCheckSquare, BsWhatsapp } from 'react-icons/bs'
import { AiFillMail } from 'react-icons/ai'
import { searchProject, variantsListAction } from '../actions'
import ProjectItem from './projectItem'
import VariationItem from './variationItem'
// import projectItemComplete from './projectItemComplete'
import { useDispatch } from 'react-redux'
import { HomeOutlined } from '@ant-design/icons'
import LeadItem from './leadItem'


const ProjectView = (props) => {

  const dispatch = useDispatch()

  const { onClose, visible, selectedArr, leadObj } = props
  const [options, setOptions] = useState([])
  const [setProject, setSelectedProject] = useState(null)
  const [sendWhatsApp, setSendWhatsApp] = useState(false)
  const [sendMail, setSendMail] = useState(false)
  const [sendText, setSendText] = useState(false)
  const [loader, setLoader] = useState(false)
  const [variations, setVariationList] = useState([])

  const [arr, setArr] = useState([])


  useState(() => {
    dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
  }, [])

  const searchResult = (query) => {
    return new Promise(async (next) => {
      setLoader(true)
      let { success, data } = await searchProject({
        name: query

      })
      setLoader(false)
      if (success) {
        return next(data.map((v, idx) => {
          return {
            value: v.name,
            label: (
              <div className={styles.autoCtr}>
                <ProjectItem {...v} showSales={false} />
              </div>
            )
          }
        }))
      } else {
        return next([])
      }

    })
  }

  const handleSearch = async (value) => {

    if (value) {
      let arr = await searchResult(value)
      setOptions(arr)
    } else {
      setOptions([])
    }

  }
  const handleClear = async (value) => {

    setSelectedProject(null)
  }


  const onSelect = async (value, option) => {
    //console.log(option.label)
    const ll = option.label.props.children.props
    setSelectedProject(ll)
    if (ll && ll._id) {
      console.log(ll._id)
      fetchVariationsByProjectId(ll._id)
    }
  }
  const fetchVariationsByProjectId = async (projectId) => {
    let {data } = await variantsListAction({ projectId: projectId, results: 100 })
      setVariationList(data)

  }

  return (

    <Card bordered={true}>
      <Row>
        <Col style={{ flex: 1 }}>
          <AutoComplete
            dropdownMatchSelectWidth={'100%'}
            dropdownClassName="certain-category-search-dropdown"

            style={{
              width: '100%'
            }}
            options={options}
            onSelect={onSelect}
            onSearch={handleSearch}
            onClear={handleClear}
            allowClear
          >
            <Input.Search size="large" placeholder="Search Project" />
          </AutoComplete>
        </Col>
        <Col className={styles.loadCtrl}>
          <Spin spinning={loader} />
        </Col>
      </Row>
      {
        setProject ? <div className={styles.choosenProject}>
          <ProjectItem {...setProject} showSales={true}></ProjectItem>
          <VariationItem arr={variations} />


        </div> : null
      }


    </Card>


  )
}
export default ProjectView
