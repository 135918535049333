import React, { useEffect, useState } from 'react'
import { Form, Button, Card, notification, Upload, Row, Col } from 'antd'
import { Column, Pie, Bar, Line, TinyArea, TinyLine } from '@ant-design/charts'

import { managerAgentsTargetTotalData } from './actions'
import styles from './styles.less'
import moment from 'moment'

const TargetChart = props => {
  const [loader, setLoader] = useState(false)
  const [month, setMonth] = useState(moment().format('MMMM'))
  const [year, setYear] = useState(moment().format('YYYY'))
  const [targetData, setManagerAgentsTotalTargetData] = useState([])
  const fetchData = async () => {

    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (user && user.userType === 'admin') {
      managerAgentsTargetTotalData({}).then(({ data }) => {
        if (data && data.length) {
          setManagerAgentsTotalTargetData(data)
        }
      })
    }
  }
  useEffect(() => {
    fetchData()
  }, [])


  const managerTargetDataConfig = {
    data: targetData,
    isGroup: true,
    xField: 'managerName',
    yField: 'totalAgentsTarget',
    seriesField: 'type',
    dodgePadding: 2,
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 style={{ marginTop: 16 }}>{title}</h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { name, value, type, color } = item
                return (
                  <li
                    key={item.value}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                    >
                      <span style={{ margiRight: 16 }}>{name}: </span>
                      <span className="g2-tooltip-list-item-value">{value} Million</span>
                    </span>
                  </li>
                )
              })}
            </ul>
          </>
        )
      }
    },
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position'
        },
        {
          type: 'interval-hide-overlap'
        },
        {
          type: 'adjust-color'
        }
      ]
    }
  }


  return (
    <div>

      <Card style={{ marginBottom: '10px' }}>
        <h3 className={styles.chartTitle}>
          Manager's Team's Total Target ({month} {year})
        </h3>


        <Column {...managerTargetDataConfig} />

      </Card>

    </div>
  )
}

export default TargetChart
