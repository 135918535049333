import React, { useEffect, useState } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, notification, Select } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import GetAllFormFields from '../../../components/_utils/formUtils'
import { API_URL, followUpTime } from '../../../request'
import async from 'async'
import moment from 'moment'
import { addAppointmentAction } from '../actions'

const { Option } = Select


const AddAppointment = props => {

  const [form] = Form.useForm()
  let { resetFields, setFieldsValue } = form

  const [loader, setLoader] = useState(false)
  const [timeOptionsArr, setTimeOptionsArr] = useState([])
  let { handleAddAppointment, leadId, projectId } = props


  const getTimeOptions = (date) => {
    let afterDate = date || moment()
    let timeOptionsArr = []
    if (afterDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
      async.forEach(followUpTime, (doc, cb) => {
        let compareTime = moment(doc, ['h:mma'])
        if (moment().add('hour', 1).isBefore(compareTime)) {
          timeOptionsArr.push(doc)
        }
        cb()
      }, () => {
        setTimeOptionsArr(timeOptionsArr)
      })
    } else {
      setTimeOptionsArr(followUpTime)
    }
  }

  useEffect(() => {
    getTimeOptions()
  }, [])


  useEffect(
    async () => {
      if (props && props.showPreField) {
        setFieldsValue({ appointmentType: 'Viewing', appointmentLocation: 'Site Location', appointmentDate: moment() })
      }
    },
    []
  )

  const onFinish = async values => {
    let x = null
    let user = JSON.parse(localStorage.getItem('user'))

    setLoader(true)
    values.leadId = leadId
    values.projectId = projectId

    x = await addAppointmentAction(values)


    setLoader(false)
    if (!x.error) {
      resetFields()
      notification.success({
        message: x.message
      })

      if (handleAddAppointment) {
        handleAddAppointment()
      }


    } else {
      notification.error({
        message: x.message ? x.message : 'Error Saving'
      })
    }
  }

  const onFinishFailed = errorInfo => {
    return notification.error({
      message: 'Please Fill All Required Fields'
    })
  }


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      md: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      md: { span: 18 }
    }
  }

  const submitFormLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 10, offset: 7 },
      md: { span: 12, offset: 8 }
    }
  }
  const inputTypes = {
    fields: [
      {
        label: 'Date',
        key: 'appointmentDate',
        type: 'date',
        required: true,
        customProps: {
          onChange: (val) => {
            getTimeOptions(val)
          },
          disabledDate: (d) => !d || d.isBefore(moment().add(-1, 'day'))

        }

      },
      {
        label: 'Appointment Time',
        key: 'appointmentTime',
        type: 'select',
        required: true,
        options: timeOptionsArr,
        customProps: {
          onChange: (val) => {
            setFieldsValue({ appointmentTime: val })
          }
        }
      },
     /* {
        label: 'Location',
        key: 'appointmentLocation',
        required: true,
        onChange: (val) => {
          setFieldsValue({ location: val })
        }
      },
      {
        label: 'Type',
        key: 'appointmentType',
        type: 'select',
        options: ['Viewing', 'Meeting'],
        required: true,
        onChange: (val) => {
          setFieldsValue({ appointmentType: val })
        }
      },*/

      {
        label: 'Description',
        key: 'description',
        type: 'textArea',
        rows: 5
        // required: true
      }


    ]
  }

  let form1 = <div>
    {props.hideTitle && <React.Fragment>
      <Form
        form={form}
        name='basic'
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <GetAllFormFields apiurl={API_URL} inputSchema={inputTypes} formItemLayout={formItemLayout} />

        <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
          <Button loading={loader} type="primary" htmlType="submit">
            SAVE
          </Button>
        </Form.Item>
      </Form>


    </React.Fragment>}
  </div>

  return (
    <React.Fragment>{!props.hideTitle ? <PageHeaderWrapper>{form1}</PageHeaderWrapper> : form1}</React.Fragment>
  )
}

export default AddAppointment
