import {ImUserTie} from "react-icons/im";
import styles from "./style.less";
import {Tag} from "antd";
import {BsTelephone} from "react-icons/bs";
import {GoMail} from "react-icons/go";
import React from "react";
import moment from 'moment';

const LeadItem = (item) => {
    const {customer, phone, email, created_time, projectName, campaign_name} = item;
    const campName = (v) => {
        if (v) {
            let r = v.split(" ")
            let str = "";
            _.each(r, (vv => {
                if (str.length < 2) {
                    str += vv.substring(0, 1)
                }
            }))
            return str.toString()
        } else {
            return ''
        }

    }
    return <div>
        <h3><ImUserTie className={styles.icon}/> {customer}</h3>
        <div className={styles.contactCtr}>
            <Tag color="blue" style={{alignSelf: 'center'}}> <BsTelephone className={styles.icon}/> {phone} </Tag>
            <Tag color="orange"> <GoMail className={styles.icon}/> {email} </Tag>
            <div className={styles.timeAgo}>{moment().fromNow(created_time)}</div>
        </div>
        <div className={styles.projectInfoCtr}>
           {/* <div className={styles.campName}>{campName(campaign_name)}</div>*/}
            <div className={styles.proName}>{projectName}</div>

        </div>

    </div>
}

export default LeadItem;
